import Vue from 'vue'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'

import store from '@/store'
import { IBlogState, IArticle, ISections, IArticlesList, LoadArticlesListParams } from '@/store/typings/blog'

@Module({ dynamic: true, store, namespaced: true, name: 'blog' })
class BlogModule extends VuexModule implements IBlogState {
  article: IArticle | null = null
  sections: ISections[] = []
  articlesList: IArticlesList | null = null
  isLoading = false
  isArticleLoading = false
  isArticlesLoading = false

  @Mutation
  setArticle(article: IArticle) {
    this.article = article
  }

  @Mutation
  setSections(sections: ISections[]) {
    this.sections = sections
  }

  @Mutation
  setArticlesList(articlesList: IArticlesList) {
    this.articlesList = articlesList
  }

  @Mutation
  setLoadingStatus(isLoading: boolean) {
    this.isLoading = isLoading
  }

  @Mutation
  setArticleLoadingStatus(isArticleLoading: boolean) {
    this.isArticleLoading = isArticleLoading
  }

  @Mutation
  setArticlesLoadingStatus(isArticlesLoading: boolean) {
    this.isArticlesLoading = isArticlesLoading
  }

  @Action
  public async loadSections() {
    this.setLoadingStatus(true)

    const sections: ISections[] = await Vue.prototype.$api('getSections')

    this.setLoadingStatus(false)
    this.setSections(sections)
  }

  @Action
  public async loadArticlesList(params: LoadArticlesListParams) {
    this.setArticlesLoadingStatus(true)

    this.setArticlesLoadingStatus(true)

    const resp: IArticlesList = await Vue.prototype.$api('getArticlesList', { page_num: 1, ...params })

    this.setArticlesLoadingStatus(false)
    this.setArticlesList(resp)
  }

  @Action
  public async loadMoreArticles(params: LoadArticlesListParams) {
    const resp: IArticlesList = await Vue.prototype.$api('getArticlesList', {
      page_num: this.articlesList!.page_num + 1,
      ...params
    })
    const updatedArticlesList = {
      ...resp,
      result: [...this.articlesList!.result, ...(resp?.result ?? [])]
    }

    this.setArticlesList(updatedArticlesList)
  }

  @Action
  public async loadArticle(articleID: string) {
    this.setArticleLoadingStatus(true)

    const resp: IArticle = await Vue.prototype.$api('getArticle', { id: articleID })

    this.setArticleLoadingStatus(false)
    this.setArticle(resp)
  }
}

export const storeBlog: InstanceType<typeof BlogModule> = getModule(BlogModule)
