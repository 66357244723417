import Vue from 'vue'
import Vuex from 'vuex'
import sharedMutations from 'vuex-shared-mutations'
import createPersistedState from 'vuex-persistedstate'
import { config } from 'vuex-module-decorators'

import { STORAGE_KEY } from '@/helpers/storage'
import { IAppState } from '@/store/typings/app'
import { IAuthState } from '@/store/typings/auth'
import { IBlogState } from '@/store/typings/blog'
import { IReportState } from '@/store/typings/report'
import { IDashboardState } from '@/store/typings/dashboard'
import { IInventoryState } from '@/store/typings/inventory'
import { ISnapshotsState } from '@/store/typings/snapshots'
import { IMagicLinksState } from '@/store/typings/magicLinks'
import { IJsonSchemasState } from '@/store/typings/jsonSchemas'
import { IRemoteConfigState } from '@/store/typings/remoteConfig'
import { IConfigurationsState } from '@/store/typings/configurations'
import { INotificationCenterState } from '@/store/typings/notificationCenter'
import { IAnalyticsStorageDataState } from '@/store/typings/analyticsStorageData'
import { IUaChronologyState } from '@/store/typings/uaChronology'

config.rawError = true

Vue.use(Vuex)

export interface IRootState extends IAppState {
  app: IAppState
  auth: IAuthState
  blog: IBlogState
  report: IReportState
  dashboard: IDashboardState
  inventory: IInventoryState
  snapshots: ISnapshotsState
  magicLinks: IMagicLinksState
  jsonSchemas: IJsonSchemasState
  remoteConfig: IRemoteConfigState
  configurations: IConfigurationsState
  notificationCenter: INotificationCenterState
  analyticsStorageData: IAnalyticsStorageDataState
  uaChronology: IUaChronologyState
}

const store = new Vuex.Store<IRootState>({
  plugins: [
    sharedMutations({
      predicate: ['setCustomPredefinedFilter', 'analyticsStorageData/setGlobalAnalyticsStorageData', 'auth/setToken', 'auth/setAccessToken']
    }),
    createPersistedState({
      key: STORAGE_KEY,
      paths: ['auth', 'app.customPredefinedFilter']
    })
  ]
})
export default store
