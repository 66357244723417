import Vue from 'vue'

import { storeConfigurations } from '@/store/modules/configurations'

Vue.mixin({
  methods: {
    // Check permissions to configurations
    // eslint-disable-next-line vue/no-unused-properties
    $can(resourceID: string, resourceType: TResourceAccessType = 'configurations') {
      if (resourceType === 'configurations') {
        return storeConfigurations.configurationGroupsIDs[resourceID]
      }
      return false
    }
  }
})
