import WebSocketAsPromised from 'websocket-as-promised'
import { has as _has } from 'lodash'
import { VueConstructor } from 'vue'

import { storeApp } from '@/store/modules/app'
import { RESOURCE_TYPES } from '@/helpers/constants'
import { storeJsonSchemas } from '@/store/modules/jsonSchemas'
import { storeNotificationCenter } from '@/store/modules/notificationCenter'

export interface SocketPlugin {
  open: () => void
  close: () => void
  sendRequest: <T = any>(method: string, params: object) => Promise<T>
  methodRequest: <T = any>(method: string, params: object, nprogressActivate: boolean) => Promise<T[]>
}

// eslint-disable-next-line no-undef
const webSocketAddress = process.env.VUE_APP_WS_ADDR || config.VUE_APP_WS_ADDR || 'wss://ws.middle-earth.io/'

export default {
  install(Vue: VueConstructor<Vue>) {
    const wsp = new WebSocketAsPromised(webSocketAddress, {
      packMessage: (data: object) => JSON.stringify(data),
      unpackMessage: (data: string | ArrayBuffer | Blob) => {
        const parsedData = data && JSON.parse(data as string)
        return parsedData && parsedData.id ? parsedData : {}
      },
      attachRequestId: (data, requestId) => Object.assign({ id: requestId }, data),
      extractRequestId: data => data && data.id
    })

    let connectsReattemptsCounter: number = 0
    let pingTimeout: NodeJS.Timeout | null = null

    try {
      wsp.open()
      console.info('[WS:Open]')
    } catch (e) {
      console.error('[WS:Error]: ', e)
    }

    const jsonRPCObj = (method: string, params: object = {}) => ({
      jsonrpc: '2.0',
      method,
      params
    })

    wsp.onOpen.addListener(() => {
      connectsReattemptsCounter = 0
      storeApp.WS_ONOPEN()

      const timer = 1000 * 30 * 1

      const checkCHStatus = () => {
        wsp.send(
          JSON.stringify({
            jsonrpc: '2.0',
            method: 'pingCh'
          })
        )
      }

      checkCHStatus()

      const pingResource = () => {
        const resourceType = RESOURCE_TYPES[storeApp.currentRouteName as keyof IResourceTypes]
        const resourceId = storeApp.resourceID
        const isLoggedInSocket = storeApp.isLoggedInSocket

        if (resourceType && resourceId && isLoggedInSocket) {
          Vue.prototype.$api('setActiveResource', {
            resource_id: resourceId,
            resource_type: resourceType
          })
        } else {
          wsp.send(
            JSON.stringify({
              jsonrpc: '2.0',
              method: 'ping'
            })
          )
        }

        checkCHStatus()

        pingTimeout = setTimeout(pingResource, timer)
      }

      pingTimeout = setTimeout(pingResource, timer)
    })

    wsp.onClose.addListener(event => {
      if (pingTimeout) clearTimeout(pingTimeout)
      if (event.wasClean) {
        console.log(`[WS:CLOSE] Connection was closed clean. Code=${event.code}, reason=${event.reason}`)
      } else {
        if (connectsReattemptsCounter < 5) {
          connectsReattemptsCounter++
          console.log(`[WS:RECONNECT] trying to reconnect. COUNT: ${connectsReattemptsCounter}`)
          const reconnectTimeout = setTimeout(() => {
            wsp.open()
            clearTimeout(reconnectTimeout)
          }, 5000)
        } else {
          console.log(`[WS:RECONNECT] You pass the limit of reconnection. Refresh the page`)
        }
      }
    })

    wsp.onMessage.addListener(messageStr => {
      const message = messageStr && JSON.parse(messageStr)
      const payload = message.params?.payload

      if (!_has(message, 'method')) return
      if (
        message.method === 'processMessage' &&
        message.params?.type === 'resourceChanged' &&
        payload &&
        RESOURCE_TYPES[storeApp.currentRouteName as keyof IResourceTypes] === payload.type &&
        storeApp.resourceID === payload.id
      ) {
        storeApp.updateEntity(payload)
      } else if (message.method === 'processMessage' && message.params?.type === 'healthStatus') {
        storeNotificationCenter.setClickHouseStatus(message.params.payload)
      } else if (message.method === 'processMessage' && message.params?.type === 'reloadResourceConfigs') {
        storeApp.reloadEntity(payload)
      } else if (message.method === 'processMessage' && message.params?.type === 'newNotification') {
        storeNotificationCenter.addNotification(payload)
      } else if (
        message.method === 'processMessage' &&
        (message.params?.type === 'contentCategoryOrderChanged' || message.params?.type === 'contentOrderChanged')
      ) {
        storeJsonSchemas.setContentServerOrderMessage(message.params)
      } else if (message.method !== 'processMessage') {
        storeApp.addMessageToCollector(message.params)
      }
    })

    Vue.prototype.$socket = {
      open: () => wsp.open(),
      close: () => wsp.close(),
      sendRequest: async function <T = any>(method: string, params: object): Promise<T> {
        Vue.prototype.$nprogress.start()
        if (!wsp.isOpened) await wsp.open()
        if (!storeApp.isLoggedInSocket && !['login', 'reissue'].includes(method)) {
          await new Promise(resolve => setTimeout(resolve, 500))
        }
        storeApp.increaseRPCCounter()
        const response = await wsp.sendRequest(jsonRPCObj(method, params), {
          requestId: storeApp.rpcCounter
        })
        Vue.prototype.$nprogress.done()
        return new Promise((resolve, reject) => {
          if (response.error) {
            reject(response.error)
            // Reserved error code for failed login
            if (response.error.code !== -32000 && response.error.code !== 32403) {
              Vue.prototype.$sauronNotify.show({
                type: 'error',
                name: response.error.message,
                message: response.error.message
              })
            }
          }
          resolve(response.result)
        })
      },
      // TODO: not used anymore. Potentially can be removed with message collector
      //  Was used before when with WS we've send a big chunked data
      methodRequest: async function <T = any>(method: string, params: object, nprogressActivate = true): Promise<T[]> {
        const messageUUID = await this.sendRequest(method, params)

        storeApp.initMessageCollector({ method, uuid: messageUUID })

        let requestMaxIndex = 0

        if (nprogressActivate) Vue.prototype.$nprogress.start()
        return new Promise((resolve, reject) => {
          const onMessage = (messageStr: string) => {
            const message = messageStr && JSON.parse(messageStr)

            if (message?.params?.uuid === messageUUID && _has(message, 'method') && storeApp.messageCollector[messageUUID]) {
              const counter = storeApp.messageCollector[messageUUID].counter
              requestMaxIndex = message.params.is_final ? message.params.idx : requestMaxIndex

              if (message.params.result?.error) {
                reject(message.params.result.error)
                Vue.prototype.$sauronNotify.show({
                  type: 'error',
                  name: message.params.result.error,
                  message: message.params.result.error
                })
              }

              if (requestMaxIndex === counter) {
                if (nprogressActivate) Vue.prototype.$nprogress.done()

                resolve(storeApp.messageCollector[messageUUID].value)
                storeApp.removeMessageFromCollector(messageUUID)
                wsp.onMessage.removeListener(onMessage)
              }
            }
          }

          wsp.onMessage.addListener(onMessage)
        })
      }
    } as SocketPlugin
  }
}
