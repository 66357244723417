import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import InfiniteLoading from 'vue-infinite-loading'
import VueGtag from 'vue-gtag'

//Directives
import '@/directives/fluid-select'
import '@/directives/scrollable-shadow'
import '@/directives/selectable-on-enter'
import '@/directives/closable-loading'
import '@/directives/focus-trap'

// Global mixins
import '@/mixins/globalMixin'

//Lodash
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import 'nprogress/nprogress.css'
import nprogress from 'nprogress'

//V-calendar
import VCalendar from 'v-calendar'

//ECharts
import ECharts from 'vue-echarts'

//Element
import './styles/element-variables.sass'
import './styles/styles.sass'
import {
  Alert,
  Notification,
  Input,
  InputNumber,
  Select,
  Radio,
  Button,
  ButtonGroup,
  Switch,
  Form,
  FormItem,
  RadioButton,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Option,
  OptionGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  MessageBox,
  Loading,
  Table,
  TableColumn,
  Pagination,
  Tooltip,
  Tabs,
  Tag,
  TabPane,
  TimePicker,
  TimeSelect,
  ColorPicker,
  DatePicker,
  Card,
  Menu,
  MenuItem,
  Image,
  Popover,
  Popconfirm
} from 'element-ui'
import store from './store/index'
import router from './router'
import socket from './plugins/socket'
import api from './plugins/api'
import sauronNotify from './plugins/sauronNotify'
import message from './plugins/message'

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

import CustomUploader from '@/components/CustomElements/Upload/index.vue'

import App from './App.vue'
import { sync } from 'vuex-router-sync'

import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// vue-class-component HOOKS register globally
import Component from 'vue-class-component'
import 'vue-class-component/hooks' // import hooks type to enable auto-complete
// Register the router hooks with their names
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate'])

dayjs.extend(quarterOfYear)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.updateLocale('en', { weekStart: 1 })

if (process.env.NODE_ENV !== 'development') {
  // Sentry init
  Sentry.init({
    dsn: 'https://472fe3c7b2f94c8f9842a2f32c422638@sentry-production.k8s.middle-earth.io/11',
    // eslint-disable-next-line no-undef
    environment: config?.ENVIRONMENT || process.env.NODE_ENV,
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.']
  })

  // Google analytics init
  const GTAGIDS = {
    staging: 'G-7GWVB6T8HX',
    production: 'G-CWP8P5FN69'
  }

  Vue.use(VueGtag, {
    config: {
      // eslint-disable-next-line no-undef
      // @ts-ignore
      id: GTAGIDS[config.ENVIRONMENT || process.env.NODE_ENV]
    }
  })
}

sync(store, router)

// Import global components. Should start with Base

const requireComponent = require.context('./components', false, /Base[A-Z]\w+\.(vue|js)$/)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      // @ts-ignore
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false

locale.use(lang)

Vue.prototype.$notify = Notification
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$nprogress = nprogress.configure({ showSpinner: false })

Vue.use(Alert)
Vue.use(Select)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Switch)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(CheckboxButton)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Tooltip)
Vue.use(Tag)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(TimePicker)
Vue.use(TimeSelect)
Vue.use(InfiniteLoading)
Vue.use(ColorPicker)
Vue.use(DatePicker)
Vue.use(Loading)
Vue.use(Card)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Image)
Vue.use(socket)
Vue.use(api)
Vue.use(sauronNotify)
Vue.use(message)
Vue.use(Popover)
Vue.use(Popconfirm)
Vue.use(VCalendar, {
  masks: { weekdays: 'WWW', title: 'MMMM YYYY' }
})

Vue.component('el-upload', CustomUploader)
Vue.component('v-chart', ECharts)

Vue.use(VueRouter)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
