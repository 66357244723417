import Vue from 'vue'
import store from '@/store'
import { VuexModule, Module, getModule, Mutation, MutationAction, Action } from 'vuex-module-decorators'
import { INotificationCenterState, INotification } from '@/store/typings/notificationCenter'

@Module({ dynamic: true, store, namespaced: true, name: 'notificationCenter' })
class NotificationCenterModule extends VuexModule implements INotificationCenterState {
  _notifications: INotification[] = []
  _clickHouseStatus: { status: string; message: string } | null = null

  get notifications(): INotification[] {
    return this._notifications
  }

  get clickHouseStatus(): { status: string; message: string } | null {
    return this._clickHouseStatus
  }

  @Mutation
  addNotification(notification: INotification) {
    this._notifications = Array.isArray(this._notifications) ? [notification, ...this._notifications] : [notification]
  }

  @Mutation
  removeNotification(notificationID: string) {
    this._notifications = this._notifications.filter(notification => notification.id !== notificationID)
  }

  @Mutation
  setClickHouseStatus(status: { status: string; message: string }) {
    this._clickHouseStatus = status
  }

  @MutationAction
  async loadNotifications() {
    const notifications: INotification[] = await Vue.prototype.$api('getNotifications')
    return { _notifications: notifications }
  }

  @Action
  markAsRead(notificationID: string) {
    Vue.prototype.$api('markNotificationAsRead', { id: notificationID })
    this.removeNotification(notificationID)
  }

  @Action
  markAsReadByArticleID(articleID: string) {
    const notification = this.notifications.find(notification => notification.data.article_id === articleID)

    if (!notification) return

    Vue.prototype.$api('markNotificationAsRead', { id: notification.id })
    this.removeNotification(notification.id)
  }
}

export const storeNotificationCenter: InstanceType<typeof NotificationCenterModule> = getModule(NotificationCenterModule)
