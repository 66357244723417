import Vue from 'vue'
import VueRouter, { RouteConfig, NavigationGuard } from 'vue-router'

import Home from '@/views/Home.vue'

import { storeApp } from '@/store/modules/app'
import { storeAuth } from '@/store/modules/auth'
import { storeReport } from '@/store/modules/report'
import { storeInventory } from '@/store/modules/inventory'
import { storeDashboard } from '@/store/modules/dashboard'
import { storeConfigurations } from '@/store/modules/configurations'
import { IReport } from '@/store/typings/report'
import { IDashboard } from '@/store/typings/dashboard'
import { IInventoryType } from '@/store/typings/inventory'
import { IConfigurationGroup } from '@/store/typings/configurations'

type IActualStore = IReport | IInventoryType | IConfigurationGroup | IDashboard | null

function lazyLoad(view: string) {
  return () => import(`@/views/${view}.vue`)
}

const notAuthenticated: NavigationGuard = (to, from, next) => {
  if (!storeAuth.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const authenticated: NavigationGuard = (to, from, next) => {
  if (storeAuth.isAuthenticated) {
    next()
    return
  }
  next('/')
}

function getConfigTabTitle({ title, subTitle, rootTitle }: { title?: string; subTitle: string; rootTitle: string }) {
  if (title && rootTitle && subTitle) {
    return `${subTitle} - ${rootTitle} - ${title}`
  } else if (subTitle && rootTitle) {
    return `${subTitle} - ${rootTitle}`
  } else if (rootTitle) {
    return rootTitle
  }
}

function getAnalyticsTabTitle({ title, subTitle, rootTitle }: { title?: string; subTitle: string; rootTitle: string }) {
  if (title && rootTitle && subTitle) {
    return `${title} - ${rootTitle} - ${subTitle}`
  } else if (title && rootTitle) {
    return `${title} - ${rootTitle}`
  } else if (rootTitle) {
    return rootTitle
  }
}

function getStoreByPageName(pageName: string): IActualStore {
  switch (pageName) {
    case 'reportsPage':
      return storeReport.currentReport
    case 'inventoryPage':
      return storeInventory.currentInventory
    case 'dashboardPage':
      return storeDashboard.currentDashboard
    case 'configurationsPage':
    case 'grabbersPage':
    case 'grabberDetailsPage':
    case 'contentServerPage':
    case 'addEditGrabbersPage':
    case 'addEditConfigurationPage':
      return storeConfigurations.currentConfiguration
    default:
      return null
  }
}

function getTitle(pageName: string, resourceName?: string | null) {
  const actualStore = getStoreByPageName(pageName)
  if (resourceName === 'inventoryPage' || resourceName === 'dashboardPage') {
    return (actualStore as IDashboard | IInventoryType)?.presentation_name
  }
  return actualStore?.name
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Magify' }
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    meta: { title: 'Terms Of Service' },
    component: lazyLoad('TermsOfService')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: { title: 'Privacy Policy' },
    component: lazyLoad('PrivacyPolicy')
  },
  {
    path: '/blog/articles/:id',
    name: 'articlesPage',
    component: lazyLoad('blog/ArticlesPage'),
    meta: { title: 'Blog Articles' },
    beforeEnter: authenticated
  },
  {
    path: '/blog/article/:id',
    name: 'articlePage',
    component: lazyLoad('blog/ArticlePage'),
    meta: { title: 'Blog Article' },
    beforeEnter: authenticated
  },
  {
    path: '/remote-login',
    name: 'remoteLogin',
    component: lazyLoad('RemoteLogin'),
    beforeEnter: notAuthenticated
  },
  {
    path: '/reportGroup/:groupId/report/:id',
    name: 'reportsPage',
    component: lazyLoad('reports/ReportsPage'),
    meta: { title: 'Reports' },
    beforeEnter: authenticated
  },
  {
    path: '/inventory/:id',
    name: 'inventoryPage',
    component: lazyLoad('inventory/InventoryPage'),
    meta: { title: 'Inventory' },
    beforeEnter: authenticated
  },
  {
    path: '/dashboard/:id',
    name: 'dashboardPage',
    component: lazyLoad('dashboard/DashboardPage'),
    meta: { title: 'Dashboard' },
    beforeEnter: authenticated
  },
  {
    path: '/configurations/:id',
    name: 'configurationsPage',
    component: lazyLoad('configurations/ConfigurationPage'),
    children: [
      {
        name: 'addEditConfigurationPage',
        path: ':state/:instance_id?',
        component: lazyLoad('configurations/AddEditPage'),
        meta: { title: 'Remote config' }
      }
    ],
    meta: { title: 'Remote config' },
    beforeEnter: authenticated
  },
  {
    name: 'uaChronologyPage',
    path: '/ua-chronology/:id',
    component: lazyLoad('configurations/UaChronologyPage'),
    meta: { title: 'UA configurations' },
    beforeEnter: authenticated
  },
  {
    path: '/integration/:id',
    name: 'grabbersPage',
    component: lazyLoad('configurations/GrabbersPage'),
    children: [
      {
        name: 'addEditGrabbersPage',
        path: ':state/:schema_id/:instance_id?',
        component: lazyLoad('configurations/AddEditPage'),
        meta: { title: 'Integration' }
      }
    ],
    meta: { title: 'Integration' },
    beforeEnter: authenticated
  },
  {
    name: 'grabberDetailsPage',
    path: '/integration/:id/:schema_id',
    component: lazyLoad('configurations/GrabberDetailsPage'),
    meta: { title: 'Integration' },
    beforeEnter: authenticated
  },
  {
    name: 'contentServerPage',
    path: '/content/:id/:state?/:instance_id?',
    component: lazyLoad('configurations/ContentServerListPage'),
    meta: { title: 'Content Server' },
    beforeEnter: authenticated
  },
  {
    name: 'funnelsPage',
    path: '/funnels/:id',
    component: lazyLoad('configurations/FunnelsPage'),
    meta: { title: 'Funnels' },
    beforeEnter: authenticated
  },
  {
    name: 'tiktokOAuth',
    path: '/tiktok-oauth',
    component: lazyLoad('oauth/TiktokOAuth'),
    meta: { title: 'Tiktok OAuth' },
    beforeEnter: authenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    Vue.prototype.$nprogress.start()
  }
  next()
})

router.afterEach(to => {
  // Complete the animation of the route progress bar.
  Vue.prototype.$nprogress.done()
  Vue.nextTick().then(async () => {
    if (storeApp.initialDataStatus === 'pending') await storeApp.isInitialDataLoaded()

    const rootTitle = storeApp.pageTitle
    const title = getTitle(storeApp.currentRouteName, to.name)
    const subTitle = to.query.title as string

    const pageTitle =
      to.name === 'addEditConfigurationPage'
        ? getConfigTabTitle({ title, subTitle, rootTitle })
        : getAnalyticsTabTitle({ title, subTitle, rootTitle })

    document.title = `${pageTitle}`
  })
})

export default router
