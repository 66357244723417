import { get as _get, remove as _remove, uniq as _uniq } from 'lodash'

import { removeReactivity } from '@/helpers'
import { RESOURCE_TYPES } from '@/helpers/constants'
import { defaultCurrentReportTable } from '@/store//utils/report'
import { ICurrentEntity } from '@/store/typings/app'
import { IAnalyticsData } from '@/store/typings/analyticsStorageData'
import { IChartSpotData, IReport, IRetentionTable, ITable, ITableDimension, ITableField } from '@/store/typings/report'
import {
  IResourceChange,
  IResourceChanges,
  IReportChartSpotChange,
  IReportTableFieldChanges,
  IReportTableDimensionChanges,
  IFiltersChange,
  IReportTableChanges
} from '@/store/typings/resourceChanges'

const dimensionsPropPath = ['dimensions']
const allDimensionsPropPath = ['allReportDimensions']
const downloadDimensionsPropPath = ['downloadReportFields', 'dimensions']
const downloadAllDimensionsPropPath = ['downloadReportFields', 'sortedAllDimensions']

const metricsPropPath = ['metrics']
const allMetricsPropPath = ['allMetrics']
const downloadMetricsPropPath = ['downloadReportFields', 'metrics']
const downloadAllMetricsPropPath = ['downloadReportFields', 'sortedAllMetrics']

export function getUpdatedAnalyticsStorageByResourceChanges(
  resourceChanges: IResourceChanges,
  oldAnalyticsStorage: IAnalyticsData,
  resourceType: TResourceType = RESOURCE_TYPES.reportsPage,
  currentEntity?: ICurrentEntity
): IAnalyticsData {
  if (resourceType === RESOURCE_TYPES.reportsPage) {
    return getUpdatedReportAnalyticsStorageByResourceChanges(resourceChanges, oldAnalyticsStorage, currentEntity as IReport)
  } else if (resourceType === RESOURCE_TYPES.dashboardPage) {
    return getUpdatedDashboardAnalyticsStorageByResourceChanges(resourceChanges as IFiltersChange[], oldAnalyticsStorage)
  }
  return oldAnalyticsStorage
}

function getUpdatedReportAnalyticsStorageByResourceChanges(
  resourceChanges: IResourceChanges,
  oldAnalyticsStorage: IAnalyticsData,
  currentReport: IReport
): IAnalyticsData {
  let updatedAnalyticsStorage = removeReactivity(oldAnalyticsStorage)

  resourceChanges?.forEach(resourceChange => {
    // Reset some saved report settings after updating resource
    if (resourceChange.field === 'tables' || resourceChange.field === 'retention_tables') {
      // Reset saved table settings after changing resource
      if (resourceChange.action === 'changed') {
        ;(resourceChange as IReportTableChanges).changes?.forEach(tableChange => {
          if (tableChange.field === 'chart_spot') {
            // Update chart settings
            updatedAnalyticsStorage = getUpdatedReportAnalyticsStorageByChartChanges(tableChange, updatedAnalyticsStorage)
          } else if (tableChange.field === 'dimensions') {
            // Update dimensions fields
            updatedAnalyticsStorage = getUpdatedReportAnalyticsStorageByDimensionChanges(tableChange, updatedAnalyticsStorage)
          } else if (tableChange.field === 'fields') {
            // Update metrics fields
            updatedAnalyticsStorage = getUpdatedReportAnalyticsStorageByMetricChanges(tableChange, updatedAnalyticsStorage)
          }
        })
      } else {
        // Update report settings
        updatedAnalyticsStorage = getUpdatedReportAnalyticsStorageByReportChanges(resourceChange, updatedAnalyticsStorage, currentReport)
      }
    }
  })

  return updatedAnalyticsStorage
}

function getUpdatedReportAnalyticsStorageByChartChanges(chartChanges: IReportChartSpotChange, oldAnalyticsStorage: IAnalyticsData): IAnalyticsData {
  const updatedAnalyticsStorage = removeReactivity(oldAnalyticsStorage)

  const removedGroupBy: string[] = chartChanges.old_data.group_by
    .filter(oldGroupBy => !chartChanges.data.group_by.some(updatedGroupBy => oldGroupBy.name === updatedGroupBy.name))
    .map(removedGroupBy => removedGroupBy.name)

  const removedOrderBy: string[] = chartChanges.old_data.order_by
    .filter(oldOrderBy => !chartChanges.data.order_by.some(updatedOrderBy => oldOrderBy.name === updatedOrderBy.name))
    .map(removedOrderBy => removedOrderBy.name)

  // Remove invalid chart group by
  if (removedGroupBy.length > 0 && Array.isArray(updatedAnalyticsStorage.chart_group_by)) {
    _remove(updatedAnalyticsStorage.chart_group_by, groupBy => groupBy && removedGroupBy.includes(groupBy))
  }

  // Remove invalid order by and select first showed order by
  if (removedOrderBy.length > 0 && chartChanges.data.group_by.length > 0 && updatedAnalyticsStorage.chart_order_by) {
    if (removedOrderBy.includes(updatedAnalyticsStorage.chart_order_by)) {
      updatedAnalyticsStorage.chart_order_by = chartChanges.data.order_by[0].name
    }
  }

  // Select first showed chart group by if all group by was removed
  if (
    Array.isArray(updatedAnalyticsStorage.chart_group_by) &&
    Array.isArray(chartChanges.data.group_by) &&
    updatedAnalyticsStorage.chart_group_by.length === 0 &&
    chartChanges.data.group_by.length > 0
  ) {
    updatedAnalyticsStorage.chart_group_by.push(chartChanges.data.group_by[0].name)
  }

  return updatedAnalyticsStorage
}

function getUpdatedReportAnalyticsStorageByDimensionChanges(
  dimensionChanges: IReportTableDimensionChanges,
  oldAnalyticsStorage: IAnalyticsData
): IAnalyticsData {
  const updatedAnalyticsStorage = removeReactivity(oldAnalyticsStorage)

  const oldDimensionName = dimensionChanges.old_data?.name
  const updatedDimensionName = dimensionChanges.data?.name

  const updatedTableType = dimensionChanges.data?.shown_type

  // Use updated dimension name
  if (dimensionChanges.action === 'changed' && oldDimensionName !== updatedDimensionName) {
    const propsToUpdate = [allDimensionsPropPath, dimensionsPropPath, downloadAllDimensionsPropPath, downloadDimensionsPropPath]

    for (const propToUpdate of propsToUpdate) {
      const storageProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageProp)) {
        // Replace old dimension name to new
        for (let i = 0; i < storageProp.length; i++) {
          if (storageProp[i] === oldDimensionName) {
            storageProp[i] = updatedDimensionName
          }
        }
      }
    }
  }

  // Add new dimension to table
  if (
    updatedTableType === 'Collapsed' ||
    updatedTableType === 'Show' ||
    updatedTableType === 'Active_And_Not_Collapsible' ||
    updatedTableType === 'Inactive_And_Not_Collapsible'
  ) {
    const propsToUpdate = [downloadAllDimensionsPropPath, allDimensionsPropPath]

    for (const propToUpdate of propsToUpdate) {
      const storageProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageProp) && !storageProp.includes(updatedDimensionName)) {
        storageProp.push(updatedDimensionName)
      }
    }
  }

  // Remove hidden dimension from table
  if (dimensionChanges.action === 'deleted' || updatedTableType === 'Hidden') {
    const propsToUpdate = [allDimensionsPropPath, dimensionsPropPath, downloadAllDimensionsPropPath, downloadDimensionsPropPath]

    for (const propToUpdate of propsToUpdate) {
      const storageProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageProp)) {
        _remove(storageProp, oldDimension => oldDimension === oldDimensionName)
      }
    }
  }

  // Select first showed dimension if all dimensions was removed
  if (
    Array.isArray(updatedAnalyticsStorage.dimensions) &&
    Array.isArray(updatedAnalyticsStorage.allReportDimensions) &&
    updatedAnalyticsStorage.dimensions.length === 0 &&
    updatedAnalyticsStorage.allReportDimensions.length > 0
  ) {
    updatedAnalyticsStorage.dimensions.push(updatedAnalyticsStorage.allReportDimensions[0])
  }

  // Select first showed downloadable dimension if all downloadable dimensions was removed
  if (
    updatedAnalyticsStorage.downloadReportFields &&
    Array.isArray(updatedAnalyticsStorage.downloadReportFields.dimensions) &&
    Array.isArray(updatedAnalyticsStorage.downloadReportFields.sortedAllDimensions) &&
    updatedAnalyticsStorage.downloadReportFields.dimensions.length === 0 &&
    updatedAnalyticsStorage.downloadReportFields.sortedAllDimensions.length > 0
  ) {
    updatedAnalyticsStorage.downloadReportFields.dimensions.push(updatedAnalyticsStorage.downloadReportFields.sortedAllDimensions[0])
  }

  return updatedAnalyticsStorage
}

function getUpdatedReportAnalyticsStorageByMetricChanges(
  metricChanges: IReportTableFieldChanges,
  oldAnalyticsStorage: IAnalyticsData
): IAnalyticsData {
  const updatedAnalyticsStorage = removeReactivity(oldAnalyticsStorage)

  const oldMetricName = metricChanges.old_data?.name
  const updatedMetricName = metricChanges.data?.name

  const updatedTableType = metricChanges.data?.info.table_presentation_type

  const allMetricsPropPath = ['allMetrics']
  const metricsPropPath = ['metrics']
  const downloadMetricsPropPath = ['downloadReportFields', 'metrics']
  const downloadAllMetricsPropPath = ['downloadReportFields', 'sortedAllMetrics']

  // Use updated metric name
  if (metricChanges.action === 'changed' && oldMetricName !== updatedMetricName) {
    const propsToUpdate = [allMetricsPropPath, metricsPropPath, downloadAllMetricsPropPath, downloadMetricsPropPath]

    for (const propToUpdate of propsToUpdate) {
      const storageProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageProp)) {
        // Replace old metric name to new
        for (let i = 0; i < storageProp.length; i++) {
          if (storageProp[i] === oldMetricName) {
            storageProp[i] = updatedMetricName
          }
        }
      }
    }
  }

  // Add new metric to table
  if (updatedTableType === 'Collapsed' || updatedTableType === 'Show') {
    const propsToUpdate = [downloadAllMetricsPropPath, allMetricsPropPath]

    for (const propToUpdate of propsToUpdate) {
      const storageProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageProp) && !storageProp.includes(updatedMetricName)) {
        storageProp.push(updatedMetricName)
      }
    }
  }

  // Remove hidden metric from table
  if (metricChanges.action === 'deleted' || updatedTableType === 'Hidden') {
    const propsToUpdate = [allMetricsPropPath, metricsPropPath, downloadAllMetricsPropPath, downloadMetricsPropPath]

    for (const propToUpdate of propsToUpdate) {
      const storageProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageProp)) {
        _remove(storageProp, oldMetric => oldMetric === oldMetricName)
      }
    }
  }

  // Select first showed metric if all metrics was removed
  if (
    Array.isArray(updatedAnalyticsStorage.metrics) &&
    Array.isArray(updatedAnalyticsStorage.allMetrics) &&
    updatedAnalyticsStorage.metrics.length === 0 &&
    updatedAnalyticsStorage.allMetrics.length > 0
  ) {
    updatedAnalyticsStorage.metrics.push(updatedAnalyticsStorage.allMetrics[0])
  }

  // Select first showed downloadable metric if all downloadable metrics was removed
  if (
    updatedAnalyticsStorage.downloadReportFields &&
    Array.isArray(updatedAnalyticsStorage.downloadReportFields.metrics) &&
    Array.isArray(updatedAnalyticsStorage.downloadReportFields.sortedAllMetrics) &&
    updatedAnalyticsStorage.downloadReportFields.metrics.length === 0 &&
    updatedAnalyticsStorage.downloadReportFields.sortedAllMetrics.length > 0
  ) {
    updatedAnalyticsStorage.downloadReportFields.metrics.push(updatedAnalyticsStorage.downloadReportFields.sortedAllMetrics[0])
  }

  return updatedAnalyticsStorage
}

function getUpdatedReportAnalyticsStorageByReportChanges(reportChange: IResourceChange, oldAnalyticsStorage: IAnalyticsData, currentReport: IReport) {
  let updatedAnalyticsStorage = removeReactivity(oldAnalyticsStorage)

  const isRetention = currentReport.retention_tables.length
  const currentReportTables: Array<IRetentionTable | ITable> = isRetention ? currentReport.retention_tables : currentReport.tables
  const currentReportChartSpot = isRetention ? currentReport.retention_chart_spots : currentReport.chart_spots

  if (reportChange.action === 'deleted') {
    if (updatedAnalyticsStorage.reportTableID === reportChange.id) {
      const reportFieldsToRemove = [
        'chart_group_by',
        'chart_order_by',
        'additional_chart_type',
        'metrics',
        'allMetrics',
        'dimensions',
        'allReportDimensions',
        'downloadReportFields',
        'reportTableID'
      ]
      updatedAnalyticsStorage = removePropsInObj(oldAnalyticsStorage, reportFieldsToRemove)

      const currentTable = (
        isRetention ? (currentReportTables[0] as IRetentionTable) : defaultCurrentReportTable(currentReportTables as ITable[])
      ) as IRetentionTable | ITable

      updatedAnalyticsStorage.dimensions =
        currentTable?.dimensions
          .filter(dimension => dimension.shown_type === 'Show' || dimension.shown_type === 'Active_And_Not_Collapsible')
          .map(dimension => dimension.name)
          .slice(0, 8) ?? []

      const currentChartSpot = isRetention
        ? currentReportChartSpot[0]
        : currentReportChartSpot.find(chartSpot => chartSpot.table_id === currentTable.id)

      updatedAnalyticsStorage.chart_group_by = currentChartSpot ? [currentChartSpot.group_by.map(groupByItem => groupByItem.name)[0]] : []
      updatedAnalyticsStorage.chart_order_by = currentChartSpot?.order_by.map(orderByItem => orderByItem.name)[0] ?? ''
    } else {
      const allDimensions = _uniq(
        currentReportTables
          .reduce((allDimensions: ITableDimension[], table) => {
            allDimensions.push(...table.dimensions)
            return allDimensions
          }, [])
          .map(dimension => dimension.name)
      )
      const allMetrics = _uniq(
        currentReportTables
          .reduce((allMetrics: ITableField[], table) => {
            allMetrics.push(...table.fields)
            return allMetrics
          }, [])
          .map(metric => metric.name)
      )

      const allGroupBy = _uniq(
        currentReportChartSpot
          .reduce((allGroupBy: IChartSpotData[], chart) => {
            allGroupBy.push(...chart.group_by)
            return allGroupBy
          }, [])
          .map(groupBy => groupBy.name)
      )

      const allOrderBy = _uniq(
        currentReportChartSpot
          .reduce((allOrderBy: IChartSpotData[], chart) => {
            allOrderBy.push(...chart.order_by)
            return allOrderBy
          }, [])
          .map(orderBy => orderBy.name)
      )

      // Remove invalid metrics from table
      const metricPropsToUpdate = [allMetricsPropPath, metricsPropPath, downloadAllMetricsPropPath, downloadMetricsPropPath]
      for (const propToUpdate of metricPropsToUpdate) {
        const storageMetricProp = _get(updatedAnalyticsStorage, propToUpdate)

        if (Array.isArray(storageMetricProp)) {
          _remove(storageMetricProp, metric => !allMetrics.some(showedMetric => showedMetric === metric))
        }
      }

      // Remove invalid dimensions from table
      const dimensionPropsToUpdate = [allDimensionsPropPath, dimensionsPropPath, downloadAllDimensionsPropPath, downloadDimensionsPropPath]
      for (const propToUpdate of dimensionPropsToUpdate) {
        const storageDimensionProp = _get(updatedAnalyticsStorage, propToUpdate)

        if (Array.isArray(storageDimensionProp)) {
          _remove(storageDimensionProp, dimension => !allDimensions.some(showedDimensions => showedDimensions === dimension))
        }
      }

      // Remove invalid chart group by
      if (Array.isArray(updatedAnalyticsStorage.chart_group_by)) {
        _remove(updatedAnalyticsStorage.chart_group_by, groupBy => !allGroupBy.some(showedGroupBy => showedGroupBy === groupBy))
      }

      // Remove invalid order by and select first showed order by
      if (allOrderBy.length > 0 && updatedAnalyticsStorage.chart_order_by) {
        if (!allOrderBy.includes(updatedAnalyticsStorage.chart_order_by)) {
          updatedAnalyticsStorage.chart_order_by = allOrderBy[0]
        }
      }

      // Select first showed chart group by if all group by was removed
      if (
        Array.isArray(updatedAnalyticsStorage.chart_group_by) &&
        Array.isArray(allGroupBy) &&
        updatedAnalyticsStorage.chart_group_by.length === 0 &&
        allGroupBy.length > 0
      ) {
        updatedAnalyticsStorage.chart_group_by.push(allGroupBy[0])
      }
    }
  } else if (reportChange.action === 'added' && reportChange.field !== 'retention_tables') {
    // Add new metrics to table
    const addedMetrics = Object.values(reportChange.data.fields)
    const metricPropsToUpdate = [allMetricsPropPath, downloadAllMetricsPropPath]
    for (const propToUpdate of metricPropsToUpdate) {
      const storageMetricProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageMetricProp)) {
        for (const addedMetric of addedMetrics) {
          if (
            (addedMetric.info.table_presentation_type === 'Show' || addedMetric.info.table_presentation_type === 'Collapsed') &&
            !storageMetricProp.includes(addedMetric.name)
          ) {
            storageMetricProp.push(addedMetric.name)
          }
        }
      }
    }

    // Add new dimensions to table
    const addedDimensions = Object.values(reportChange.data.dimensions)
    const dimensionPropsToUpdate = [allDimensionsPropPath, downloadAllDimensionsPropPath]
    for (const propToUpdate of dimensionPropsToUpdate) {
      const storageDimensionProp = _get(updatedAnalyticsStorage, propToUpdate)

      if (Array.isArray(storageDimensionProp)) {
        for (const addedDimension of addedDimensions) {
          if (
            (addedDimension.shown_type === 'Show' ||
              addedDimension.shown_type === 'Collapsed' ||
              addedDimension.shown_type === 'Active_And_Not_Collapsible' ||
              addedDimension.shown_type === 'Inactive_And_Not_Collapsible') &&
            !storageDimensionProp.includes(addedDimension.name)
          ) {
            storageDimensionProp.push(addedDimension.name)
          }
        }
      }
    }
  }

  return updatedAnalyticsStorage
}

function getUpdatedDashboardAnalyticsStorageByResourceChanges(
  resourceChanges: IFiltersChange[],
  oldAnalyticsStorage: IAnalyticsData
): IAnalyticsData {
  let updatedAnalyticsStorage = removeReactivity(oldAnalyticsStorage)

  resourceChanges.forEach(resourceChange => {
    // Reset some saved report settings after updating resource
    if (resourceChange.field === 'filters') {
      const filterFieldsToRemove = ['autoUpdatedFilter']
      updatedAnalyticsStorage = removePropsInObj(updatedAnalyticsStorage, filterFieldsToRemove)
    }
  })

  return updatedAnalyticsStorage
}

function removePropsInObj(oldAnalyticsStorage: IAnalyticsData, propsToRemove: string[] = []): IAnalyticsData {
  const updatedObject = removeReactivity(oldAnalyticsStorage)
  for (const fieldToRemove of propsToRemove) {
    delete updatedObject[fieldToRemove as keyof IAnalyticsData]
  }
  return updatedObject
}
