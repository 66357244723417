
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import NotificationsMenu from '@/components/Header/NotificationsMenu.vue'
import UserMenu from '@/components/Header/UserMenu.vue'
import ServerTime from '@/components/Header/ServerTime.vue'
import MainMenu from '@/components/Header/MainMenu.vue'
import HelpMenu from '@/components/Header/HelpMenu.vue'
import MenuSubLevelSelect from '@/components/Header/MenuSubLevelSelect.vue'
import SnapshotSelect from '@/components/Header/SnapshotSelect.vue'
import { storeApp } from '@/store/modules/app'
import { storeAuth } from '@/store/modules/auth'

@Component({
  components: {
    SnapshotSelect,
    MenuSubLevelSelect,
    MainMenu,
    ServerTime,
    UserMenu,
    HelpMenu,
    NotificationsMenu
  }
})
export default class Header extends Vue {
  isLoading = false

  @Watch('isAuthenticated')
  onIsAuthenticatedChanged(newVal: boolean) {
    if (newVal) this.getInitialData()
  }

  async mounted() {
    if (this.isAuthenticated) {
      await storeAuth.login()
      await this.getInitialData()
    }

    // reassign favicon depending on environment
    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']")
    if (link && this.environmentFavicon) link.href = `/${this.environmentFavicon}`
  }

  get currentRouteName() {
    return storeApp.currentRouteName
  }
  get isAuthenticated() {
    return storeAuth.isAuthenticated
  }
  get environmentClass(): string {
    return {
      dev: 'pink',
      staging: 'orange',
      production: '',
      'pre-prod': 'green'
      // eslint-disable-next-line no-undef
      // @ts-ignore
    }[config.ENVIRONMENT || process.env.NODE_ENV]
  }
  get environmentFavicon(): string {
    return {
      dev: 'favicon_dev.ico',
      staging: 'favicon_staging.ico',
      production: 'favicon.ico',
      'pre-prod': 'favicon_pre-prod.ico'
      // eslint-disable-next-line no-undef
      // @ts-ignore
    }[config.ENVIRONMENT || process.env.NODE_ENV]
  }
  get toShowMenuSelects() {
    return (
      this.$route.matched.findIndex(matchedRoute =>
        [
          'reportsPage',
          'inventoryPage',
          'funnelsPage',
          'dashboardPage',
          'configurationsPage',
          'grabbersPage',
          'grabberDetailsPage',
          'contentServerPage',
          'uaChronologyPage'
        ].includes(matchedRoute.name || '')
      ) !== -1
    )
  }
  get toShowSnapshotMenu() {
    return ['reportsPage', 'dashboardPage'].includes(this.currentRouteName)
  }
  async login() {
    await storeAuth.loadAuthURL()
  }

  async getInitialData() {
    this.isLoading = true
    await storeApp.getInitialData()
    this.isLoading = false
  }
}
