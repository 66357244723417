
import Vue from 'vue'
import { Component, Ref } from 'vue-property-decorator'

import MainHeader from '@/components/Header/MainHeader.vue'
import _MagicLinks from '@/components/_MagicLinks.vue'
import MaintenanceModal from '@/components/common/MaintenanceModal.vue'

@Component({ components: { MaintenanceModal, MagicLinks: _MagicLinks, MainHeader } })
export default class App extends Vue {
  @Ref('app') readonly appRef!: HTMLElement

  mounted() {
    console.log(
      // eslint-disable-next-line no-undef
      `%c BUILD TIME %c ${process.env.VUE_APP_BUILD_TIME} \n%c CURRENT VERSION %c ${process.env.VUE_APP_VERSION || config.VUE_APP_VERSION} `,
      'background: #3652F1; color: #fff; border-radius: 3px 0 0 3px;',
      'background: #809CFF; color: #000; border-radius: 0 3px 3px 0;',
      'background: #52F136; color: #000; border-radius: 3px 0 0 3px;',
      'background: #9CFF80; color: #000; border-radius: 0 3px 3px 0;'
    )
    window.addEventListener('scroll', this.onScroll)
    document.addEventListener('visibilitychange', this.onResetFocus)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    document.removeEventListener('visibilitychange', this.onResetFocus)
  }

  onScroll() {
    window.scrollY > 0 ? this.appRef.classList.add('scrolled') : this.appRef.classList.remove('scrolled')
  }

  onResetFocus() {
    ;(document.activeElement as HTMLElement)?.blur()
  }
}
