import Vue from 'vue'

const fakeSelectEl: HTMLElement = document.createElement('div')
fakeSelectEl.setAttribute('class', 'fake-select-length')

let fakeSelectDOM: HTMLElement | null = null

Vue.directive('fluid-select', {
  inserted(el) {
    el.appendChild(fakeSelectEl)
    fakeSelectDOM = el.querySelector('.fake-select-length')
  },
  componentUpdated(el, { value }) {
    let longestName = ''
    // Value is the list of the options
    value.forEach((listItem: { value: string; label: string }) => {
      if (listItem.label.length > longestName.length) {
        longestName = listItem.label
      }
    })

    const timeout = setTimeout(() => {
      el.style.width = `${fakeSelectDOM!.offsetWidth + 10}px`
      clearTimeout(timeout)
    }, 50)

    fakeSelectDOM!.innerHTML = longestName
  }
})
