import Vue from 'vue'
import { isEmpty as _isEmpty, isEqual, orderBy } from 'lodash'
import { VuexModule, Module, Action, getModule, MutationAction, Mutation } from 'vuex-module-decorators'

import store from '@/store'
import { storeApp } from '@/store/modules/app'
import { RESOURCE_TYPES } from '@/helpers/constants'
import { IAnalyticsData } from '@/store/typings/analyticsStorageData'
import { ISelectedFilter } from '@/store/typings/filter'
import { IResourceChanges } from '@/store/typings/resourceChanges'
import { ICreateSnapshotParams, ILoadSnapshotsListParams, ISnapshot, ISnapshotsState, IUpdateSnapshotParams } from '@/store/typings/snapshots'
import { isEqualFilters, getUpdatedAnalyticsStorageByResourceChanges } from '@/helpers'

@Module({ dynamic: true, store, namespaced: true, name: 'snapshots' })
class SnapshotsModule extends VuexModule implements ISnapshotsState {
  _snapshotsList: ISnapshot[] = []
  _activeSnapshot: ISnapshot | null = null
  _updatedActiveSnapshotData: IAnalyticsData = {}

  get resourceType(): TResourceType {
    return RESOURCE_TYPES[storeApp.currentRouteName as keyof IResourceTypes]
  }

  get activeSnapshot(): ISnapshot | null {
    return this._activeSnapshot
  }

  get activeSnapshotData(): IAnalyticsData {
    return this.activeSnapshot?.data ?? {}
  }

  get activeSnapshotDataToUpdate(): IAnalyticsData {
    return Object.keys(this.updatedActiveSnapshotData)
      .filter(updatedPropKey => !['version_id', 'autoUpdatedFilter'].includes(updatedPropKey))
      .reduce((dataToUpdate, updatedPropKey) => {
        const activeSnapshotPropData = this.activeSnapshotData[updatedPropKey as keyof IAnalyticsData]
        const analyticsUpdatedPropData = this.updatedActiveSnapshotData[updatedPropKey as keyof IAnalyticsData]

        const isEqualData: boolean =
          updatedPropKey === 'filters'
            ? isEqualFilters(activeSnapshotPropData as ISelectedFilter[], analyticsUpdatedPropData as ISelectedFilter[])
            : isEqual(activeSnapshotPropData, analyticsUpdatedPropData)

        if (!isEqualData && analyticsUpdatedPropData) {
          return { ...dataToUpdate, [updatedPropKey]: analyticsUpdatedPropData }
        }
        return dataToUpdate
      }, {})
  }

  get activeSnapshotDataIsNotActual(): boolean {
    return !!this.activeSnapshot && !_isEmpty(this.activeSnapshotDataToUpdate)
  }

  get updatedActiveSnapshotData(): IAnalyticsData {
    return this._updatedActiveSnapshotData
  }

  get snapshotsList(): ISnapshot[] {
    return orderBy(this._snapshotsList, 'name')
  }

  get snapshotNames(): string[] {
    return this.snapshotsList.map(snapshot => snapshot.name)
  }

  @Mutation
  setUpdatedAnalyticsStorageData(updatedActiveSnapshotData: IAnalyticsData) {
    this._updatedActiveSnapshotData = updatedActiveSnapshotData ?? {}
  }

  @Mutation
  addUpdatedAnalyticsStorageData(updatedActiveSnapshotData: IAnalyticsData) {
    if (this._activeSnapshot?.data) {
      this._updatedActiveSnapshotData = {
        ...this._updatedActiveSnapshotData,
        ...updatedActiveSnapshotData
      }
    }
  }

  @Mutation
  setActiveSnapshot(snapshot: ISnapshot | null) {
    this._activeSnapshot = snapshot
    this._updatedActiveSnapshotData = {}
  }

  @MutationAction
  async loadSnapshotsList(payload: ILoadSnapshotsListParams) {
    const { resource_type, resource_id } = payload
    const snapshots: ISnapshot[] = await Vue.prototype.$api('getSnapshotsList', { resource_type, resource_id })

    return { _snapshotsList: snapshots }
  }

  @Action
  async createSnapshot(payload: ICreateSnapshotParams) {
    const { name, resource_type, resource_id, ...rest } = payload
    return (await Vue.prototype.$api('createSnapshot', { name, resource_type, resource_id, data: { ...rest } })) as ISnapshot
  }

  @Action
  async loadSnapshot(snapshot_id: string) {
    const snapshot: ISnapshot = await Vue.prototype.$api('getSnapshot', { snapshot_id })

    const previews_version_id = snapshot.data.version_id
    const current_version_id = storeApp.currentEntity?.version_id

    if (
      previews_version_id &&
      storeApp.currentEntity &&
      snapshot.resource_type === RESOURCE_TYPES.reportsPage &&
      previews_version_id !== current_version_id
    ) {
      this.updateActiveSnapshotByResourceChanges({ snapshotToUpdate: snapshot, resourceType: RESOURCE_TYPES.reportsPage })
    } else {
      this.setActiveSnapshot(snapshot)
    }
  }

  @Action
  async updateActiveSnapshotByResourceChanges({
    snapshotToUpdate,
    resourceType,
    resourceChanges
  }: {
    snapshotToUpdate: ISnapshot
    resourceType: TResourceType
    resourceChanges?: IResourceChanges
  }) {
    const snapshotResourceChanges: IResourceChanges =
      resourceChanges ||
      (await Vue.prototype.$api('getResourceChanges', {
        resource_id: storeApp.resourceID,
        version_id: snapshotToUpdate.data.version_id,
        resource_type: resourceType
      }))

    const updatedSnapshotData: IAnalyticsData = getUpdatedAnalyticsStorageByResourceChanges(
      snapshotResourceChanges,
      snapshotToUpdate.data,
      resourceType,
      storeApp.currentEntity
    )

    if (storeApp.currentEntity?.version_id && storeApp.currentEntity.version_id !== updatedSnapshotData.version_id) {
      updatedSnapshotData.version_id = storeApp.currentEntity.version_id
    }

    const updatedSnapshot = { ...snapshotToUpdate, data: updatedSnapshotData }

    this.updateSnapshot({ snapshot_id: updatedSnapshot.id, name: updatedSnapshot.name, ...updatedSnapshotData })
  }

  @Action
  async updateSnapshot(payload: IUpdateSnapshotParams) {
    const { name, snapshot_id, ...rest } = payload

    const updatedSnapshot = await Vue.prototype.$api('updateSnapshot', { name, snapshot_id, data: { ...rest } })
    this.setActiveSnapshot(updatedSnapshot)
  }

  @Action
  async removeSnapshot(snapshot_id: string) {
    await Vue.prototype.$api('removeSnapshot', { snapshot_id })
  }
}

export const storeSnapshots: InstanceType<typeof SnapshotsModule> = getModule(SnapshotsModule)
