import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { storeApp } from '@/store/modules/app'
import { storeAuth } from '@/store/modules/auth'
import { storeSnapshots } from '@/store/modules/snapshots'
import { getSessionStorageByPath, setSessionStorage } from '@/helpers'
import { CUSTOM_GLOBAL_UUID, NULLABLE_FILTER_VALUE } from '@/helpers/constants'
import { IChoiceResponse, IChoicesResponse, ISelectedChoiceFilter, ISelectedFilter } from '@/store/typings/filter'
import { IAnalyticsData, IAnalyticsStorageDataState, IInitAnalyticsStorageData } from '@/store/typings/analyticsStorageData'

@Module({ dynamic: true, store, namespaced: true, name: 'analyticsStorageData' })
class AnalyticsStorageDataModule extends VuexModule implements IAnalyticsStorageDataState {
  data: IAnalyticsData = {}
  globalData: IAnalyticsData = {}
  isLoaded: boolean = false

  get analyticsAllData(): IAnalyticsData {
    return this.data
  }

  get analyticsFilters() {
    return this.data?.filters ?? []
  }

  get analyticsFiltersPrepared() {
    return this.analyticsFilters.reduce(
      (analyticsFilters, analyticFilter) => ({ ...analyticsFilters, [analyticFilter.choice_type_pk]: analyticFilter.options }),
      {}
    )
  }

  get analyticsAutoUpdatedFilters() {
    return this.data?.autoUpdatedFilter ?? {}
  }

  get globalAnalyticsPinnedOptions() {
    return this.globalData?.pinnedOptions ?? {}
  }

  @Mutation
  setAnalyticsIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded
  }

  @Mutation
  setGlobalAnalyticsStorageData(globalAnalyticsData: IAnalyticsData) {
    this.globalData = globalAnalyticsData ?? {}
  }

  @Mutation
  setAnalyticsStorageData(analyticStorageData: IAnalyticsData) {
    this.data = analyticStorageData ?? {}
    setSessionStorage(storeApp.resourceID, this.data)
  }

  @Action
  async updateGlobalAnalyticsStorageData(globalAnalyticsData: IAnalyticsData) {
    this.setGlobalAnalyticsStorageData(globalAnalyticsData)
    await Vue.prototype.$api('setAnalyticStorageData', { key: CUSTOM_GLOBAL_UUID, data: globalAnalyticsData })
  }

  @Action
  async loadGlobalAnalyticsStorageData() {
    const globalAnalyticsData: IAnalyticsData = await Vue.prototype.$api('getAnalyticStorageData', { key: CUSTOM_GLOBAL_UUID })
    this.setGlobalAnalyticsStorageData(globalAnalyticsData)
  }

  @Action
  async initAnalyticsStorageData({ customPredefinedFilter, resourceType }: IInitAnalyticsStorageData) {
    const savedStorageData = getSessionStorageByPath<IAnalyticsData>(storeApp.resourceID)

    if (savedStorageData) {
      this.setAnalyticsIsLoaded(true)
      this.setAnalyticsStorageData(savedStorageData)
    } else {
      await this.loadAnalyticsStorageData(customPredefinedFilter)
    }

    if (resourceType) {
      await storeApp.loadResourceChanges(resourceType)
      storeSnapshots.setUpdatedAnalyticsStorageData({})
    }
  }

  @Action
  async loadAnalyticsStorageData(customPredefinedFilter?: ISelectedFilter[]) {
    this.setAnalyticsIsLoaded(false)

    let analyticsStorageDataResponse: IAnalyticsData = (await Vue.prototype.$api('getAnalyticStorageData', { key: storeApp.resourceID })) ?? {}
    const permissionCheckedFilters = analyticsStorageDataResponse?.filters
      ? await this.checkFiltersForPermissions(analyticsStorageDataResponse.filters)
      : null

    if (!analyticsStorageDataResponse.version_id && storeApp.currentEntity?.version_id) {
      analyticsStorageDataResponse.version_id = storeApp.currentEntity.version_id
    }
    if (permissionCheckedFilters) analyticsStorageDataResponse.filters = permissionCheckedFilters

    if (Array.isArray(customPredefinedFilter) && customPredefinedFilter.length > 0) {
      analyticsStorageDataResponse = { filters: [...customPredefinedFilter] }
    }

    this.setAnalyticsIsLoaded(true)
    this.setAnalyticsStorageData(analyticsStorageDataResponse)
  }

  @Action
  async checkFiltersForPermissions(analyticsStorageFilters: ISelectedFilter[]) {
    const filterTypeChoice = analyticsStorageFilters.filter(predefinedFilter => predefinedFilter.filter_type === 'choice')
    const filterTypeRest = analyticsStorageFilters.filter(predefinedFilter => predefinedFilter.filter_type !== 'choice')
    const choicePromisesArray = filterTypeChoice.map(async predefinedFilter => {
      return (await Vue.prototype.$api('getChoices', {
        choice_type_id: predefinedFilter.choice_type,
        uuids: (predefinedFilter as ISelectedChoiceFilter).options.filter(option => option !== NULLABLE_FILTER_VALUE),
        page_limit: predefinedFilter.options.length
      })) as IChoicesResponse
    })
    const choicesPromise: IChoicesResponse[] = await Promise.all(choicePromisesArray)

    const checkedFilterTypeChoices = filterTypeChoice.filter((filterTypeChoiceItem, index) => {
      const choices: IChoiceResponse[] = choicesPromise[index].choices
      return choices.length ?? choices.map(choice => choice.key_name)
    })

    return [...checkedFilterTypeChoices, ...filterTypeRest]
  }

  @Action
  async updateAnalyticsStorageData(payload: IAnalyticsData) {
    if (!storeAuth.isAuthenticated) return

    const { routerKey = storeApp.resourceID, ...data } = payload
    const analyticsData = { ...this.data, ...data, version_id: storeApp.currentEntity?.version_id }

    this.setAnalyticsStorageData(analyticsData)

    await Vue.prototype.$api('setAnalyticStorageData', { key: routerKey, data: analyticsData })

    storeSnapshots.addUpdatedAnalyticsStorageData(data)
  }
}

export const storeAnalyticsStorageData: InstanceType<typeof AnalyticsStorageDataModule> = getModule(AnalyticsStorageDataModule)
