import { orderBy } from 'lodash'

import { getDayFromToday } from '@/helpers'
import { DATE_UNITS_MAP, SERVER_DATE_FORMAT } from '@/helpers/constants'
import { IReport } from '@/store/typings/report'
import {
  IDashboard,
  IDateSetup,
  IChart,
  IChartGroup,
  IChartPoint,
  IMapChart,
  ICommonChart,
  IChartBase,
  IChartResponse,
  IMapChartPoint,
  IFabricFilter
} from '@/store/typings/dashboard'

export function parseCharts(
  charts: IChartResponse[],
  reports: IReport[],
  cohortDayShift: number,
  currentDashboard: IDashboard
): IChartGroup[] | null {
  const sortedFabricFilters = currentDashboard.fabric_filters.sort((a, b) => b.presentation_name.length - a.presentation_name.length)

  const filledCharts = charts
    .filter(chart => !!chart)
    .sort((a, b) => a.presentation_name.localeCompare(b.presentation_name, undefined, { numeric: true }))
  const isEmpty = filledCharts.length === 0 || filledCharts.every(chart => chart.points.every(point => !point.y))

  if (isEmpty) return null

  const sortedCharts = orderBy(filledCharts, [
    'group_idx',
    chart => chart.metric_idx,
    chart => {
      if (chart.is_fabric) {
        const presentationName = chart.presentation_name.toLowerCase()
        if (presentationName.includes('others')) {
          return 1
        } else if (presentationName.includes('total')) {
          return -1
        }
        return 0
      }
    }
  ])

  return sortedCharts.reduce((parsedChartsGroups: IChartGroup[], chart) => {
    const uniqGroupID = chart.is_fabric ? getUniqGroupId(chart, sortedFabricFilters) : chart.group_id
    const parsedChartsGroup = parsedChartsGroups.find((parsedChart: IChartGroup) => parsedChart.groupId === uniqGroupID)
    const hasPieChart = chart.group_is_pie_presentation_possible && Number(chart.pie) > 0
    const isMapChart = chart.chart_type === 'map'
    const isEmptyChart = !chart.points || chart.points.length === 0 || chart.points.every(point => !point.y || Number(point.y) === 0)
    const formattedDateSetup = getFormattedDateSetup(currentDashboard.date_setup, chart.x_column_from, chart.x_column_to, cohortDayShift)

    if (parsedChartsGroup) {
      parsedChartsGroup.data.push(parseChartData(chart, reports, formattedDateSetup, cohortDayShift, isEmptyChart))

      if (!parsedChartsGroup.hasPieChart && hasPieChart) {
        parsedChartsGroup.hasPieChart = hasPieChart
      } else if (!parsedChartsGroup.hasMapChart && isMapChart) {
        parsedChartsGroup.hasMapChart = isMapChart
      }

      // Set the highest value to predict_from property
      if (parsedChartsGroup.dateSetup.predict_from && chart.predict_x_column_from > parsedChartsGroup.dateSetup.predict_from) {
        parsedChartsGroup.dateSetup.predict_from = chart.predict_x_column_from
      }

      // Check for empty chart
      if (parsedChartsGroup.isEmptyGroup && !isEmptyChart) {
        parsedChartsGroup.isEmptyGroup = isEmptyChart
      }

      return parsedChartsGroups
    }

    return [
      ...parsedChartsGroups,
      {
        groupId: uniqGroupID,
        groupHint: chart.group_hint,
        dateSetup: { ...formattedDateSetup, predict_from: chart.predict_x_column_from ?? 0 },
        presentationName: chart.group_presentation_name,
        isEmptyGroup: isEmptyChart,
        data: [parseChartData(chart, reports, formattedDateSetup, cohortDayShift, isEmptyChart)],
        valueType: chart.y_value_type,
        valueTypeX: chart.x_value_type,
        hasMapChart: isMapChart,
        hasPieChart
      }
    ]
  }, [])
}

export function getFormattedDateSetup(dateSetup: IDateSetup, x_column_from: number, x_column_to: number, cohortDayShift: number): IDateSetup {
  return { from: x_column_from ?? dateSetup.from, to: (x_column_to ?? dateSetup.to) - cohortDayShift, compare_with: dateSetup.compare_with }
}

export function parseChartData(
  chart: IChartResponse,
  reports: IReport[],
  dateSetup: IDateSetup,
  cohortDayShift: number,
  isEmptyChart: boolean
): IChart {
  const report = reports.find(report => report.id === chart.report_id)
  const isMapChart = chart.chart_type === 'map'
  return isMapChart ? parseMapChart(chart) : parseCommonChart(chart, dateSetup, cohortDayShift, isEmptyChart, report)
}

export function parseMapChart(chart: IChartResponse): IMapChart {
  return { ...parseBaseChart(chart), points: chart.points as IMapChartPoint[], isMapChart: true }
}

export function parseCommonChart(
  chart: IChartResponse,
  dateSetup: IDateSetup,
  cohortDayShift: number,
  isEmptyChart: boolean,
  report?: IReport
): ICommonChart {
  return {
    ...parseBaseChart(chart),
    median: calculateMedian(chart.points),
    points: parseChartPoints(chart.points, dateSetup, chart.x_value_type as TDateType),
    predictedPoints: chart.predicted_points.length
      ? parsePredictedChartPoints(chart.predicted_points, dateSetup, chart.x_value_type as TDateType)
      : [],
    isFabric: chart.is_fabric,
    pieChart: chart.group_is_pie_presentation_possible ? chart.pie : null,
    predictFrom: chart.predict_x_column_from ?? 0,
    pieValueType: chart.group_is_pie_presentation_possible ? chart.pie_value_type : null,
    pieShare: chart.group_is_pie_presentation_possible ? chart.pie_share : null,
    reportId: chart.report_id,
    reportGroupId: chart.report_group_id,
    reportPresentationName: report?.name ?? null,
    isEmpty: isEmptyChart,
    headerIDx: (chart.header_idx ?? -1) - cohortDayShift,
    isMapChart: false
  }
}

export function parseBaseChart(chart: IChartResponse): IChartBase {
  return {
    yDigits: chart.y_rounding,
    presentationName: chart.presentation_name,
    hint: chart.hint,
    color: chart.color,
    order: chart.metric_idx,
    chartType: chart.chart_type,
    extraValueType: chart.y_extra_value_type
  }
}

export function parseChartPoints(points: IChartPoint[], dateSetup: IDateSetup, xValueType: TDateType): IChartPoint[] {
  const fromDays = dateSetup.compare_with < 0 ? dateSetup.from + dateSetup.compare_with : dateSetup.from
  const toDays = 0
  const parsedChartPoints = []

  for (let currentDay = fromDays; currentDay <= toDays; currentDay++) {
    const day = getDayFromToday(currentDay, xValueType)

    const defaultPoint = { x: day.format(SERVER_DATE_FORMAT), y: null, y_extra: null }

    const filledPoint = points.find(point => day.isSame(point.x, DATE_UNITS_MAP[xValueType]))

    const point = filledPoint || defaultPoint

    parsedChartPoints.push(point)
  }

  return parsedChartPoints
}

export function parsePredictedChartPoints(predictedPoints: IChartPoint[], dateSetup: IDateSetup, xValueType: TDateType): IChartPoint[] {
  const fromDays = dateSetup.compare_with < 0 ? dateSetup.from + dateSetup.compare_with : dateSetup.from
  const toDays = dateSetup.compare_with < 0 ? dateSetup.to : dateSetup.to + dateSetup.compare_with

  const parsedChartPoints = []
  for (let currentDay = fromDays; currentDay <= toDays; currentDay++) {
    const day = getDayFromToday(currentDay, xValueType)
    const filledPoint = predictedPoints.find(point => day.isSame(point.x, DATE_UNITS_MAP[xValueType]))

    if (filledPoint) {
      parsedChartPoints.push(filledPoint)
    } else {
      // Generate predicted point to create empty gap at chart
      const defaultPoint = { x: day.format(SERVER_DATE_FORMAT), y: null, y_extra: null }
      parsedChartPoints.push(defaultPoint)
    }
  }
  return parsedChartPoints
}

export function calculateMedian(points: IChartPoint[]): number {
  const sum = points.reduce((sum: number, point: IChartPoint) => (sum += point.y ? parseFloat(point.y) : 0), 0)
  return sum / points.length
}

export function getUniqGroupId(chart: IChartResponse, fabricFilters: IFabricFilter[]) {
  const fabricFilter = fabricFilters.find(fabricFilter => chart.group_presentation_name.includes(fabricFilter.presentation_name))
  return fabricFilter ? `${chart.group_id}_${fabricFilter?.group_id}` : chart.group_id
}
