import store from '@/store'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { IMagicLinksState } from '@/store/typings/magicLinks'
import { IRealtimeDashboardSettings } from '@/typings/components/dashboard'

@Module({ dynamic: true, store, namespaced: true, name: 'magicLinks' })
class MagicLinksModule extends VuexModule implements IMagicLinksState {
  public isVisible: boolean = false
  public realtimeDashboardSettings: IRealtimeDashboardSettings = {}
  configAccessEnabled: boolean = false
  configAccess: Partial<{
    can_read: boolean
    can_create: boolean
    can_update: boolean
    can_delete: boolean
  }> = {}

  @Mutation
  setIsVisibleState(toShow: boolean) {
    this.isVisible = toShow
  }

  @Mutation
  setRealtimeDashboardSettings(settings: IRealtimeDashboardSettings) {
    this.realtimeDashboardSettings = settings
  }

  @Mutation
  setConfigAccessEnabled(enabled: boolean) {
    this.configAccessEnabled = enabled
  }

  @Mutation
  setConfigAccess(configAccess: any) {
    this.configAccess = configAccess
  }

  @Action
  triggerMagicLinks(toShow: boolean) {
    this.setIsVisibleState(toShow)
  }

  @Action
  saveRealtimeDashboardSettings(settings: IRealtimeDashboardSettings) {
    this.setRealtimeDashboardSettings(settings)
  }
}

export const storeMagicLinks: InstanceType<typeof MagicLinksModule> = getModule(MagicLinksModule)
