
import Vue from 'vue'
import Lottie from 'lottie-web'
import { Component, Prop, Ref } from 'vue-property-decorator'

import darkRocketData from './LoaderRocket/data/dark.json'
import lightRocketData from './LoaderRocket/data/light.json'

@Component
export default class LoaderRocket extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  dark!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  overlay!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  fullScreen!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  loading!: boolean

  @Ref('loader') readonly loaderRef!: Element

  mounted() {
    Lottie.loadAnimation({
      container: this.loaderRef,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: this.dark ? darkRocketData : lightRocketData
    })
  }
}
