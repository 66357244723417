
import Vue from 'vue'
import Component from 'vue-class-component'
import { Route } from 'vue-router'
import { Watch } from 'vue-property-decorator'

import { storeSnapshots } from '@/store/modules/snapshots'

@Component
export default class SnapshotSelect extends Vue {
  snapshotId: string | null = null

  get snapshots() {
    return storeSnapshots.snapshotsList
  }
  get resourceType() {
    return storeSnapshots.resourceType
  }

  @Watch('$route', { immediate: true })
  async onRouteChange(currentRouter: Route, prevRouter: Route) {
    if (!prevRouter && currentRouter.query?.snapshot) {
      const snapshot = this.$route.query.snapshot as string

      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, snapshot: undefined }
      })

      await storeSnapshots.loadSnapshot(snapshot)
      await this.loadSnapshotsList()
      this.snapshotId = snapshot
    } else if (prevRouter && currentRouter.path !== prevRouter?.path) {
      this.resetSnapshots()
      this.loadSnapshotsList()
    } else if (!prevRouter || prevRouter.query?.snapshot !== currentRouter.query?.snapshot) {
      this.loadSnapshotsList()
    }
  }

  async loadSnapshotsList() {
    await storeSnapshots.loadSnapshotsList({ resource_type: this.resourceType, resource_id: this.$route.params.id })
  }

  async deleteSnapshot(snapshotId: string, snapshotName: string) {
    try {
      await this.$confirm(`Are you sure you want to delete "${snapshotName}" snapshot?`, 'Delete snapshot', {
        confirmButtonText: 'Delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonClass: 'el-button--text',
        roundButton: true
      })

      if (this.snapshotId === snapshotId) this.resetSnapshots()
      await storeSnapshots.removeSnapshot(snapshotId)
      this.$sauronNotify.show({
        type: 'info',
        message: `${snapshotName} - was successfully deleted`
      })
      await this.loadSnapshotsList()
    } catch (e) {
      console.log(e)
    }
  }

  resetSnapshots() {
    this.snapshotId = null
    storeSnapshots.setActiveSnapshot(null)
  }

  shareSnapshot(snapshotId: string) {
    const url = new URL(window.location.href)
    url.searchParams.set('snapshot', snapshotId)

    navigator.clipboard.writeText(url.href).then(() => {
      this.$sauronMessage({
        type: 'success',
        message: 'Link copied',
        duration: 1000
      })
    })
  }

  onChange(snapshotId: string) {
    storeSnapshots.loadSnapshot(snapshotId)
  }

  beforeDestroy() {
    this.resetSnapshots()
  }
}
