import Vue from 'vue'

const scrollHandler = ($el: HTMLElement | Event) => {
  const el: HTMLElement = $el instanceof Event ? ($el.target as HTMLElement) : $el
  const hasHorizontalScroll = el.offsetWidth < el.scrollWidth
  const hasVerticalScroll = el.offsetHeight < el.scrollHeight

  //Horizontal Scroll
  if (hasHorizontalScroll) {
    if (el.scrollLeft > 0) {
      el.classList.add('left-shadow', 'right-shadow')
    }

    if (el.scrollLeft === 0) {
      el.classList.add('right-shadow')
      el.classList.remove('left-shadow')
    }

    if (el.scrollLeft + el.offsetWidth === el.scrollWidth) {
      el.classList.remove('right-shadow')
    }
  } else {
    el.classList.remove('left-shadow', 'right-shadow')
  }

  //Vertical Scroll
  if (hasVerticalScroll) {
    if (el.scrollTop > 0) {
      el.classList.add('top-shadow', 'bottom-shadow')
    }

    if (el.scrollTop === 0) {
      el.classList.add('bottom-shadow')
      el.classList.remove('top-shadow')
    }

    if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
      el.classList.remove('bottom-shadow')
    }
  } else {
    el.classList.remove('top-shadow', 'bottom-shadow')
  }
}
let scrollHandlerTimeout: ReturnType<typeof setTimeout> = setTimeout(() => null)

const mouseEnter = (e: Event) => {
  const target = e.target as HTMLElement
  scrollHandler(target)
  target.removeEventListener('mouseenter', mouseEnter)
}

const observeVisibility = (el: HTMLElement) => {
  // used IntersectionObserver for checking visibility of the element.
  // used for initial shadow calculation (for example when filter is hidden)
  const options: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }

  const callback: IntersectionObserverCallback = function (entries, observer) {
    const [{ isIntersecting }] = entries
    if (isIntersecting) {
      scrollHandler(el)
      observer.unobserve(el)
    }
  }

  const observer: IntersectionObserver = new IntersectionObserver(callback, options)
  observer.observe(el)
}

Vue.directive('scrollable-shadow', {
  inserted(el, { value, arg, modifiers }) {
    if (value === false) return
    if (arg) el.classList.add(arg)
    if (modifiers.static) el.classList.add('static-shadow')
    observeVisibility(el)

    el.classList.add('scrollable-shadow')
    el.addEventListener('scroll', scrollHandler)
    el.addEventListener('mouseenter', mouseEnter)
  },
  componentUpdated(el, { value }) {
    if (value === false) return
    clearTimeout(scrollHandlerTimeout)
    scrollHandlerTimeout = setTimeout(() => {
      scrollHandler(el)
      clearTimeout(scrollHandlerTimeout)
    }, 2000)
  }
})
