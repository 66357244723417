import colorFormatter from 'color'

import { CHART_COLORS } from '@/helpers/constants'

type RGB = [number, number, number]

export function hexToRgb(hex: string): RGB | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

export function rgbToHex(rgb?: string): string {
  return colorFormatter(rgb).hex()
}

export function getMarkerStyle(predicted: boolean, color: string): string {
  const commonStyles = `display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};`
  return predicted
    ? `style="${commonStyles};background: repeating-linear-gradient(0deg, ${color} 0 2px, ${colorFormatter(color).fade(0.8).string()} 0px 4px)"`
    : `style="${commonStyles}"`
}

export function getChartColorByIndex(index: number) {
  const colorsLength = CHART_COLORS.length

  if (index < colorsLength) {
    return CHART_COLORS[index]
  }

  const colorIndex = index % colorsLength

  return CHART_COLORS[colorIndex]
}

interface IColorRGB {
  [key: string]: number
}

export function colorGradient(fadeFraction: number, rgbColor1: IColorRGB, rgbColor2: IColorRGB, rgbColor3?: IColorRGB) {
  let color1 = rgbColor1
  let color2 = rgbColor2
  let fade = fadeFraction

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1
      color1 = rgbColor2
      color2 = rgbColor3
    }
  }

  const diffRed = color2.r - color1.r
  const diffGreen = color2.g - color1.g
  const diffBlue = color2.b - color1.b

  const gradient: IColorRGB = {
    r: parseInt(String(Math.floor(color1.r + diffRed * fade)), 10),
    g: parseInt(String(Math.floor(color1.g + diffGreen * fade)), 10),
    b: parseInt(String(Math.floor(color1.b + diffBlue * fade)), 10)
  }

  return `rgb(${gradient.r}, ${gradient.g}, ${gradient.b})`
}
