
import Vue from 'vue'
import Component from 'vue-class-component'
import CustomElDropdown from '@/components/CustomElements/Dropdown.vue'
import { storeBlog } from '@/store/modules/blog'
import { storeApp } from '@/store/modules/app'

@Component({ components: { CustomElDropdown } })
export default class HelpMenu extends Vue {
  get resourceID() {
    return storeApp.resourceID
  }
  get sections() {
    return storeBlog.sections
  }
  get isBlogPage() {
    return this.$route.name === 'articlesPage' || this.$route.name === 'articlePage'
  }
}
