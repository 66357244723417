import { Message } from 'element-ui'
import { ElMessageOptions } from 'element-ui/types/message'
import { VueConstructor } from 'vue'

export type MessagePlugin = (props: MessageOptions) => void
export type MessageOptions = { closeOnClickOnLink?: boolean } & ElMessageOptions

export default {
  install(Vue: VueConstructor<Vue>) {
    Vue.prototype.$sauronMessage = ({ closeOnClickOnLink, ...messageProps }: MessageOptions) => {
      const message = Message(messageProps)

      if (closeOnClickOnLink) {
        message.$el.addEventListener('click', event => {
          // Close message after click on link
          if ((event.target as Element).matches('a')) {
            message?.close()
          }
        })
      }
    }
  }
}
