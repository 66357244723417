import Vue from 'vue'

function handleEnterKeydown(event: KeyboardEvent) {
  if (event.code === 'Enter') {
    const target = event.target as HTMLElement
    target.click()
  }
}

Vue.directive('selectable-on-enter', {
  bind(el) {
    el.addEventListener('keydown', handleEnterKeydown)
  },
  unbind(el) {
    el.removeEventListener('keydown', handleEnterKeydown)
  }
})
