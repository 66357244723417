
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import CustomElDropdown from '@/components/CustomElements/Dropdown.vue'
import { storeBlog } from '@/store/modules/blog'
import { storeNotificationCenter } from '@/store/modules/notificationCenter'
import { getFormattedDurationTime } from '@/helpers'

@Component({ components: { CustomElDropdown } })
export default class Notifications extends Vue {
  timer: NodeJS.Timer | null = null

  get sections() {
    return storeBlog.sections
  }
  get notifications() {
    return storeNotificationCenter.notifications
  }
  get hasNotifications() {
    return this.notifications?.length > 0
  }

  @Watch('hasNotifications', { immediate: true })
  onHasNotificationsChange(hasNotifications: boolean) {
    if (hasNotifications) {
      this.initUpdatingTimer()
    } else {
      // Clear timer for empty notification component
      if (this.timer) clearInterval(this.timer)
    }
  }

  initUpdatingTimer() {
    // Clear old timer
    if (this.timer) clearInterval(this.timer)

    // Add new updating timer
    this.timer = setInterval(() => {
      // Update duration time in notifications
      this.$forceUpdate()
    }, 1000 * 60)
  }

  markAsRead(notificationID: string) {
    storeNotificationCenter.markAsRead(notificationID)
  }

  markAsReadAndCloseDropdown(notificationID: string) {
    this.markAsRead(notificationID)
    const dropdown = this.$refs.dropdown as CustomElDropdown
    dropdown.hide()
  }

  getBlogSectionName(sectionID: string) {
    const section = this.sections.find(section => section.id === sectionID)
    return `${section?.name ?? ''} section`
  }

  getFormattedDurationTime(date: string) {
    const dateWithTimezone = date + '+00:00'
    return getFormattedDurationTime(dateWithTimezone, true)
  }
}
