
import Vue, { ComponentOptions } from 'vue'
import { isEmpty } from 'lodash'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Emitter from 'element-ui/src/mixins/emitter'
import ElButton from 'element-ui/packages/button'
import Migrating from 'element-ui/src/mixins/migrating'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import ElButtonGroup from 'element-ui/packages/button-group'
import { generateId } from 'element-ui/src/utils/util'
import { ElDropdownMenu } from 'element-ui/types/dropdown-menu'

@Component({
  name: 'ElDropdown',
  componentName: 'ElDropdown',
  mixins: [Emitter, Migrating],
  directives: { Clickoutside },
  components: { ElButton, ElButtonGroup },
  provide: function () {
    return {
      dropdown: this
    }
  }
} as ComponentOptions<Vue> & { componentName: string })
export default class ElDropdown extends Vue {
  timeout: NodeJS.Timeout | null = null
  menuItems: NodeListOf<HTMLElement> | null = null
  triggerElm: HTMLLinkElement | null = null
  dropdownElm: HTMLElement | null = null
  menuItemsArray: HTMLElement[] | null = null
  listId = `dropdown-menu-${generateId()}`
  visible = false
  focusing = false

  $ELEMENT!: HTMLElement & { size: number }
  broadcast!: Function
  popperElm!: HTMLElement

  componentName = 'ElDropdown'

  @Prop(Boolean) splitButton!: Boolean
  @Prop(String) type!: String
  @Prop({
    type: String,
    default: 'hover'
  })
  trigger!: 'click' | 'hover'

  @Prop({
    type: String,
    default: ''
  })
  size!: string

  @Prop({
    type: Boolean,
    default: true
  })
  hideOnClick!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  hideOnEnter!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  triggerElFocusOnEnter!: boolean

  @Prop({
    type: String,
    default: 'bottom-end'
  })
  placement!: string

  @Prop({
    default: true
  })
  visibleArrow!: boolean

  @Prop({
    type: Number,
    default: 250
  })
  showTimeout!: number

  @Prop({
    type: Number,
    default: 150
  })
  hideTimeout!: number

  @Prop({
    type: Number,
    default: 0
  })
  tabindex!: number

  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  get dropdownSize() {
    return this.size || (this.$ELEMENT || {}).size
  }

  get dropdownMenu() {
    return this.$children.find(child => child.$el?.matches('.el-dropdown-menu')) as ElDropdownMenu | null
  }

  @Watch('visible')
  private onVisibleChange(val: boolean) {
    this.broadcast('ElDropdownMenu', 'visible', val)
    this.$emit('visible-change', val)
  }

  @Watch('focusing')
  private onFocusingChange(val: boolean) {
    const selfDefine = this.$el.querySelector('.el-dropdown-selfdefine')
    if (selfDefine) {
      if (val) {
        selfDefine.className += ' focusing'
      } else {
        selfDefine.className = selfDefine.className.replace('focusing', '')
      }
    }
  }

  mounted() {
    this.$on('menu-item-click', this.handleMenuItemClick)
  }

  updated() {
    const menuItems = this.dropdownElm?.querySelectorAll("[tabindex='-1']:not(a):not(.disabled)") as NodeListOf<HTMLElement> | undefined
    this.menuItems = isEmpty(menuItems) ? (this.dropdownElm!.querySelectorAll('.el-form-item') as NodeListOf<HTMLElement>) : menuItems!
    this.menuItemsArray = [].slice.call(this.menuItems)
  }

  getMigratingConfig() {
    return {
      props: {
        'menu-align': 'menu-align is renamed to placement.'
      }
    }
  }

  show() {
    if (this.triggerElm?.disabled || this.disabled) return
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(
      () => {
        this.visible = true
      },
      this.trigger === 'click' ? 0 : this.showTimeout
    )
  }

  hide() {
    if (this.triggerElm?.disabled || this.disabled) return
    this.removeTabindex()
    if (this.tabindex >= 0) {
      this.resetTabindex(this.triggerElm!)
    }
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(
      () => {
        this.visible = false
      },
      this.trigger === 'click' ? 0 : this.hideTimeout
    )
  }

  close() {
    this.hide()
    this.triggerElmFocus()
  }

  handleClick() {
    if (this.triggerElm?.disabled) return
    if (this.visible) {
      this.hide()
    } else {
      this.show()
    }
  }

  handleTriggerKeyDown(ev: KeyboardEvent) {
    const keyCode = ev.keyCode
    const target = ev.target as HTMLElement

    if ([38, 40].includes(keyCode)) {
      this.focusMenuItem(0, ev)
    } else if (keyCode === 13) {
      this.handleClick()
    } else if (keyCode === 27) {
      this.hide()
    } else if (keyCode === 9 && this.menuItemsArray && this.visible && !target.classList.contains('menu-button')) {
      this.focusMenuItem(0, ev)
    }
  }

  handleItemKeyDown(ev: KeyboardEvent) {
    const keyCode = ev.keyCode
    const target = ev.target as HTMLElement
    const currentIndex = this.menuItemsArray!.findIndex(menuItem => menuItem.contains(target as Node))
    const max = this.menuItemsArray!.length - 1
    let nextIndex
    if ([38, 40].includes(keyCode)) {
      // up/down
      if (keyCode === 38) {
        // up
        nextIndex = currentIndex !== 0 ? currentIndex - 1 : 0
      } else {
        // down
        nextIndex = currentIndex < max ? currentIndex + 1 : max
      }
      this.focusMenuItem(nextIndex, ev)
    } else if (keyCode === 13 && !target.classList.contains('el-input__inner')) {
      target.click()
      if (this.triggerElFocusOnEnter) {
        this.triggerElmFocus()
      }
      if (this.hideOnClick || this.hideOnEnter) {
        this.close()
      }
    } else if (keyCode === 27) {
      this.close()
    } else if (keyCode === 9) {
      const isLast = this.menuItemsArray![max].contains(target)

      if (isLast) {
        ev.preventDefault()
        this.hide()
        this.triggerElmFocus()
      } else {
        setTimeout(() => {
          if (!this.dropdownElm?.contains(document.activeElement)) {
            this.hide()
          }
        }, 0)
      }
    }
  }

  focusMenuItem(index: number, event: Event) {
    const menuItem = this.menuItems![index]
    const focusableItemSelector = 'a, input:not([disabled=disabled])'
    const focusableItemElement = menuItem?.querySelector(focusableItemSelector) as HTMLElement

    if (focusableItemElement) {
      focusableItemElement.focus()

      if (focusableItemElement.matches('[type=radio]')) {
        focusableItemElement.click()
      }
    } else {
      menuItem?.focus()
      this.removeTabindex()
      this.resetTabindex(menuItem)
    }

    event.preventDefault()
    event.stopPropagation()
  }

  resetTabindex(ele: HTMLElement | HTMLLinkElement) {
    this.removeTabindex()
    ele?.setAttribute('tabindex', String(this.tabindex))
  }

  removeTabindex() {
    this.triggerElm?.setAttribute('tabindex', '-1')
    this.menuItemsArray?.forEach(item => {
      item.setAttribute('tabindex', '-1')
    })
  }

  initAria() {
    this.dropdownElm?.setAttribute('id', this.listId)
    this.triggerElm?.setAttribute('aria-haspopup', 'list')
    this.triggerElm?.setAttribute('aria-controls', this.listId)

    if (!this.splitButton) {
      this.triggerElm?.setAttribute('role', 'button')
      this.triggerElm?.setAttribute('tabindex', String(this.tabindex))
      this.triggerElm?.setAttribute('class', (this.triggerElm.getAttribute('class') || '') + ' el-dropdown-selfdefine')
    }
  }

  initEvent() {
    let { trigger, show, hide, handleClick, splitButton, handleTriggerKeyDown, handleItemKeyDown } = this
    this.triggerElm = splitButton ? ((this.$refs.trigger as Vue).$el as HTMLLinkElement) : (this.$slots.default![0].elm! as HTMLLinkElement)

    let dropdownElm = this.dropdownElm

    this.triggerElm?.addEventListener('keydown', handleTriggerKeyDown) // triggerElm keydown
    dropdownElm?.addEventListener('keydown', handleItemKeyDown) // item keydown

    if (!splitButton) {
      this.triggerElm?.addEventListener('focus', () => {
        this.focusing = true
      })
      this.triggerElm?.addEventListener('blur', () => {
        this.focusing = false
      })
      this.triggerElm?.addEventListener('click', () => {
        this.focusing = false
      })
    }
    if (trigger === 'hover') {
      this.triggerElm?.addEventListener('mouseenter', show)
      this.triggerElm?.addEventListener('mouseleave', hide)
      dropdownElm?.addEventListener('mouseenter', show)
      dropdownElm?.addEventListener('mouseleave', hide)
    } else if (trigger === 'click') {
      this.triggerElm?.addEventListener('click', handleClick)
    }
  }

  handleMenuItemClick(command: string, instance: string) {
    if (this.hideOnClick) {
      this.visible = false
    }
    this.$emit('command', command, instance)
  }

  triggerElmFocus() {
    this.triggerElm?.focus()
  }

  initDomOperation() {
    this.dropdownElm = this.popperElm!
    const menuItems = this.dropdownElm?.querySelectorAll("[tabindex='-1']:not(a):not(.disabled)") as NodeListOf<HTMLElement>
    this.menuItems = isEmpty(menuItems) ? this.dropdownElm.querySelectorAll('.el-form-item') : menuItems
    this.menuItemsArray = [].slice.call(this.menuItems)

    this.initEvent()
    this.initAria()
  }

  handleMainButtonClick(event: MouseEvent) {
    this.$emit('click', event)
    this.hide()
  }
}
