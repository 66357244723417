import { get as _get } from 'lodash'

export const STORAGE_KEY = 'sauron-app'
export const ANALYTIC_STORAGE_KEY = 'analytic-storage'

export function getStorageByPath<T>(path: string, defaultValue: T): T {
  const storage = localStorage.getItem(STORAGE_KEY)
  if (!storage) return defaultValue
  const parsedValue: Object = JSON.parse(storage)
  return _get(parsedValue, path, defaultValue)
}

export function setSessionStorage<T>(path: string, value: T) {
  const newValue = { [path]: value }
  sessionStorage.setItem(ANALYTIC_STORAGE_KEY, JSON.stringify(newValue))
}

export function getSessionStorageByPath<T>(path: string, defaultValue?: T): T | undefined {
  const storage = sessionStorage.getItem(ANALYTIC_STORAGE_KEY)
  if (!storage) return defaultValue
  const parsedValue: Object = JSON.parse(storage)
  return _get(parsedValue, path, defaultValue)
}
