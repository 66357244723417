
import Vue from 'vue'
import Component from 'vue-class-component'

import { storeReport } from '@/store/modules/report'
import { storeInventory } from '@/store/modules/inventory'
import { storeDashboard } from '@/store/modules/dashboard'
import { storeMagicLinks } from '@/store/modules/magicLinks'
import { removeReactivity } from '@/helpers'
import { IRealtimeDashboardSettings } from '@/typings/components/dashboard'

@Component
export default class MagicLinks extends Vue {
  segmentationName: string = ''
  apiMethodsList: string | null = ''
  apiUnauthMethodsList: string | null = ''
  wsAuthMethodsList: string | null = ''
  mapSettings: IRealtimeDashboardSettings = {} // Realtime dashboard settings
  configAccess: string[] = []

  mounted() {
    window.addEventListener('keyup', this.magicLinkListener)
    this.segmentationName = localStorage.getItem('__segmentationName') || ''
  }

  beforeDestroy() {
    window.removeEventListener('keyup', this.magicLinkListener)
  }

  get environment(): string {
    //@ts-ignore
    return config.ENVIRONMENT || process.env.NODE_ENV
  }

  get isVisibleMagicLinks() {
    return storeMagicLinks.isVisible
  }

  get isEnabledConfigAccess() {
    return storeMagicLinks.configAccessEnabled
  }

  get isRealtimeMap() {
    return storeDashboard.isRealtimeMap
  }
  get isReportPage() {
    return this.$route?.name === 'reportsPage'
  }
  get isDashboardPage() {
    return this.$route?.name === 'dashboardPage'
  }
  get isInventoryPage() {
    return this.$route?.name === 'inventoryPage'
  }

  closeMagicLinks() {
    storeMagicLinks.triggerMagicLinks(false)
  }

  magicLinkListener(e: KeyboardEvent) {
    window.magicLink += e.key
    clearTimeout(window.magicTimeout)
    window.magicTimeout = setTimeout(() => {
      window.magicLink = ''
    }, 5000)
    if (window.magicLink.includes('magiclinks') || window.magicLink.includes('ьфпшсдштлы')) {
      storeMagicLinks.triggerMagicLinks(true)
    }
  }

  async resetFilterAnalytics() {
    await this.$api('dropAnalyticStorageData', { key: this.$route.params.id })
    sessionStorage.clear()
    window.location.reload()
  }

  applySegmentationSchema() {
    localStorage.setItem('__segmentationName', this.segmentationName)
  }

  applyConfigAccess() {
    const preparedConfigAccess = this.configAccess.reduce((acc, item) => {
      //@ts-ignore
      acc[item] = true
      return acc
    }, {})

    storeMagicLinks.setConfigAccess(preparedConfigAccess)
  }

  onEnableConfigAccess(value: boolean) {
    storeMagicLinks.setConfigAccessEnabled(value)
  }

  updateReport() {
    storeReport.updateReport(this.$route.params.id)
  }

  updateDashboard() {
    storeDashboard.updateDashboardCharts(this.$route.params.id)
  }

  updateInventory() {
    storeInventory.updateCards(this.$route.params.id)
  }

  // Save realtime dashboard settings
  applyRealtimeDashboardSettings() {
    storeMagicLinks.saveRealtimeDashboardSettings(removeReactivity(this.mapSettings))
  }

  async load_auth_api_method_list() {
    const res = await this.$api('getAuthorizedApiMethods')
    this.apiMethodsList = res.join('\n')
  }

  async load_unauth_api_method_list() {
    const res = await this.$api('getUnauthorizedMethods')
    this.apiUnauthMethodsList = res.join('\n')
  }

  async load_auth_ws_method_list() {
    const res = await this.$api('getAuthorizedWsMethods')
    this.wsAuthMethodsList = res.join('\n')
  }
}
