
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { storeAuth } from '@/store/modules/auth'
import FeaturesContent from '@/components/Home/FeaturesContent.vue'

@Component({
  components: { FeaturesContent }
})
export default class Home extends Vue {
  get isAuthenticated() {
    return storeAuth.isAuthenticated
  }
}
