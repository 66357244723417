import validator from 'validator'
import { isEqual } from 'lodash'
import { IFilterLookup, IFilterRequest, ISelectedChoiceFilter, ISelectedFilter } from '@/store/typings/filter'
import { dateFormat } from '@/helpers/date'
import { IRandomDefaultConfig } from '@/store/typings/jsonSchemas'

export * from '@/helpers/resourceChanges'
export * from '@/helpers/storage'
export * from '@/helpers/string'
export * from '@/helpers/number'
export * from '@/helpers/color'
export * from '@/helpers/date'

export function wait(ms: number): Promise<unknown> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function isUUID(str: string): boolean {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(str)
}

export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function generateRandomString({
  length = 32,
  numbers = false,
  smallLetters = true,
  capitalLetters = false,
  additionalCharacters = '',
  prefix = '',
  postfix = ''
}: IRandomDefaultConfig): string {
  let result = ''
  let allowedSymbols = ''
  if (numbers) allowedSymbols += '0123456789'
  if (smallLetters) allowedSymbols += 'abcdefghijklmnopqrstuvwxyz'
  if (capitalLetters) allowedSymbols += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (additionalCharacters) allowedSymbols += additionalCharacters
  if (prefix) result = prefix + result
  if (postfix) result += postfix

  const acceptedLength = length - prefix.length - postfix.length

  for (let i = 0; i < acceptedLength; i++) {
    result += allowedSymbols.charAt(Math.floor(Math.random() * allowedSymbols.length))
  }
  return result
}

export function isEqualMapsSets(obj1: Map<any, any>, obj2: Map<any, any>): boolean {
  if (obj1.size !== obj2.size) return false
  return JSON.stringify(Array.from(obj1)) === JSON.stringify(Array.from(obj2))
}

export function removeReactivity<Type = object>(item: Type): Type {
  return JSON.parse(JSON.stringify(item))
}

export function isMobileDevice() {
  return window.innerWidth <= 825
}

export function isEqualFilters(filters: ISelectedFilter[], updatedFilters: ISelectedFilter[]) {
  const dateFilters = ['date_related', 'week_related', 'month_related']
  return (
    filters?.length === updatedFilters?.length &&
    filters.every(filter => {
      const updatedFilter = updatedFilters.find(updatedFilter => updatedFilter.uuid === filter.uuid)

      if (!updatedFilter) return false

      if (dateFilters.includes(filter.filter_type)) {
        // Compare date filters by label
        return filter.uuid === '_defaultDateFilter' || filter.label === updatedFilter.label
      }

      return isEqual(filter, updatedFilter)
    })
  )
}

export function getApplicationFilterOptionsID(applicationFilter?: ISelectedChoiceFilter): string {
  if (!applicationFilter) return 'default'
  if (applicationFilter.checkAll && applicationFilter.exclude) return `exclude:${applicationFilter.exclude.join(',')}`
  return applicationFilter.options.length === 1 ? applicationFilter.options[0] : `include:${applicationFilter.options.join(',')}`
}

export async function downloadFileByURL(src: string, fileName?: string) {
  await fetch(src)
    .then(response => response.blob())
    .then(blob => {
      const fileNameToSave = fileName ?? src.split('/').pop()!
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      document.body.appendChild(link)
      link.download = fileNameToSave
      link.click()
      link.remove()
    })
}

export function isURL(url: string) {
  return !url.startsWith('://') && validator.isURL(url, { require_protocol: true, require_valid_protocol: false, require_tld: false })
}

export function filtersFormatterForRequest(selectedFilters: ISelectedFilter[], checkAllValue: string | [] = '--all--'): IFilterRequest[] {
  return selectedFilters
    .filter(filter => filter.options.length)
    .map(filter => {
      const filterName = filter.choice_type_pk || filter.choice_type_name
      if (filter.filter_type === 'text' || (filter.filter_type === 'numeric' && ['from', 'to', 'exact'].includes(filter.choice_type))) {
        return { [filterName]: String(filter.options[0]) }
      } else if (filter.filter_type === 'numeric') {
        return { [filterName]: [String(filter.options[0]), String(filter.options[1])] }
      } else if (['from', 'to', 'exact'].includes(filter.choice_type)) {
        return { [filterName]: dateFormat(filter.options[0], true) }
      } else if (['range'].includes(filter.choice_type)) {
        return { [filterName]: [dateFormat(filter.options[0], true), dateFormat(filter.options[1], true)] }
      } else if (filter.filter_type === 'extra_choice') {
        const options = [{ [filter.choice_type_presentation_name]: filter.checkAll ? checkAllValue : filter.options }]
        return { [filterName]: options }
      } else {
        return { [filterName]: filter.checkAll ? checkAllValue : filter.options }
      }
    })
    .reduce((result: IFilterRequest[], item: IFilterRequest) => {
      // function to check if same filter already exists and merge it into one. Done for extra_choice filter
      const key = Object.keys(item)[0]
      const existingItem = result.find(obj => Object.keys(obj)[0] === key)

      if (existingItem) {
        const existingKey = Object.keys(existingItem)[0]
        ;(existingItem[existingKey] as IFilterLookup[]).push(...(item[key] as IFilterLookup[]))
      } else {
        result.push({ [key]: item[key] })
      }

      return result
    }, [])
}

export function filtersExcludeFormatterForRequest(selectedFilters: ISelectedFilter[]): IFilterRequest[] {
  return selectedFilters
    .filter(filter => (filter.checkAll && filter.exclude?.length) || filter.excludeFilterType)
    .map(filter => {
      const filterName = filter.choice_type_pk || filter.choice_type_name

      if (filter.excludeFilterType) {
        if (filter.filter_type === 'text' || (filter.filter_type === 'numeric' && ['from', 'to', 'exact'].includes(filter.choice_type))) {
          return { [filterName]: String(filter.options[0]) }
        } else if (filter.filter_type === 'numeric') {
          return { [filterName]: [String(filter.options[0]), String(filter.options[1])] }
        }
      } else if (filter.filter_type === 'extra_choice') {
        return { [filter.choice_type_pk]: filter.exclude ? [{ [filter.choice_type_presentation_name]: filter.exclude }] : [] }
      }

      return { [filter.choice_type_pk]: filter.exclude || [] }
    })
}
