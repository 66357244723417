import { has as _has } from 'lodash'

import { removeReactivity } from '@/helpers'
import { IDefinedFieldCard, IFullFieldInfo, IDefinedFields } from '@/store/typings/jsonSchemas'

export function getFieldsData(fields: IDefinedFields, fieldInfo: IFullFieldInfo) {
  const isNestedFormField = fieldInfo.cardName && fieldInfo.cardID
  if (isNestedFormField) {
    const cardsData =
      _has(fields, fieldInfo.cardName!) && Array.isArray(fields[fieldInfo.cardName!])
        ? (removeReactivity(fields[fieldInfo.cardName!]) as IDefinedFieldCard[])
        : []

    // Find card index with filled fields
    const cardIndex = cardsData.findIndex((cardInfo: IDefinedFieldCard) => cardInfo.cardID === fieldInfo.cardID)

    // Formset card data with filled fields
    const cardInfo =
      cardIndex !== -1 && cardsData[cardIndex]
        ? cardsData[cardIndex]
        : ({ cardID: fieldInfo.cardID, cardName: fieldInfo.cardName, cardLabel: fieldInfo.cardLabel, fields: {} } as IDefinedFieldCard)

    if (cardIndex !== -1) {
      // Add field data to card
      cardsData[cardIndex] = { ...cardInfo, fields: { ...cardInfo.fields, [fieldInfo.fieldName]: fieldInfo.fieldData } }
    } else {
      // Set first field data to card
      cardsData.push({
        ...cardInfo,
        fields: { [fieldInfo.fieldName]: fieldInfo.fieldData }
      })
    }

    return removeReactivity({ ...fields, [fieldInfo.cardName!]: cardsData })
  }
  return removeReactivity({ ...fields, [fieldInfo.fieldName]: fieldInfo.fieldData })
}
