import Vue from 'vue'

const ICON_CLASS_NAME = 'el-icon-circle-close'

type ILoadingElement = HTMLElement & { iconElement?: HTMLElement }

function createIconElement(parent: ILoadingElement, closeCallback: Function) {
  let iconElement: HTMLElement | null = parent.iconElement ?? null

  if (iconElement && document.body.contains(iconElement)) {
    iconElement.classList.add('show')
    return
  }

  const parentClientRect = parent.getBoundingClientRect()

  iconElement = document.createElement('i')
  iconElement.style.top = `${parentClientRect.top + window.scrollY + Math.floor(parentClientRect.height / 2)}px`
  iconElement.style.visibility = 'hidden'
  iconElement.classList.add(ICON_CLASS_NAME, 'show', 'close-loading')

  parent.iconElement = iconElement

  if (closeCallback) {
    iconElement.addEventListener(
      'click',
      () => {
        closeCallback()
        iconElement?.remove()
        parent.remove()
      },
      { once: true }
    )
  }

  createHint(iconElement)

  document.body.appendChild(iconElement)
}

function createHint(parentElement: HTMLElement, text: string = 'Stop loading') {
  const hintElement = document.createElement('div')
  hintElement.classList.add('el-tooltip__popper', 'is-light', 'custom')

  hintElement.innerHTML = text

  parentElement.append(hintElement)
}

function removeIconElement(parent?: ILoadingElement) {
  parent?.iconElement?.remove()
}

Vue.directive('closable-loading', {
  update(el: any, binding) {
    const { loading, closeCallback } = binding.value as { loading: string; closeCallback: Function }

    function handleMouseenter(this: ILoadingElement, event: MouseEvent) {
      if (event?.relatedTarget === this.iconElement) return
      createIconElement(this, closeCallback)
    }

    function handleMouseleave(this: ILoadingElement, event: MouseEvent) {
      if (event?.relatedTarget === this.iconElement) return
      removeIconElement(this)
    }

    if (loading && !el.loadingService) {
      const loadingService = Vue.prototype.$loading({ target: el, fullscreen: false })
      el.loadingService = loadingService

      el.loadingService.$el.addEventListener('mouseenter', handleMouseenter)
      el.loadingService.$el.addEventListener('mouseleave', handleMouseleave)
    } else if (!loading && el.loadingService) {
      el.loadingService.$el.removeEventListener('mouseenter', handleMouseenter)
      el.loadingService.$el.removeEventListener('mouseleave', handleMouseleave)

      removeIconElement(el.loadingService.$el)

      el.loadingService.close()
      el.loadingService.$el.remove()
      delete el.loadingService
    }
  },
  unbind(el: any) {
    if (el?.loadingService) {
      removeIconElement(el.loadingService.$el)
    }
  }
})
