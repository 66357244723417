
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class SvgIcon extends Vue {
  @Prop({
    type: String,
    required: true
  })
  name!: string

  @Prop({
    type: String,
    default: null
  })
  title!: string | null

  get iconPath() {
    let icon = require(`@/assets/icons/${this.name}.svg`)
    if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
      icon = icon.default
    }

    return icon.url
  }

  get className() {
    return 'svg-icon svg-icon--' + this.name
  }
}
