import Vue from 'vue'
import { ElNotificationOptions } from 'element-ui/types/notification'
import { isEmpty, isEqual, orderBy, uniqBy } from 'lodash'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { storeApp } from '@/store/modules/app'
import { storeSnapshots } from '@/store/modules/snapshots'
import { storeAnalyticsStorageData } from '@/store/modules/analyticsStorageData'
import { RESOURCE_TYPES, SERVER_DATE_FORMAT } from '@/helpers/constants'
import { getUpdatedAnalyticsStorageByResourceChanges } from '@/helpers'
import { IReportTableChanges, IResourceChange, IResourceChanges } from '@/store/typings/resourceChanges'
import { IExtraDimension, IFilter, IFilterChoiceRequest, IFilterRequest, ISelectedFilter } from '@/store/typings/filter'
import {
  IChartDataResponse,
  IChartsData,
  IChartSpot,
  IChartSpotData,
  ICommonChartDataResponse,
  ICommonChartsData,
  ICommonTableData,
  ICommonTableDataResponse,
  IFormattedExtraDimension,
  IGetReportGroupsResponse,
  IReport,
  IReportGroup,
  IReportRequestParams,
  IReportsList,
  IReportState,
  IRetentionChartItemDataResponse,
  IRetentionChartsData,
  IRetentionTable,
  IRetentionTableData,
  IRetentionTableDataResponse,
  IRetentionTableRowData,
  ITable,
  ITableData,
  ITableDataResponse,
  ITableDimension,
  ITableField,
  ITableRowData
} from '@/store/typings/report'
import { defaultCurrentReportTable, formatReportData, formatRetentionChartData, getAllReportsFromReportGroups } from '@/store//utils/report'
import dayjs from 'dayjs'
import { IAppRelease, IGetAppReleasesResponse } from '@/store/typings/dashboard'

@Module({ dynamic: true, store, namespaced: true, name: 'report' })
class ReportModule extends VuexModule implements IReportState {
  _reportGroups: IReportGroup[] = []
  _filterForReportRequest: IFilterRequest[] = []
  _excludeForReportRequest: IFilterRequest[] = []
  _selectedDateFilter: ISelectedFilter | null = null
  _appliedReportParams: IReportRequestParams = {}
  _tableData: ITableData | null = null
  _chartsData: IChartsData | null = null
  _appReleases: IAppRelease[] | null = null
  _currentReportTableID: string | null = null
  _downloadPermission = false
  _autoReloadReport = false
  _chartIsLoading = false
  _tableIsLoading = false
  _chartWithErrorLoading = false
  _extraDimensions: IFormattedExtraDimension[] = []

  get reportGroups(): IReportGroup[] {
    return this._reportGroups
  }

  get filterForReportRequest(): IFilterRequest[] {
    return this._filterForReportRequest
  }

  //exclude is_chart_metrics for charts only
  get filterForChartRequest(): IFilterRequest[] {
    const allReportMetricFiltersChoiceTypePKs = storeReport.allReportMetricFilters.map(filter => filter.info.choice_type_pk)
    return this.filterForReportRequest.filter(filter => !allReportMetricFiltersChoiceTypePKs.includes(Object.keys(filter)[0]))
  }

  get excludeForReportRequest(): IFilterRequest[] {
    return this._excludeForReportRequest
  }

  get extraDimensions(): IFormattedExtraDimension[] {
    return this._extraDimensions
  }

  get extraDimensionsNames(): string[] {
    return this.extraDimensions.map(extraDimension => extraDimension.name)
  }

  get extraChartGroups(): IChartSpotData[] {
    return this.extraDimensions.map(extraDimension => ({
      name: extraDimension.name,
      presentation_name: extraDimension.presentation_name,
      order: 100,
      isExtra: true
    }))
  }

  get selectedExtraDimensionsNames(): string[] {
    return this.extraDimensions
      .filter(extraDimension => !storeAnalyticsStorageData.analyticsAllData.inactiveExtraDimensions?.includes(extraDimension.name))
      .map(extraDimension => extraDimension.name)
  }

  get selectedExtraGroupByNames(): string[] {
    return this.extraDimensions
      .filter(extraDimension => storeAnalyticsStorageData.analyticsAllData.extraGroupBy?.includes(extraDimension.name))
      .map(extraDimension => extraDimension.name)
  }

  get autoReloadReport(): boolean {
    return this._autoReloadReport
  }

  get chartIsLoading(): boolean {
    return this._chartIsLoading
  }

  get tableIsLoading(): boolean {
    return this._tableIsLoading
  }

  get formattedTableData(): ITableData | null {
    return this._tableData
  }

  get formattedChartsData(): IChartsData | null {
    return this._chartsData
  }

  get downloadPermission(): boolean {
    return this._downloadPermission
  }

  get selectedDateFilter(): ISelectedFilter | null {
    return this._selectedDateFilter
  }

  get chartWithErrorLoading(): boolean {
    return this._chartWithErrorLoading
  }

  get selectedApplications(): IFilterChoiceRequest | null {
    const selectedApplicationFilter = this.filterForChartRequest?.find(filter => filter.application || filter.appName || filter.app_name)
    const selectedApplicationValue = selectedApplicationFilter
      ? selectedApplicationFilter.application || selectedApplicationFilter.appName || selectedApplicationFilter.app_name
      : null

    return selectedApplicationValue || null
  }

  get appliedReportParams() {
    return this._appliedReportParams
  }

  get appliedDimensions(): string[] {
    return this._appliedReportParams.dimensions ?? []
  }

  get predefinedDeselectedLegends() {
    return storeApp.predefinedConfigurationsData?.deselectedLegends
  }

  get predefinedSelectedGroupIdx() {
    return storeApp.predefinedConfigurationsData?.selectedGroupIdx
  }

  get reportsList(): IReportsList {
    const reportList = this.reportGroups.reduce((reportList: IReportsList, reportGroup: IReportGroup) => {
      reportGroup.reports.forEach((report: IReport) => {
        report.groupId = reportGroup.id
        reportList.push(report)
      })

      return reportList
    }, [])

    return orderBy(reportList, reportListItem => reportListItem.name.toLowerCase())
  }

  get defaultChartGroupBy(): string[] {
    return [this.currentReportChartSpots?.group_by.map(groupByItem => groupByItem.name)[0]!]
  }

  get defaultChartOrderBy() {
    return this.currentReportChartSpots?.order_by.map(orderByItem => orderByItem.name)[0]
  }

  get currentReportGroup(): IReportGroup | null {
    return this.reportGroups.find(reportGroup => reportGroup.id === storeApp.currentRouter.params.groupId) ?? null
  }

  get currentReport(): IReport | null {
    return (this.currentReportGroup && this.currentReportGroup.reports.find(item => item.id === storeApp.resourceID)) || null
  }

  get currentReportTable(): ITable | IRetentionTable | null {
    return (
      (this.isRetention
        ? this.currentReport?.retention_tables?.[0]
        : this.currentReportTableID && this.currentReport?.tables.find(table => table.id === this.currentReportTableID)) || null
    )
  }

  get currentReportChartSpots(): IChartSpot | undefined {
    return this.isRetention
      ? this.currentReport?.retention_chart_spots?.[0]
      : this.currentReport?.chart_spots.find(chartSpot => chartSpot.table_id === this.currentReportTableID)
  }

  get currentReportTableID(): string {
    return (
      storeApp.predefinedConfigurationsData?.reportTableID ||
      this._currentReportTableID ||
      (defaultCurrentReportTable(this.currentReport?.tables) as ITable).id
    )
  }

  get isRetention(): boolean {
    return this.currentReport?.retention_tables?.length! > 0 ?? false
  }

  get hasCurrentChart(): boolean {
    return !!this.currentReportChartSpots && this.currentReportChartSpots?.group_by.length > 0 && this.currentReportChartSpots?.order_by.length > 0
  }

  get preparedTableOrdersMap() {
    if (storeApp.predefinedConfigurationsData?.tableOrdersMap?.length) {
      return storeApp.predefinedConfigurationsData.tableOrdersMap.filter(
        orderMapItem =>
          this.selectedExtraDimensionsNames.includes(orderMapItem[0]) ||
          this.allReportDimensions.some(dimension => dimension.name === orderMapItem[0]) ||
          this.allReportMetrics.some(metric => metric.name === orderMapItem[0])
      )
    }

    return []
  }

  get allReportDimensions(): ITableDimension[] {
    if (!this.currentReport) return []
    return uniqBy(
      this.currentReport.tables.reduce((allReportDimensions: ITableDimension[], table: ITable) => [...allReportDimensions, ...table.dimensions], []),
      'name'
    )
  }

  get allReportMetrics(): ITableField[] {
    return uniqBy(
      this.currentReport?.tables.reduce((allReportMetrics: ITableField[], table: ITable) => [...allReportMetrics, ...table.fields], []),
      'name'
    )
  }

  get allReportMetricFilters(): IFilter[] {
    return this.currentReport?.filters.filter(filter => filter.is_metric_filter) ?? []
  }

  get allReportChartGroups(): IChartSpotData[] {
    return uniqBy(
      this.currentReport?.[this.isRetention ? 'retention_chart_spots' : 'chart_spots'].reduce(
        (allReportChartGroups: IChartSpotData[], chartSpot: IChartSpot) => [...allReportChartGroups, ...chartSpot.group_by],
        []
      ),
      'name'
    )
  }

  get orderedAllReportChartGroups(): IChartSpotData[] {
    return orderBy([...this.allReportChartGroups, ...this.extraChartGroups], 'order')
  }

  get allReportChartOrders(): IChartSpotData[] {
    return uniqBy(
      this.currentReport?.[this.isRetention ? 'retention_chart_spots' : 'chart_spots'].reduce(
        (allReportChartOrders: IChartSpotData[], chartSpot: IChartSpot) => [...allReportChartOrders, ...chartSpot.order_by],
        []
      ),
      'name'
    )
  }

  @Mutation
  setChartLoading(isLoading: boolean) {
    this._chartIsLoading = isLoading
  }

  @Mutation
  setTableLoading(isLoading: boolean) {
    this._tableIsLoading = isLoading
  }

  @Mutation
  setReportGroups(reportGroups: IReportGroup[]) {
    this._reportGroups = reportGroups
  }

  @Mutation
  setTableData(tableData: ITableData | null) {
    this._tableData = tableData
  }

  @Mutation
  setChartsData(chartsData: IChartsData | null) {
    this._chartsData = chartsData
  }

  @Mutation
  setDownloadPermission(downloadPermission: boolean) {
    this._downloadPermission = downloadPermission
  }

  @Mutation
  setAppReleases(appReleases: IAppRelease[] | null) {
    this._appReleases = appReleases
  }

  @Mutation
  setChartWithErrorLoading(chartWithErrorLoading: boolean) {
    this._chartWithErrorLoading = chartWithErrorLoading
  }

  @Mutation
  clearState() {
    this._appliedReportParams = {}
    this._filterForReportRequest = []
    this._excludeForReportRequest = []
    this._extraDimensions = []
    this._tableData = null
    this._chartsData = null
    this._currentReportTableID = null
    this._downloadPermission = false
    this._tableIsLoading = false
    this._chartIsLoading = false
    this._selectedDateFilter = null
  }

  @Mutation
  setReportParams(newReportParams: IReportRequestParams) {
    this._appliedReportParams = {
      ...this._appliedReportParams,
      ...newReportParams
    }
  }

  @Mutation
  setCurrentReportTableID(currentReportTableID: string) {
    this._currentReportTableID = currentReportTableID
  }

  @Mutation
  setFilterForReportRequest(filterForReportRequest: IFilterRequest[]) {
    this._filterForReportRequest = filterForReportRequest
  }

  @Mutation
  setExcludeForReportRequest(excludeForReportRequest: IFilterRequest[]) {
    this._excludeForReportRequest = excludeForReportRequest
  }

  @Mutation
  setExtraDimensions(extraDimensions: IExtraDimension[]) {
    this._extraDimensions = extraDimensions.map(extraDimension => ({
      ...extraDimension,
      presentation_name: extraDimension.name.split(/(?=[A-Z])/).join(' '),
      value_type: 'String'
    }))
  }

  @Mutation
  setAutoReloadReport(autoReloadReport: boolean) {
    this._autoReloadReport = autoReloadReport
  }

  @Mutation
  setSelectedDateFilter(selectedDateFilter: ISelectedFilter | null) {
    this._selectedDateFilter = selectedDateFilter
  }

  @Action
  async loadReportGroups() {
    const { items: reportGroups }: IGetReportGroupsResponse = await Vue.prototype.$api('getReportGroups')
    this.setReportGroups(reportGroups)
  }

  @Action
  async reloadReportGroups({ updatedReportUUID, resourceChanges }: { updatedReportUUID: string; resourceChanges: IResourceChanges }) {
    if (storeApp.resourceID === updatedReportUUID) {
      const { items: reportGroups }: IGetReportGroupsResponse = await Vue.prototype.$api('getReportGroups')
      const allReports: IReport[] = getAllReportsFromReportGroups(reportGroups)
      const updatedReport = allReports.find(report => report.id === updatedReportUUID)

      const updatedTable = this.isRetention ? updatedReport?.retention_tables : updatedReport?.tables
      const oldTable = this.isRetention ? this.currentReport?.retention_tables : this.currentReport?.tables

      const filterHasBeenUpdated =
        updatedReport?.filters && this.currentReport?.filters && !isEqual(updatedReport?.filters, this.currentReport?.filters)
      const chartHasBeenUpdated = resourceChanges?.some((resourceChange: IResourceChange) =>
        (resourceChange as IReportTableChanges).changes?.some(tableChange => tableChange.field === 'chart_spot')
      )
      const tableHasBeenUpdated = updatedTable && oldTable && !isEqual(updatedTable, oldTable)

      if (isEmpty(this._appliedReportParams)) {
        this.updateReportResource({ reportGroups, resourceChanges })
      } else if (filterHasBeenUpdated) {
        let message

        if (chartHasBeenUpdated || tableHasBeenUpdated) {
          Vue.prototype.$sauronNotify.close('autoReload')
          const updatedSubjectName = tableHasBeenUpdated ? 'table' : 'chart'
          message = `Filters and ${updatedSubjectName} has been updated:`
        } else {
          message = 'Filters has been updated:'
        }

        Vue.prototype.$sauronNotify.show({
          name: 'reload',
          message,
          linkText: 'Reload',
          duration: 60000000,
          replaceDuplicate: true, // Replace duplicated messages to use updated onClick and onRemove functions
          onClick: () => {
            this.clearState()
            this.updateReportResource({ reportGroups, resourceChanges })
          },
          onRemove: () => {
            this.updateReportResource({ reportGroups, resourceChanges })
          }
        })
      } else if (chartHasBeenUpdated || tableHasBeenUpdated) {
        const updatedSubjectName = tableHasBeenUpdated ? 'Table' : 'Chart'
        Vue.prototype.$sauronNotify.show({
          name: 'autoReload',
          message: `${updatedSubjectName} has been updated:`,
          linkText: 'Reload',
          duration: 60000000,
          replaceDuplicate: true, // Replace duplicated messages to use updated onClick and onRemove functions
          onClick: () => {
            Vue.prototype.$sauronNotify.close('update')
            this.updateReportResource({ reportGroups, resourceChanges })
            this.setAutoReloadReport(true)
          },
          onRemove: () => {
            this.updateReportResource({ reportGroups, resourceChanges })
          }
        })
      } else {
        Vue.prototype.$sauronNotify.show({
          name: 'update',
          message: 'This report has been updated:',
          linkText: 'Update',
          duration: 60000000,
          replaceDuplicate: true, // Replace duplicated messages to use updated onClick and onRemove functions
          onClick: () => {
            this.updateReportResource({ reportGroups, resourceChanges })
            this.loadReport({ isUpdate: true })
          },
          onRemove: () => {
            this.updateReportResource({ reportGroups, resourceChanges })
          }
        })
      }
    } else {
      this.loadReportGroups()
    }
  }

  @Action
  async loadReport({ reportParams = this._appliedReportParams, isUpdate = false }) {
    Vue.prototype.$sauronNotify.remove('update')
    this.setChartWithErrorLoading(false)

    const promises = []
    this.setReportParams(reportParams)
    if (!isUpdate) this.setTableLoading(true)
    this.checkDownloadsPermission()

    promises.push(this.fetchTableData({ tableParams: reportParams }).finally(() => this.setTableLoading(false)))

    if (this.hasCurrentChart) {
      if (!isUpdate) this.setChartLoading(true)
      promises.push(
        Vue.prototype
          .$api(this.isRetention ? 'getRetentionTableChartData' : 'getReportChartData', {
            ...reportParams,
            dimensions: [],
            filters: this.filterForChartRequest
          })
          .then(async (chartsData: IChartDataResponse) => {
            const formattedChartsData: IChartsData = this.isRetention
              ? (formatRetentionChartData(chartsData.items as IRetentionChartItemDataResponse[], this.currentReport) as IRetentionChartsData)
              : (formatReportData(chartsData.items) as ICommonChartsData)

            if (!this.isRetention) this.setCurrentReportTableID((chartsData as ICommonChartDataResponse).table_id)

            this.setChartsData(formattedChartsData)
          })
          .catch(() => {
            if (!this.formattedChartsData) {
              // show error message only for empty charts (initial load mostly)
              this.setChartWithErrorLoading(true)
            }
          })
          .finally(() => {
            this.setChartLoading(false)
          })
      )

      if (this.selectedApplications?.length === 1) {
        promises.push(
          Vue.prototype
            .$api('getAppReleases', {
              application: this.selectedApplications[0],
              from_date: dayjs(this.selectedDateFilter?.options[0]).format(SERVER_DATE_FORMAT),
              to_date: dayjs(this.selectedDateFilter?.options[1]).format(SERVER_DATE_FORMAT)
            })
            .then((response: IGetAppReleasesResponse) => {
              const { items: appReleases } = response

              const sortedAppReleases = appReleases.sort((a, b) => (dayjs(a.release_date).isAfter(dayjs(b.release_date)) ? 1 : -1))

              this.setAppReleases(sortedAppReleases)
            })
        )
      } else {
        this.setAppReleases(null)
      }
    }

    return Promise.all(promises).then(() => {
      if (isUpdate) {
        Vue.prototype.$notify({
          type: 'info',
          message: 'Report was successfully updated'
        } as ElNotificationOptions)
      }
    })
  }

  @Action
  async loadTableData({ tableParams, lazyLoading = false }: { tableParams: IReportRequestParams; lazyLoading?: boolean }) {
    let tableData: ITableDataResponse

    try {
      this.setTableLoading(true)
      tableData = await this.fetchTableData({ tableParams, lazyLoading })
      this.setTableLoading(false)

      this.setReportParams(tableParams)

      return tableData
    } catch (error) {
      this.setTableLoading(false)
      throw error
    }
  }

  @Action
  async fetchTableData({ tableParams, lazyLoading }: { tableParams: IReportRequestParams; lazyLoading?: boolean }): Promise<ITableDataResponse> {
    return this.isRetention ? this.fetchRetentionTableData({ tableParams, lazyLoading }) : this.fetchCommonTableData({ tableParams, lazyLoading })
  }

  @Action
  async fetchCommonTableData({
    tableParams,
    lazyLoading
  }: {
    tableParams: IReportRequestParams
    lazyLoading?: boolean
  }): Promise<ICommonTableDataResponse> {
    const tableParamsPrepared = Object.freeze(tableParams)
    const reportData: ICommonTableDataResponse = await Vue.prototype.$api('getReportTableData', tableParamsPrepared)
    const formattedTableData = formatReportData(reportData.items) as ICommonTableData

    if (this.formattedTableData?.table_rows && lazyLoading) {
      formattedTableData.table_rows = [...(this.formattedTableData.table_rows as ITableRowData[]), ...formattedTableData.table_rows]
    }

    this.setCurrentReportTableID(reportData.table_id)
    this.setTableData(formattedTableData)

    return reportData
  }

  @Action
  async fetchRetentionTableData({
    tableParams,
    lazyLoading
  }: {
    tableParams: IReportRequestParams
    lazyLoading?: boolean
  }): Promise<IRetentionTableDataResponse> {
    let tableParamsPrepared = Object.freeze(tableParams)

    if (tableParamsPrepared.page_num === 1) {
      tableParamsPrepared = { ...tableParamsPrepared, wa_required: true }
    }

    const reportData: IRetentionTableDataResponse = await Vue.prototype.$api('getRetentionTableData', tableParamsPrepared)

    const formattedTableData: IRetentionTableData = {
      isRetention: true,
      table_rows: reportData.items,
      other_items: (this.formattedTableData as IRetentionTableData)?.other_items ?? [],
      weighted_average_items: (this.formattedTableData as IRetentionTableData)?.weighted_average_items ?? []
    }

    if (this.formattedTableData?.table_rows && lazyLoading) {
      formattedTableData.table_rows = [...(this.formattedTableData.table_rows as IRetentionTableRowData[]), ...formattedTableData.table_rows]
    }

    if (formattedTableData.isRetention && tableParamsPrepared.page_num === 1) {
      formattedTableData.weighted_average_items = reportData.weighted_average_items
      formattedTableData.other_items = reportData.other_items
    }

    this.setTableData(formattedTableData)

    return reportData
  }

  @Action
  async loadCharts(chartsParams: IReportRequestParams) {
    try {
      this.setChartLoading(true)
      this.setChartWithErrorLoading(false)

      const chartsData = await Vue.prototype.$api(this.isRetention ? 'getRetentionTableChartData' : 'getReportChartData', {
        ...chartsParams,
        filters: this.filterForChartRequest
      })

      const formattedChartsData: IChartsData = this.isRetention
        ? formatRetentionChartData(chartsData.items, this.currentReport)
        : (formatReportData(chartsData.items) as ICommonChartsData)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dimensions, dimension_filter, ...chartsParamsToSave } = chartsParams

      this.setCurrentReportTableID(chartsData.table_id)
      this.setReportParams(chartsParamsToSave)
      await this.setChartsData(formattedChartsData)
    } finally {
      this.setChartLoading(false)
    }
  }

  @Action
  async updateReport(updatedReportUUID: string) {
    if (storeApp.resourceID !== updatedReportUUID || isEmpty(this._appliedReportParams)) return

    Vue.prototype.$sauronNotify.show({
      name: 'update',
      message: 'This report has been updated:',
      linkText: 'Update',
      duration: 60000000,
      replaceDuplicate: true, // Replace duplicated messages to use updated onClick function
      onClick: () => {
        this.loadReport({ isUpdate: true })
      }
    })
  }

  @Action
  async downloadReport(params = {}) {
    try {
      const downloadReportResult = await Vue.prototype.$api(this.isRetention ? 'downloadRetentionReport' : 'downloadReport', {
        report_id: storeApp.resourceID,
        filters: this.filterForReportRequest,
        exclude: this.excludeForReportRequest,
        ...params
      })

      Vue.prototype.$sauronMessage({
        message: `Report was uploaded to your Google drive. You can <a href="https://drive.google.com/" target="_blank">open</a> or <a href="${downloadReportResult.download_link}" target="_blank">download</a> it.`,
        showClose: true,
        duration: 0,
        dangerouslyUseHTMLString: true,
        type: 'success',
        closeOnClickOnLink: true
      })
    } catch (error) {
      console.error(error)
    }
  }

  @Action
  checkDownloadsPermission(): boolean {
    return Vue.prototype
      .$api('hasDownloadPermission', {
        report_id: storeApp.resourceID,
        dimensions: this.appliedDimensions,
        filters: this.filterForReportRequest,
        exclude: this.excludeForReportRequest,
        table_mode: this.isRetention ? 'retention' : 'normal'
      })
      .then((result: boolean) => {
        this.setDownloadPermission(result)
        return result
      })
  }

  @Action
  async updateReportResource({ reportGroups, resourceChanges }: { reportGroups?: IReportGroup[]; resourceChanges: IResourceChanges }) {
    // Set updated report groups
    if (reportGroups) this.setReportGroups(reportGroups)

    // Update snapshot if it was selected
    const activeSnapshot = storeSnapshots.activeSnapshot
    if (activeSnapshot) {
      storeSnapshots.updateActiveSnapshotByResourceChanges({
        snapshotToUpdate: activeSnapshot,
        resourceType: RESOURCE_TYPES.reportsPage,
        resourceChanges
      })
    }

    // Update analytics storage by changes
    const updatedAnalyticsStorage = getUpdatedAnalyticsStorageByResourceChanges(
      resourceChanges,
      storeAnalyticsStorageData.analyticsAllData,
      RESOURCE_TYPES.reportsPage,
      this.currentReport
    )
    storeAnalyticsStorageData.setAnalyticsStorageData(updatedAnalyticsStorage)

    if (!isEmpty(this._appliedReportParams)) {
      this.setReportParams({
        ...(updatedAnalyticsStorage.dimensions ? { dimensions: updatedAnalyticsStorage.dimensions } : {}),
        ...(updatedAnalyticsStorage.chart_group_by ? { chart_group_by: updatedAnalyticsStorage.chart_group_by } : {}),
        ...(updatedAnalyticsStorage.chart_order_by ? { chart_order_by: updatedAnalyticsStorage.chart_order_by } : {})
      })
    }
  }

  @Action
  resetTableData() {
    this.setTableData(null)
  }

  @Action
  clear() {
    Vue.prototype.$sauronNotify.remove()
    this.clearState()
    storeApp.removeMessagesFromCollector(['getReportTableData', 'getReportChartData'])
  }
}

export const storeReport: InstanceType<typeof ReportModule> = getModule(ReportModule)
