import Vue from 'vue'
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  IFunnelDataColumn,
  IFunnelDataStep,
  IFunnelMode,
  IFunnelReports,
  IFunnelsState,
  IMyFunnelResponse,
  IStepEventItem,
  IStepItem,
  IStepParameterItem
} from '@/store/typings/funnels'
import { ISelectedFilter } from '@/store/typings/filter'
import { storeApp } from '@/store/modules/app'
import { storeAnalyticsStorageData } from '@/store/modules/analyticsStorageData'
import { filtersFormatterForRequest, removeReactivity } from '@/helpers'

@Module({ dynamic: true, store, namespaced: true, name: 'funnels' })
class FunnelsModule extends VuexModule implements IFunnelsState {
  funnelReports: IFunnelReports[] = []
  mode: IFunnelMode | null = null
  funnelData: IFunnelDataColumn[] | null = null
  funnelsList: IMyFunnelResponse[] = []
  funnelName: string = ''
  lastActiveSelectName: string = ''
  currentFunnel: IMyFunnelResponse | null = null
  funnelsListIsLoaded = false
  toUpdateFunnelListFilter = false
  isUpdatingSteps = false
  filtersForRequest: ISelectedFilter[] = []
  selectedApplicationId: string = ''
  selectedFunnelFilterName: string = ''

  get appliedApplicationId() {
    return (this.filtersForRequest.find(filter => filter.choice_type_name === 'application')?.options[0] as string) ?? this.applicationId
  }

  get applicationId() {
    return (
      this.selectedApplicationId ||
      (storeAnalyticsStorageData.analyticsFilters.find(filter => filter.choice_type_name === 'application')?.options[0] as string) ||
      ''
    )
  }

  get currentFilterFunnelName() {
    return (
      this.selectedFunnelFilterName ||
      (storeAnalyticsStorageData.analyticsFilters.find(filter => filter.choice_type_name === 'funnel_list')?.options[0] as string) ||
      ''
    )
  }

  get currentFunnelsReport(): IFunnelReports | null {
    return this.funnelReports.find(funnelReport => funnelReport.id === storeApp.resourceID) ?? null
  }

  get currentFunnelSteps() {
    return this.currentFunnel?.funnel ?? []
  }

  get funnelReportsList() {
    return this.funnelReports.map(funnelReport => ({
      name: funnelReport.name,
      id: funnelReport.id
    }))
  }

  get currentStepFilters() {
    return this.currentFunnelsReport?.step_filters ?? null
  }

  @Mutation
  setFunnelsListLoadedStatus(isLoaded: boolean) {
    this.funnelsListIsLoaded = isLoaded
  }

  @Mutation
  setFunnelName(name: string) {
    this.funnelName = name
  }

  @Mutation setMode(mode: IFunnelMode) {
    this.mode = mode
  }

  @Mutation
  setLastActiveSelectName(name: string) {
    this.lastActiveSelectName = name
  }

  @Mutation
  seCurrentFunnel(funnel: IMyFunnelResponse | null) {
    this.currentFunnel = funnel
  }

  @Mutation
  resetFunnel() {
    this.mode = null
    this.funnelData = null
    this.currentFunnel = null
    this.funnelName = ''
    this.selectedApplicationId = ''
    this.selectedFunnelFilterName = ''
    this.filtersForRequest = []
  }

  @Mutation
  setFunnelListFilter(toUpdate: boolean) {
    this.toUpdateFunnelListFilter = toUpdate
  }

  @Mutation
  setUpdatingStepsStatus(isUpdating: boolean) {
    this.isUpdatingSteps = isUpdating
  }

  @Mutation
  setFiltersForRequest(filtersForRequest: ISelectedFilter[]) {
    this.filtersForRequest = removeReactivity(filtersForRequest)
  }

  @Mutation
  setSelectedApplicationId(selectedApplicationId: string) {
    this.selectedApplicationId = selectedApplicationId
  }

  @Mutation
  setFunnelFilterName(selectedFunnelFilterName: string) {
    this.selectedFunnelFilterName = selectedFunnelFilterName
  }

  @MutationAction
  async loadMyFunnels({ applicationId, q }: { applicationId: string; q?: string } = { applicationId: this.applicationId, q: undefined }) {
    const funnelsList = await Vue.prototype.$api('getMyFunnels', { application_id: applicationId, funnel_id: storeApp.resourceID, q })
    return { funnelsList }
  }

  @MutationAction
  async cloneMyFunnels({ applicationId, userFunnelID }: { applicationId: string; userFunnelID: string }) {
    const prevFunnelsIDs = this.funnelsList.map(funnel => funnel.id)

    const funnelsList = (await Vue.prototype.$api('cloneFunnel', {
      application_id: applicationId,
      user_funnel_id: userFunnelID,
      funnel_id: storeApp.resourceID
    })) as IMyFunnelResponse[]

    // Set origin_id for cloned funnel
    const newFunnels: IMyFunnelResponse[] = funnelsList.map(funnel => {
      if (!prevFunnelsIDs.includes(funnel.id)) funnel.original_id = userFunnelID
      return funnel
    })

    return { funnelsList: newFunnels }
  }

  @MutationAction
  async loadFunnelReports() {
    const funnelReports: IFunnelReports[] = await Vue.prototype.$api('getFunnelReports')
    return { funnelReports }
  }

  @MutationAction
  async loadCurrentFunnel(funnelName: string) {
    const funnels = (await Vue.prototype.$api('getMyFunnels', {
      funnel_id: storeApp.resourceID,
      application_id: this.applicationId
    })) as IMyFunnelResponse[]

    // Use strict comparison instead of search by query
    const currentFunnel = funnels.find(funnel => funnel.name === funnelName)

    return { currentFunnel }
  }

  @MutationAction
  async saveFunnel({ funnelName, steps, applicationID }: { funnelName: string; steps: IStepItem[]; applicationID?: string }) {
    const currentFunnel = await Vue.prototype.$api('storeFunnel', {
      application_id: applicationID ?? this.appliedApplicationId,
      funnel_id: storeApp.resourceID,
      name: funnelName,
      funnel: steps
    })

    if (this.mode === 'add') {
      storeFunnels.setSelectedApplicationId(storeFunnels.appliedApplicationId)
    }

    storeFunnels.updateFunnelListFilter(true)

    return { currentFunnel }
  }

  @MutationAction
  async loadFunnelData() {
    storeApp.cancelLastRequest('getFunnelBuildData')

    const preparedSteps: IFunnelDataStep[] = []

    this.currentFunnelSteps.forEach((step, stepIndex) => {
      step.stepEvents.forEach(stepEvent => {
        const stepItem: IFunnelDataStep = { step: stepIndex + 1 }
        this.currentStepFilters?.forEach(currentStepFilter => {
          const choiceTypeName = currentStepFilter.info.choice_type_name
          stepItem[`${choiceTypeName}_id`] = (stepEvent[choiceTypeName as keyof IStepEventItem] as string) || null
        })

        if (stepEvent.parameters.length) {
          stepEvent.parameters.forEach(stepEventParameter => {
            const stepEventParameterNames = Object.keys(stepEventParameter).filter(
              stepEventParameterName => stepEventParameterName !== 'id' && stepEventParameterName !== 'operation_id'
            )
            const parametersObj: { [id: string]: string | null } = { operation_id: stepEventParameter.operation_id ?? stepEventParameter.id }
            stepEventParameterNames.forEach(
              stepEventParameterName =>
                (parametersObj[`${stepEventParameterName}_id`] = stepEventParameter[stepEventParameterName as keyof IStepParameterItem] || null)
            )
            preparedSteps.push({
              ...stepItem,
              ...parametersObj
            })
          })
        } else {
          preparedSteps.push(stepItem)
        }
      })
    })

    const filters =
      this.filtersForRequest.length > 0
        ? filtersFormatterForRequest(this.filtersForRequest)
        : filtersFormatterForRequest(storeAnalyticsStorageData.analyticsFilters)

    try {
      const funnelData = await Vue.prototype.$api('getFunnelBuildData', {
        funnel_id: storeApp.resourceID,
        steps: preparedSteps,
        filters
      }) // replace after getFunnelData will be replaced with getFunnelBuildData

      return { funnelData }
    } catch (e) {
      console.error(e)
    }
  }

  @Action
  updateFunnelListFilter(toUpdate: boolean) {
    if (this.appliedApplicationId === this.applicationId) {
      this.setFunnelListFilter(toUpdate)
    }
  }

  @Action
  async deleteFunnel() {
    this.setMode(null)
    await Vue.prototype.$api('deleteFunnel', {
      application_id: this.appliedApplicationId,
      funnel_id: storeApp.resourceID,
      name: this.currentFunnel!.name
    })

    delete storeAnalyticsStorageData.analyticsAllData.autoUpdatedFunnelListFilter![this.appliedApplicationId]
    storeAnalyticsStorageData.updateAnalyticsStorageData({
      autoUpdatedFunnelListFilter: {
        ...storeAnalyticsStorageData.analyticsAllData.autoUpdatedFunnelListFilter
      }
    })

    this.resetFunnel()
    this.updateFunnelListFilter(true)
  }

  @Action
  async renameFunnel(newFunnelName: string) {
    await Vue.prototype.$api('renameFunnel', {
      application_id: this.appliedApplicationId,
      funnel_id: storeApp.resourceID,
      old_name: this.currentFunnel!.name,
      new_name: newFunnelName
    })
    await this.loadCurrentFunnel(newFunnelName)
    this.updateFunnelListFilter(true)

    if (storeAnalyticsStorageData.analyticsAllData.autoUpdatedFunnelListFilter?.[this.appliedApplicationId]) {
      const funnelFilter = removeReactivity(storeAnalyticsStorageData.analyticsAllData.autoUpdatedFunnelListFilter![this.appliedApplicationId][0])
      funnelFilter.options = [newFunnelName]
      funnelFilter.label = newFunnelName
      storeAnalyticsStorageData.updateAnalyticsStorageData({
        autoUpdatedFunnelListFilter: {
          ...storeAnalyticsStorageData.analyticsAllData.autoUpdatedFunnelListFilter,
          [this.appliedApplicationId]: [funnelFilter]
        }
      })
    }
  }
}

export const storeFunnels: InstanceType<typeof FunnelsModule> = getModule(FunnelsModule)
