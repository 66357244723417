
import { Vue, Component } from 'vue-property-decorator'
import { storeNotificationCenter } from '@/store/modules/notificationCenter'
import { storeApp } from '@/store/modules/app'

@Component({})
export default class MaintenanceModal extends Vue {
  get clickhouseStatus() {
    return storeNotificationCenter.clickHouseStatus
  }

  get toShowClickhouseErrorAlert() {
    return this.clickhouseStatus?.status === 'failed'
  }

  get toShowNotificationsBanners() {
    return ['report', 'inventory', 'dashboard', 'filter-filled'].includes(storeApp.currentPageType) && this.toShowClickhouseErrorAlert
  }
}
