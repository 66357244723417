
import Vue from 'vue'
import Component from 'vue-class-component'
import { uniqBy as _uniqBy } from 'lodash'

import CustomElDropdown from '@/components/CustomElements/Dropdown.vue'
import { storeDashboard } from '@/store/modules/dashboard'
import { storeApp } from '@/store/modules/app'
import { storeReport } from '@/store/modules/report'
import { storeInventory } from '@/store/modules/inventory'
import { storeConfigurations } from '@/store/modules/configurations'
import { storeFunnels } from '@/store/modules/funnels'

type IMenuTabs = {
  analytics: IMenuTab
  configurations: IMenuTab
}
type IMenuTab = {
  name: string
  disabled: boolean
  iconName: string
}

@Component({ components: { CustomElDropdown } })
export default class MainMenu extends Vue {
  activeMenu = 'analytics'
  searchMenuValue = ''

  get currentRouteName() {
    return storeApp.currentRouteName
  }
  get currentPageName() {
    return storeApp.currentPageName
  }
  get reportsList() {
    return storeReport.reportsList
  }
  get inventoryTypes() {
    return storeInventory.inventoryTypes
  }
  get remoteConfigurationsList() {
    return storeConfigurations.remoteConfigurationsList
  }
  get grabbersList() {
    return storeConfigurations.grabbersList
  }
  get contentServerList() {
    return storeConfigurations.contentServerList
  }
  get uaChronologyList() {
    return storeConfigurations.uaChronologyList
  }
  get reportLinks() {
    return _uniqBy(
      this.reportsList.filter(report => report.name.toLowerCase().includes(this.searchMenu)),
      'id'
    )
  }
  get inventoryLinks() {
    return this.inventoryTypes.filter(
      inventoryType =>
        inventoryType.name.toLowerCase().includes(this.searchMenu) || inventoryType.presentation_name.toLowerCase().includes(this.searchMenu)
    )
  }
  get dashboardsLinks() {
    return storeDashboard.dashboards.filter(
      dashboard => dashboard.name.toLowerCase().includes(this.searchMenu) || dashboard.presentation_name.toLowerCase().includes(this.searchMenu)
    )
  }
  get remoteConfigurationsLinks() {
    return this.remoteConfigurationsList.filter(configuration => configuration.name.toLowerCase().includes(this.searchMenu))
  }
  get grabbersLinks() {
    return this.grabbersList.filter(grabber => grabber.name.toLowerCase().includes(this.searchMenu))
  }
  get contentServerLinks() {
    return this.contentServerList.filter(contentServer => contentServer.name.toLowerCase().includes(this.searchMenu))
  }
  get uaChronologyLinks() {
    return this.uaChronologyList.filter(uaChronology => uaChronology.name.toLowerCase().includes(this.searchMenu))
  }
  get funnelReportsLinks() {
    return storeFunnels.funnelReportsList.filter(funnelReportList => funnelReportList.name.toLowerCase().includes(this.searchMenu))
  }
  get searchMenu() {
    return this.searchMenuValue.toLowerCase()
  }
  get menuTitle(): string {
    return storeApp.currentPageType === 'blog' || storeApp.currentPageType === 'main' ? 'Menu' : storeApp.pageTitle
  }
  get menu(): IMenuTabs {
    const menuTabs = {} as IMenuTabs

    if (this.reportsList.length || this.dashboardsLinks.length || this.inventoryLinks.length || this.funnelReportsLinks.length) {
      menuTabs.analytics = {
        name: 'Analytics',
        disabled: false,
        iconName: 'line-chart'
      }
    }

    if (storeConfigurations.configurationGroups.length) {
      menuTabs.configurations = {
        name: 'Configurations',
        iconName: 'settings',
        disabled: false
      }
    }

    return menuTabs
  }

  onVisibleChange(isVisible: boolean) {
    if (isVisible) {
      this.activeMenu = this.getActiveMenu()
      this.searchMenuValue = ''
    }
  }

  getActiveMenu() {
    switch (this.currentRouteName) {
      case 'configurationsPage':
      case 'uaChronologyPage':
      case 'grabbersPage':
      case 'grabberDetailsPage':
      case 'contentServerPage':
      case 'addEditGrabbersPage':
      case 'addEditConfigurationPage':
        return 'configurations'
      default:
        return 'analytics'
    }
  }

  handleMobileClose() {
    const menu = this.$refs.menu as CustomElDropdown
    menu.hide()
  }
}
