import Vue from 'vue'

const focusableElements = 'button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
let firstFocusableElement: HTMLElement | null = null
let lastFocusableElement: HTMLElement | null = null

const onKeyDown = function (e: KeyboardEvent) {
  const isTabPressed = e.key === 'Tab' || e.keyCode === 9

  if (!isTabPressed) {
    return
  }

  if (e.shiftKey) {
    // if shift key pressed for shift + tab combination
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement!.focus() // add focus for the last focusable element
      e.preventDefault()
    }
  } else {
    // if tab key is pressed
    if (document.activeElement === lastFocusableElement) {
      // if focused has reached to last focusable element then focus first focusable element after pressing tab
      firstFocusableElement!.focus() // add focus for the first focusable element
      e.preventDefault()
    }
  }
}

const setFocusableElements = (el: HTMLElement) => {
  const focusableContent = el.querySelectorAll(focusableElements)

  firstFocusableElement = el.querySelectorAll(focusableElements)[0] as HTMLElement
  lastFocusableElement = focusableContent[focusableContent.length - 1] as HTMLElement
}

Vue.directive('focus-trap', {
  bind(el) {
    setFocusableElements(el)
    document.addEventListener('keydown', onKeyDown)
  },
  componentUpdated(el) {
    setTimeout(() => {
      setFocusableElements(el)
    }, 0)
  },
  unbind() {
    document.removeEventListener('keydown', onKeyDown)
  }
})
