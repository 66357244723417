import { VueConstructor } from 'vue'
import { ElNotificationComponent, ElNotificationOptions } from 'element-ui/types/notification'

export interface NotifyPlugin {
  show: (option: ShowNotifyOptions) => void
  remove: (name?: string) => void
  close: (name?: string) => void
}

export type ShowNotifyOptions = Partial<{
  type: 'info' | 'error' | 'success'
  name: string
  replaceDuplicate: boolean
  message: string
  linkText: string
  linkClass: string
  duration: number
  onClick: (event: Event) => void
  onClose: () => void
  onRemove: () => void
}>

interface NotificationComponent extends ElNotificationComponent {
  visible?: boolean
  _name?: string
  _onRemove?: () => void
}

export default {
  install(Vue: VueConstructor<Vue>) {
    let sauronNotifications: NotificationComponent[] = []

    Vue.prototype.$sauronNotify = {
      show: ({
        type = 'info',
        name, // Property for find duplicated messages
        replaceDuplicate = false,
        message,
        linkText,
        linkClass,
        duration = 10000,
        onClick,
        onClose,
        onRemove
      }: ShowNotifyOptions = {}) => {
        const nameToFindDuplicates = name || message

        // Clear hidden notifications
        sauronNotifications = sauronNotifications.filter(notification => notification.visible)

        const hasDuplicatedMessage =
          sauronNotifications.length && sauronNotifications.some(notification => notification._name === nameToFindDuplicates)

        // Replace duplicated messages
        if (hasDuplicatedMessage && replaceDuplicate) {
          const notification = sauronNotifications.find(notification => notification._name === nameToFindDuplicates)
          notification?.close()
          sauronNotifications = sauronNotifications.filter(notification => notification._name !== nameToFindDuplicates)
        } else if (hasDuplicatedMessage) {
          // Skip duplicated message
          return null
        }

        const sauronNotify: NotificationComponent = Vue.prototype.$notify({
          type,
          duration,
          dangerouslyUseHTMLString: true,
          onClose,
          message: `<span>
          ${message}
          ${
            linkText
              ? `<a class="notification-link ${linkClass}">
            ${linkText}
            </a>`
              : ''
          }
          </span>`
        } as ElNotificationOptions)

        sauronNotify._name = nameToFindDuplicates
        sauronNotify._onRemove = onRemove

        if (linkText && onClick) {
          sauronNotify.$el.querySelector('.notification-link')?.addEventListener(
            'click',
            event => {
              onClick(event)
              sauronNotify?.close()
            },
            { once: true }
          )
        }
        sauronNotifications.push(sauronNotify)
      },
      remove: (nameToFindDuplicates?: string) => {
        if (nameToFindDuplicates) {
          const notification = sauronNotifications.find(notification => notification._name === nameToFindDuplicates)
          if (notification?._onRemove) notification?._onRemove()
          notification?.close()
          sauronNotifications = sauronNotifications.filter(notification => notification._name === nameToFindDuplicates)
        } else {
          sauronNotifications.forEach(notification => {
            if (notification?._onRemove) notification?._onRemove()
            notification?.close()
          })
          sauronNotifications = []
        }
      },
      close: (nameToFindDuplicates?: string) => {
        if (nameToFindDuplicates) {
          const notification = sauronNotifications.find(notification => notification._name === nameToFindDuplicates)
          notification?.close()
          sauronNotifications = sauronNotifications.filter(notification => notification._name === nameToFindDuplicates)
        } else {
          sauronNotifications.forEach(notification => {
            notification?.close()
          })
          sauronNotifications = []
        }
      }
    }
  }
}
