
import Vue from 'vue'
import { Component, Inject, Prop, Ref } from 'vue-property-decorator'

//@ts-ignore
import ajax from 'element-ui/packages/upload/src/ajax'

import UploadDragger from '@/components/CustomElements/Upload/UploadDragger.vue'

interface IRawFile extends File {
  uid: string
}

// This component is the same like in elements-ui
// We need to copy all code to use custom upload dragger component (extend component doesn`t work in this case)
@Component({ components: { UploadDragger } })
export default class Upload extends Vue {
  mouseover = false
  reqs: { [uid: string]: FileReader } = {}

  @Ref('input') readonly inputRef!: HTMLInputElement
  @Inject('uploader') readonly uploaderInject!: UploadDragger

  @Prop(Array) fileList!: FileList
  @Prop(Object) data!: any
  @Prop(Object) headers!: Headers
  @Prop(String) type!: string
  @Prop(String) accept!: string
  @Prop(String) listType!: string
  @Prop(Number) limit!: number
  @Prop(Boolean) drag!: boolean
  @Prop(Boolean) multiple!: boolean
  @Prop(Boolean) disabled!: boolean
  @Prop(Boolean) autoUpload!: boolean
  @Prop(Boolean) withCredentials!: boolean
  @Prop(Function) onError!: Function
  @Prop(Function) onStart!: Function
  @Prop(Function) onExceed!: Function
  @Prop(Function) onSuccess!: Function
  @Prop(Function) onProgress!: Function
  @Prop(Function) beforeUpload!: Function

  @Prop({
    type: String,
    required: true
  })
  action!: string

  @Prop({
    type: String,
    default: 'file'
  })
  name!: string

  @Prop({
    type: Function,
    default: function () {}
  })
  onPreview!: Function

  @Prop({
    type: Function,
    default: function () {}
  })
  onRemove!: Function

  @Prop({
    type: Function,
    default: ajax
  })
  httpRequest!: Function

  isImage(str: string) {
    return str.indexOf('image') !== -1
  }

  handleChange(ev: Event) {
    const files = (ev.target as HTMLInputElement).files

    if (!files) return

    this.uploadFiles(files)
  }

  uploadFiles(files: FileList) {
    if (this.limit && this.fileList.length + files.length > this.limit) {
      this.onExceed && this.onExceed(files, this.fileList)
      return
    }

    let postFiles: IRawFile[] = Array.prototype.slice.call(files)

    if (!this.multiple) {
      postFiles = postFiles.slice(0, 1)
    }

    if (postFiles.length === 0) {
      return
    }

    postFiles.forEach(rawFile => {
      this.onStart(rawFile)
      if (this.autoUpload) this.upload(rawFile)
    })
  }

  upload(rawFile: IRawFile) {
    this.inputRef.value = ''

    if (!this.beforeUpload) {
      return this.post(rawFile)
    }

    const before: Promise<File | BlobPart | IRawFile> | false = this.beforeUpload(rawFile)

    if (before && before.then) {
      before.then(
        processedFile => {
          const fileType = Object.prototype.toString.call(processedFile)

          if (fileType === '[object File]' || fileType === '[object Blob]') {
            if (fileType === '[object Blob]') {
              processedFile = new File([processedFile], rawFile.name, {
                type: rawFile.type
              })
            }

            Object.assign(processedFile, rawFile)

            this.post(processedFile as IRawFile)
          } else {
            this.post(rawFile)
          }
        },
        () => {
          this.onRemove(null, rawFile)
        }
      )
    } else if (before !== false) {
      this.post(rawFile)
    } else {
      this.onRemove(null, rawFile)
    }
  }

  abort(file: IRawFile) {
    const { reqs } = this
    if (file) {
      let uid: IRawFile | string = file
      if (file.uid) uid = file.uid
      if (reqs[uid as string]) {
        reqs[uid as string].abort()
      }
    } else {
      Object.keys(reqs).forEach(uid => {
        if (reqs[uid]) reqs[uid].abort()
        delete reqs[uid]
      })
    }
  }

  post(rawFile: IRawFile) {
    const { uid } = rawFile
    const options = {
      headers: this.headers,
      withCredentials: this.withCredentials,
      file: rawFile,
      data: this.data,
      filename: this.name,
      action: this.action,
      onProgress: (e: ProgressEvent<FileReader>) => {
        this.onProgress(e, rawFile)
      },
      onSuccess: (res: Response) => {
        this.onSuccess(res, rawFile)
        delete this.reqs[uid]
      },
      onError: (err: Error) => {
        this.onError(err, rawFile)
        delete this.reqs[uid]
      }
    }
    const req = this.httpRequest(options)
    this.reqs[uid] = req
    if (req && req.then) {
      req.then(options.onSuccess, options.onError)
    }
  }

  handleClick() {
    if (!this.disabled) {
      this.inputRef.value = ''
      this.inputRef.click()
    }
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.target !== e.currentTarget) return
    if (e.keyCode === 13 || e.keyCode === 32) {
      this.handleClick()
    }
  }
}
