import { render, staticRenderFns } from "./BaseLoaderRocket.vue?vue&type=template&id=2c163c44&scoped=true&"
import script from "./BaseLoaderRocket.vue?vue&type=script&lang=ts&"
export * from "./BaseLoaderRocket.vue?vue&type=script&lang=ts&"
import style0 from "./BaseLoaderRocket.vue?vue&type=style&index=0&id=2c163c44&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c163c44",
  null
  
)

export default component.exports