
import Vue from 'vue'
import Component from 'vue-class-component'
import { ElSelect } from 'element-ui/types/select'
import { Ref, Watch } from 'vue-property-decorator'
import { orderBy as _orderBy, uniqBy as _uniqBy, isEqual as _isEqual } from 'lodash'

import { IDashboard } from '@/store/typings/dashboard'

import { storeDashboard } from '@/store/modules/dashboard'
import { storeApp } from '@/store/modules/app'
import { storeReport } from '@/store/modules/report'
import { storeInventory } from '@/store/modules/inventory'
import { storeConfigurations } from '@/store/modules/configurations'
import { storeFunnels } from '@/store/modules/funnels'

interface ISelectListItem {
  name: string
  value: { id: string; groupId?: string }
  label: string
  type: string
}

@Component
export default class MenuSubLevelSelect extends Vue {
  isFocused = false
  selectedRouteID: string | null = null

  @Ref('header-selector') readonly headerSelectorRef!: ElSelect

  get currentRouteName() {
    return storeApp.currentRouteName
  }
  get selectedEntityName() {
    return (this.currentEntity as IDashboard)?.presentation_name ?? this.currentEntity?.name ?? 'Select'
  }
  get currentEntity() {
    return storeApp.currentEntity
  }
  get reportGroups() {
    return storeReport.reportGroups
  }
  get inventoryTypes() {
    return storeInventory.inventoryTypes
  }
  get funnelReportsList() {
    return storeFunnels.funnelReports
  }
  get grabbersList() {
    return storeConfigurations.grabbersList
  }
  get contentServerList() {
    return storeConfigurations.contentServerList
  }
  get remoteConfigurationsList() {
    return storeConfigurations.remoteConfigurationsList
  }
  get uaChronologyList() {
    return storeConfigurations.uaChronologyList
  }
  get dashboards() {
    return storeDashboard.dashboards
  }
  get currentPageType() {
    return storeApp.currentPageType
  }
  get selectList(): ISelectListItem[] {
    let selectArray: ISelectListItem[] = []

    if (this.currentPageType === 'report') {
      this.reportGroups.forEach(reportGroup => {
        reportGroup.reports.forEach(report => {
          selectArray.push({
            name: 'reportsPage',
            value: { groupId: reportGroup.id, id: report.id },
            label: report.name,
            type: 'report'
          })
        })
      })

      selectArray = _uniqBy(selectArray, item => item.value.id)
    } else if (this.currentPageType === 'inventory') {
      selectArray = this.inventoryTypes.map(inventoryType => ({
        name: 'inventoryPage',
        value: { id: inventoryType.id },
        label: inventoryType.presentation_name,
        type: 'inventory'
      }))
    } else if (this.currentPageType === 'dashboard') {
      selectArray = this.dashboards.map(dashboard => ({
        name: 'dashboardPage',
        value: { id: dashboard.id },
        label: dashboard.presentation_name,
        type: 'dashboard'
      }))
    } else if (this.currentPageType === 'config') {
      selectArray = this.remoteConfigurationsList.map(remoteConfiguration => ({
        name: 'configurationsPage',
        value: { id: remoteConfiguration.id },
        label: remoteConfiguration.name,
        type: 'config'
      }))
    } else if (this.currentPageType === 'grabber') {
      selectArray = this.grabbersList.map(grabber => ({
        name: grabber.type === 'smaug_app_config' ? 'configurationsPage' : 'grabbersPage',
        value: { id: grabber.id },
        label: grabber.name,
        type: 'grabber'
      }))
    } else if (this.currentPageType === 'content-server') {
      selectArray = this.contentServerList.map(contentServer => ({
        name: contentServer.type === 'content_category' ? 'contentServerPage' : 'configurationsPage',
        value: { id: contentServer.id },
        label: contentServer.name,
        type: 'content-server'
      }))
    } else if (this.currentPageType === 'ua-config') {
      selectArray = this.uaChronologyList.map(uaChronology => ({
        name: uaChronology.type === 'ua_chronology' ? 'uaChronologyPage' : 'configurationsPage',
        value: { id: uaChronology.id },
        label: uaChronology.name,
        type: 'ua-config'
      }))
    } else if (this.currentPageType === 'filter-filled') {
      selectArray = this.funnelReportsList.map(funnel => ({
        name: 'funnelsPage',
        value: { id: funnel.id },
        label: funnel.name,
        type: 'filter-filled'
      }))
    }

    return _orderBy(selectArray, selectArrayItem => selectArrayItem.label.toLowerCase())
  }

  @Watch('$route')
  onRouteChange() {
    this.setValue()
  }

  mounted() {
    if (!this.currentEntity) {
      const unwatchCurrentEntity = this.$watch('currentEntity', function () {
        this.setValue()
        unwatchCurrentEntity()
      })
    } else {
      this.setValue()
    }
  }

  onChange(selectedRouteID: string) {
    const selectedRoute = this.getRouteByID(selectedRouteID)

    if (selectedRoute && !_isEqual(selectedRoute.value, this.$route.params)) {
      this.$router.push({ name: selectedRoute.name, params: selectedRoute.value })
    }
  }

  onToggleDropdown() {
    ;(this.headerSelectorRef?.$el as HTMLElement).click()
  }

  setValue() {
    this.selectedRouteID = this.currentEntity?.id ?? null
  }

  getRouteByID(routeID: string | null) {
    return this.selectList.find(selectItem => selectItem.value.id === routeID)
  }
}
