import Vue from 'vue'
import dayjs from 'dayjs'
import { ElNotificationOptions } from 'element-ui/types/notification'
import { isEmpty, orderBy, flatten } from 'lodash'
import { VuexModule, Module, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'

import store from '@/store'
import { storeApp } from '@/store/modules/app'
import { storeReport } from '@/store/modules/report'
import { storeSnapshots } from '@/store/modules/snapshots'
import { storeAnalyticsStorageData } from '@/store/modules/analyticsStorageData'
import { getAllReportsFromReportGroups } from '@/store/utils/report'
import { parseCharts } from '@/store/utils/dashboard'
import { SERVER_DATE_FORMAT, RESOURCE_TYPES } from '@/helpers/constants'
import { getDayFromToday, getUpdatedAnalyticsStorageByResourceChanges } from '@/helpers'
import { IResourceChanges } from '@/store/typings/resourceChanges'
import { IFilterRequest, IFilterChoiceRequest, IFilterLookup, IChoicesResponse } from '@/store/typings/filter'
import {
  IVisibleWidgets,
  IDashboardState,
  IFilterRequestParams,
  IDashboard,
  IFabricWidget,
  IAppRelease,
  IChartGroup,
  IDateSetup,
  IFabricFilter,
  IGroup,
  IGetDashboardsResponse,
  IGetAppReleasesResponse,
  IGetDashboardDataResponse
} from '@/store/typings/dashboard'

@Module({ dynamic: true, store, namespaced: true, name: 'dashboard' })
class DashboardModule extends VuexModule implements IDashboardState {
  _dashboards: IDashboard[] = []
  public chartGroups: IChartGroup[] | null = []
  public appReleases: IAppRelease[] = []
  public fabricWidgets: IFabricWidget[] = []
  public appliedFilterParams: IFilterRequestParams = {}
  public visibleWidgets: IVisibleWidgets = {}
  public showedReleaseDate: string | null = null
  public dashboardsIsLoading = false
  public chartsIsLoading = false
  public hiddenWidgetsAmount = 0

  get dashboards(): IDashboard[] {
    return orderBy(this._dashboards, dashboard => dashboard.presentation_name.toLowerCase())
  }

  get fabricWidgetIds(): string[] {
    return this.fabricWidgets.map(fabricWidget => fabricWidget.uniqID)
  }

  get appliedFilters(): IFilterRequest[] {
    return this.appliedFilterParams.filters ?? []
  }

  get selectedApplications(): IFilterChoiceRequest {
    return this.appliedFilters?.find(appliedFilter => appliedFilter.hasOwnProperty('application'))?.application ?? []
  }

  get cohortFilter() {
    return storeAnalyticsStorageData.analyticsFilters.find(filter => filter.is_cohort_filter) ?? null
  }

  get excludedGroupIds() {
    return [
      ...this.allGroupIdsList.filter(groupId => this.visibleWidgets.hasOwnProperty(groupId) && !this.visibleWidgets[groupId])
      // ...this.fabricWidgetIds.filter(fabricWidgetId => this.visibleWidgets.hasOwnProperty(fabricWidgetId) && !this.visibleWidgets[fabricWidgetId])
    ]
  }

  get showAppReleases(): boolean {
    return this.currentDashboard?.x_value_type === 'Date' && this.selectedApplications.length === 1
  }

  get isRealtimeMap(): boolean {
    return !!this.currentDashboard?.name.toLowerCase().includes('realtime')
  }

  get predefinedLayouts() {
    return storeApp.predefinedConfigurationsData?.layouts
  }

  get predefinedSettings() {
    return storeApp.predefinedConfigurationsData?.settings
  }

  get predefinedLegends() {
    return storeApp.predefinedConfigurationsData?.selectedLegends
  }

  get predefinedChartsTypes() {
    return storeApp.predefinedConfigurationsData?.selectedChartsTypes
  }

  get currentDashboard(): IDashboard | null {
    return this.dashboards.find(dashboard => dashboard.id === storeApp.resourceID) ?? null
  }

  get currentDateSetup(): IDateSetup {
    return this.currentDashboard?.date_setup ?? { compare_with: -7, from: -27, to: 0 }
  }

  get currentFabricFilters(): IFabricFilter[] {
    return this.currentDashboard?.fabric_filters ?? []
  }

  get currentDashboardGroups(): IGroup[] {
    return this.currentDashboard?.groups.filter(group => this.currentFabricFilters.every(fabricFilter => fabricFilter.group_id !== group.id)) ?? []
  }

  get allGroupIdsList(): string[] {
    return this.currentDashboardGroups.map(group => group.id) ?? []
  }

  @Mutation
  setChartsLoading(chartsIsLoading: boolean) {
    this.chartsIsLoading = chartsIsLoading
  }

  @Mutation
  setDashboards(dashboards: IDashboard[]) {
    this._dashboards = dashboards
  }

  @Mutation
  setDashboardsLoading(dashboardsIsLoading: boolean) {
    this.dashboardsIsLoading = dashboardsIsLoading
  }

  @Mutation
  setAppliedFilterParams(appliedFilterParams: IFilterRequestParams) {
    this.appliedFilterParams = appliedFilterParams
  }

  @Mutation
  setVisibleWidgets(visibleWidgets: IVisibleWidgets) {
    this.visibleWidgets = visibleWidgets
  }

  @Mutation
  clear() {
    this.chartGroups = []
    this.appReleases = []
    this.fabricWidgets = []
    this.appliedFilterParams = {}
    this.chartsIsLoading = false
    this.showedReleaseDate = null
    this.hiddenWidgetsAmount = 0
  }

  @Mutation
  changeShowedReleaseDate(showedReleaseDate: string | null) {
    this.showedReleaseDate = showedReleaseDate
  }

  @Mutation
  setHiddenWidgetAmount(value: number) {
    this.hiddenWidgetsAmount = value
  }

  @MutationAction
  async fetchCharts() {
    storeApp.cancelLastRequest('getDashboardData')

    const { items = [] }: IGetDashboardDataResponse = await Vue.prototype.$api(
      'getDashboardData',
      {
        ...this.appliedFilterParams,
        dashboard_id: storeApp.resourceID,
        exclude_group_ids: this.excludedGroupIds
      },
      {},
      { checkResourceID: true }
    )

    const cohortDayShift = Number(this.cohortFilter?.selectedChoice?.presentation_name ?? 0)

    const allReports = getAllReportsFromReportGroups(storeReport.reportGroups)
    const chartGroups = parseCharts(items, allReports, cohortDayShift, this.currentDashboard!)

    return { chartGroups }
  }

  @MutationAction
  async loadAppReleases() {
    const appReleasesParams = {
      application: this.selectedApplications[0],
      from_date: getDayFromToday(this.currentDateSetup.from, this.currentDashboard?.x_value_type).format(SERVER_DATE_FORMAT),
      to_date: getDayFromToday(this.currentDateSetup.to, this.currentDashboard?.x_value_type, false).format(SERVER_DATE_FORMAT)
    }

    const { items: appReleases }: IGetAppReleasesResponse = await Vue.prototype.$api('getAppReleases', appReleasesParams)

    const sortedAppReleases = appReleases.sort((a, b) => (dayjs(a.release_date).isAfter(dayjs(b.release_date)) ? 1 : -1))

    return { appReleases: sortedAppReleases }
  }

  @MutationAction
  async loadFabricWidgets(lookups: IFilterLookup[]) {
    const promises = this.currentFabricFilters.map(async fabricFilter => {
      const filledLookups = fabricFilter.lookups.reduce((filledLookups: IFilterLookup[], lookup) => {
        const filledLookup = lookups.find(appliedLookup => appliedLookup.hasOwnProperty(lookup.name))
        if (filledLookup) {
          filledLookups.push(filledLookup)
        }
        return filledLookups
      }, [])

      const response: IChoicesResponse = await Vue.prototype.$api('getChoices', {
        page_limit: 1000,
        choice_type_id: fabricFilter.choice_type_id,
        lookups: filledLookups
      })
      const choices = response?.choices ?? []
      return choices.map(choice => ({
        id: choice.key_name,
        uniqID: `${choice.key_name}_${fabricFilter.group_id}`,
        name: `${fabricFilter.presentation_name} ${choice.presentation_name}`
      }))
    })

    const fabricWidgets = await Promise.all(promises)

    return { fabricWidgets: flatten(fabricWidgets) }
  }

  @Action
  public async loadDashboards() {
    this.setDashboardsLoading(true)

    const { items: dashboards }: IGetDashboardsResponse = await Vue.prototype.$api('getDashboards')

    this.setDashboards(dashboards)
    this.setDashboardsLoading(false)
  }

  @Action
  async reloadDashboards({ updatedDashboardUUID, resourceChanges }: { updatedDashboardUUID: string; resourceChanges: IResourceChanges }) {
    if (storeApp.resourceID === updatedDashboardUUID) {
      const { items: dashboards }: IGetDashboardsResponse = await Vue.prototype.$api('getDashboards')

      const filtersHaveBeenUpdated = resourceChanges.some(resourceChange => resourceChange.field === 'filters')

      if (filtersHaveBeenUpdated) {
        Vue.prototype.$sauronNotify.show({
          name: 'reload',
          message: 'Filters has been updated:',
          linkText: 'Reload',
          replaceDuplicate: true, // Replace duplicated messages to use updated onClick and onRemove functions
          duration: 60000000,
          onClick: () => {
            this.clear()
            this.updateDashboardResource({ dashboards, resourceChanges })
          },
          onRemove: () => {
            this.updateDashboardResource({ dashboards, resourceChanges })
          }
        })
      } else if (!isEmpty(this.appliedFilters) && !this.isRealtimeMap) {
        Vue.prototype.$sauronNotify.show({ message: 'This dashboard has been updated' })
        this.updateDashboardResource({ dashboards, resourceChanges })
        this.fetchCharts()
      } else {
        this.updateDashboardResource({ dashboards, resourceChanges })
      }
    } else {
      this.loadDashboards()
    }
  }

  @Action
  async loadDashboardData({
    filters,
    exclude,
    lookups,
    visibleWidgets
  }: {
    filters: IFilterRequest[]
    exclude: IFilterRequest[]
    lookups: IFilterLookup[]
    visibleWidgets: IVisibleWidgets
  }) {
    this.clear()
    this.setAppliedFilterParams({ filters, exclude })
    this.setVisibleWidgets(visibleWidgets)
    if (this.showAppReleases) this.loadAppReleases()
    await this.loadFabricWidgets(lookups)
    await this.loadCharts()
  }

  @Action
  async loadCharts() {
    this.setChartsLoading(true)

    await this.fetchCharts()

    this.setChartsLoading(false)
  }

  @Action
  async updateDashboardCharts(updatedDashboardUUID: string) {
    if (storeApp.resourceID !== updatedDashboardUUID || !this.appliedFilters || this.appliedFilters.length === 0) return

    await this.fetchCharts()

    if (!this.isRealtimeMap) {
      Vue.prototype.$notify({
        type: 'info',
        message: 'This dashboard has been updated'
      } as ElNotificationOptions)
    }
  }

  @Action
  async updateDashboardResource({ dashboards, resourceChanges }: { dashboards?: IDashboard[]; resourceChanges: IResourceChanges }) {
    // Set updated report groups
    if (dashboards) this.setDashboards(dashboards)

    // Update snapshot if it was selected
    const activeSnapshot = storeSnapshots.activeSnapshot
    if (activeSnapshot) {
      storeSnapshots.updateActiveSnapshotByResourceChanges({
        snapshotToUpdate: activeSnapshot,
        resourceType: RESOURCE_TYPES.dashboardPage,
        resourceChanges
      })
    }

    // Update analytics storage by changes
    const updatedAnalyticsStorage = getUpdatedAnalyticsStorageByResourceChanges(
      resourceChanges,
      storeAnalyticsStorageData.analyticsAllData,
      RESOURCE_TYPES.dashboardPage
    )
    storeAnalyticsStorageData.setAnalyticsStorageData(updatedAnalyticsStorage)
  }

  @Action
  async clearCharts() {
    Vue.prototype.$sauronNotify.remove()
    this.clear()
    storeApp.removeMessagesFromCollector(['getAppReleases', 'getDashboardData'])
  }
}

export const storeDashboard: InstanceType<typeof DashboardModule> = getModule(DashboardModule)
