import Vue from 'vue'
import { isEqual, orderBy } from 'lodash'
import { ElNotificationOptions } from 'element-ui/types/notification'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'

import store from '@/store'
import { storeApp } from '@/store/modules/app'
import { INVENTORY_EVENTS_STATUSES } from '@/helpers/constants'
import { IChoiceResponse, IChoicesResponse, IFilterLookup } from '@/store/typings/filter'
import {
  ICard,
  ICardsParams,
  IGetInventoryResponse,
  IGetInventoryTypesResponse,
  IInventoryState,
  IInventoryType,
  IStatuses
} from '@/store/typings/inventory'

const DEFAULT_PAGE_NUM = 1
const DEFAULT_PAGE_LIMIT = 24

@Module({ dynamic: true, store, namespaced: true, name: 'inventory' })
class InventoryModule extends VuexModule implements IInventoryState {
  lookups: IFilterLookup[] = []
  searchValue: string = ''
  pageNum: number = DEFAULT_PAGE_NUM
  isLastPage = false
  _cardsLoading = false
  _cardsInitiallyLoaded = false
  _cards: ICard[] = []
  _statuses: IChoiceResponse[] | null = null
  _inventoryTypes: IInventoryType[] = []
  _currentStatus: string = ''

  get currentInventory(): IInventoryType | null {
    return this.inventoryTypes.find(inventoryType => inventoryType.id === storeApp.resourceID) ?? null
  }

  get currentStatus(): string {
    return this._currentStatus
  }

  get statuses(): IChoiceResponse[] | null {
    return this._statuses
  }

  get inventoryTypes(): IInventoryType[] {
    return orderBy(this._inventoryTypes, inventoryType => inventoryType.presentation_name.toLocaleLowerCase())
  }

  get isABTestInventory(): boolean {
    return !!this.currentInventory?.filters.some(filter => filter.info.choice_type_name.includes('ab_test_status'))
  }

  get cards(): ICard[] {
    return this._cards
  }

  get cardsLoading(): boolean {
    return this._cardsLoading
  }

  get cardsInitiallyLoaded(): boolean {
    return this._cardsInitiallyLoaded
  }

  get cardsParams(): ICardsParams {
    const cardsParams: ICardsParams = {
      inventory_type_id: storeApp.resourceID,
      q: this.searchValue,
      lookups: this.currentStatus && this.isABTestInventory ? [...this.lookups, { ab_test_status: [this.currentStatus] }] : this.lookups,
      page_num: DEFAULT_PAGE_NUM,
      page_limit: DEFAULT_PAGE_LIMIT
    }

    if (!this.isABTestInventory) {
      cardsParams.is_archived = this.currentStatus === 'Archived'
    }

    return cardsParams
  }

  get hasReportAndGrabbersLink(): boolean {
    return !!(this.currentInventory?.report_id && this.currentInventory?.dashboard_id)
  }

  get canLoadMore(): boolean {
    return this.cards.length > 0 && !this.isLastPage && !this.cardsLoading
  }

  get cardListID(): string {
    return `${this.searchValue}__${this.currentStatus}`
  }

  @Mutation
  setInventoryTypes(inventoryTypes: IInventoryType[]) {
    this._inventoryTypes = inventoryTypes
  }

  @Mutation
  setCards(cards: ICard[]) {
    this._cards = cards
  }

  @Mutation
  setStatuses(statuses: IChoiceResponse[] | null) {
    this._statuses = statuses
  }

  @Mutation
  setLookups(lookups: IFilterLookup[]) {
    this.lookups = lookups
  }

  @Mutation
  setSearchValue(searchValue: string) {
    this.searchValue = searchValue
  }

  @Mutation
  setCurrentStatus(currentStatus: string) {
    this._currentStatus = currentStatus
  }

  @Mutation
  setCardsLoading(cardsLoading: boolean) {
    this._cardsLoading = cardsLoading
  }

  @Mutation
  setCardsInitiallyLoaded(initiallyLoaded: boolean) {
    this._cardsInitiallyLoaded = initiallyLoaded
  }

  @Mutation
  updateLastPageStatus(cards: ICard[]) {
    this.isLastPage = cards.length < DEFAULT_PAGE_LIMIT
  }

  @Mutation
  increasePageNum() {
    this.pageNum += 1
  }

  @Mutation
  resetPagination() {
    this.pageNum = DEFAULT_PAGE_NUM
    this.isLastPage = false
  }

  @Mutation
  clearState() {
    this._cards = []
    this._statuses = null
    this._currentStatus = ''
    this.lookups = []
    this.searchValue = ''
    this.pageNum = DEFAULT_PAGE_NUM
    this.isLastPage = false
  }

  @Action
  async loadInventoryTypes() {
    const { items: inventoryTypes }: IGetInventoryTypesResponse = await Vue.prototype.$api('getInventoryTypes')
    this.setInventoryTypes(inventoryTypes)
  }

  @Action
  async reloadInventoryTypes(updatedInventoryUUID: string) {
    const { items: inventoryTypes }: IGetInventoryTypesResponse = await Vue.prototype.$api('getInventoryTypes')

    if (storeApp.resourceID === updatedInventoryUUID) {
      const updatedInventory = inventoryTypes.find(inventory => inventory.id === updatedInventoryUUID)

      if (updatedInventory?.filters && this.currentInventory?.filters && !isEqual(updatedInventory?.filters, this.currentInventory?.filters)) {
        Vue.prototype.$sauronNotify.show({
          name: 'reload',
          message: 'Filters has been updated:',
          linkText: 'Reload',
          duration: 60000000,
          replaceDuplicate: true, // Replace duplicated messages to use updated onClick and onRemove functions
          onClick: () => {
            this.clearState()
            this.setInventoryTypes(inventoryTypes)
          },
          onRemove: () => {
            this.setInventoryTypes(inventoryTypes)
          }
        })
      } else if (this.cardsInitiallyLoaded) {
        Vue.prototype.$sauronNotify.show({ message: 'This inventory has been updated' })
        const { items: cards }: IGetInventoryResponse = await Vue.prototype.$api('getInventory', this.cardsParams)

        this.setCards(cards)
        this.setInventoryTypes(inventoryTypes)
      } else {
        this.setInventoryTypes(inventoryTypes)
      }
    } else {
      this.setInventoryTypes(inventoryTypes)
    }
  }

  @Action
  async loadStatuses() {
    const choicesData: IChoicesResponse = await Vue.prototype.$api('getChoices', {
      choice_type_id: this.currentInventory?.filters.find(filter => filter.info.choice_type_name === 'ab_test_status')?.info.choice_type,
      inventory_type_id: this.currentInventory?.id
    })

    const statuses = choicesData.choices
    const runningStatusIndex = statuses.findIndex(status => status.row_name === 'running')

    if (runningStatusIndex > 0) {
      statuses.unshift(statuses[runningStatusIndex])
      statuses.splice(runningStatusIndex + 1, 1)
    }

    const currentStatus = storeApp.predefinedConfigurationsData?.status ?? (statuses[0].key_name as IStatuses)

    this.setCurrentStatus(currentStatus)
    this.setStatuses(statuses)
  }

  @Action
  async loadCards(lookups: IFilterLookup[]) {
    this.resetPagination()
    this.setCardsInitiallyLoaded(false)
    this.setLookups(lookups)
    this.setSearchValue('')
    this.setCardsLoading(true)

    if (!this.statuses && this.isABTestInventory) {
      await this.loadStatuses()
    } else if (!this.isABTestInventory) {
      const currentEventStatus = storeApp.predefinedConfigurationsData?.status ?? INVENTORY_EVENTS_STATUSES[0]
      this.setCurrentStatus(currentEventStatus)
    }

    const { items: cards }: IGetInventoryResponse = await Vue.prototype.$api('getInventory', this.cardsParams)

    this.setCards(cards)
    this.setCardsLoading(false)
    this.updateLastPageStatus(cards)
    this.setCardsInitiallyLoaded(true)
  }

  @Action
  async updateCards(updatedInventoryUUID: string) {
    if (storeApp.resourceID !== updatedInventoryUUID || !this.cardsInitiallyLoaded) return

    await this.reloadCards()

    Vue.prototype.$notify({
      type: 'info',
      message: 'This inventory has been updated'
    } as ElNotificationOptions)
  }

  @Action
  async reloadCards() {
    const paginationParams = {
      page_num: 1,
      page_limit: this.cards.length
    }

    const { items: cards }: IGetInventoryResponse = await Vue.prototype.$api('getInventory', { ...this.cardsParams, ...paginationParams })

    this.setCards(cards)
  }

  @Action
  async changeSearchValue(searchValue: string) {
    this.resetPagination()
    this.setSearchValue(searchValue)

    const { items: cards }: IGetInventoryResponse = await Vue.prototype.$api('getInventory', this.cardsParams)

    this.setCards(cards)
    this.updateLastPageStatus(cards)
  }

  @Action
  async changeStatus(status: string) {
    this.resetPagination()
    this.setCardsLoading(true)
    this.setCurrentStatus(status)

    const { items: cards }: IGetInventoryResponse = await Vue.prototype.$api('getInventory', this.cardsParams)

    this.setCards(cards)
    this.setCardsLoading(false)
    this.updateLastPageStatus(cards)
  }

  @Action
  async loadMoreCards() {
    this.increasePageNum()

    const { items: cards }: IGetInventoryResponse = await Vue.prototype.$api('getInventory', { ...this.cardsParams, page_num: this.pageNum })

    this.setCards([...this.cards, ...cards])
    this.updateLastPageStatus(cards)
  }

  @Action
  clear() {
    Vue.prototype.$sauronNotify.remove()
    this.clearState()
    storeApp.removeMessagesFromCollector(['getInventory'])
  }
}

export const storeInventory: InstanceType<typeof InventoryModule> = getModule(InventoryModule)
