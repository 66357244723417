
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ElUploadDragger from 'element-ui/packages/upload/src/upload-dragger'

import Upload from './Upload.vue'

@Component({ extends: ElUploadDragger })
export default class UploadDragger extends Vue {
  disabled!: boolean
  dragover!: boolean
  uploader!: Upload

  // This method is using in parent component
  // eslint-disable-next-line vue/no-unused-properties
  onDrop(e: DragEvent) {
    if (this.disabled || !this.uploader) return
    const accept = this.uploader.accept
    this.dragover = false
    if (!accept) {
      this.$emit('file', e.dataTransfer!.files)
      return
    }

    const invalidExtensions = new Set<string>()

    const validFiles = [].slice.call(e.dataTransfer!.files).filter((file: File) => {
      const { type, name } = file
      const extension = name.indexOf('.') > -1 ? `.${name.split('.').pop()}` : ''
      const baseType = type.replace(/\/.*$/, '')
      const isValidExtension = accept
        .split(',')
        .map((type: string) => type.trim())
        .filter((type: string) => type)
        .some((acceptedType: string) => {
          let isValid = false
          if (/\..+$/.test(acceptedType)) {
            isValid = extension === acceptedType
          } else if (/\/\*$/.test(acceptedType)) {
            isValid = baseType === acceptedType.replace(/\/\*$/, '')
            // eslint-disable-next-line no-useless-escape
          } else if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
            isValid = type === acceptedType
          }
          return isValid
        })

      if (!isValidExtension) {
        invalidExtensions.add(extension)
      }

      return isValidExtension
    })

    if (invalidExtensions.size > 0) {
      this.showValidationError([...invalidExtensions])
    }

    this.$emit('file', validFiles)
  }

  showValidationError(invalidExtensions: string[]) {
    const message =
      invalidExtensions.length === 1
        ? `<b>${invalidExtensions[0]}</b> file is not allowed`
        : `<b>${invalidExtensions.join(', ')}</b> files is not allowed`

    this.$sauronNotify.show({
      type: 'error',
      name: message,
      message
    })
  }
}
