import Vue from 'vue'
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import router from '@/router/index'
import { storeApp } from '@/store/modules/app'
import { getStorageByPath } from '@/helpers/storage'
import { IAuthState, IGetTokenResponse, ILoginResponse } from '@/store/typings/auth'

@Module({ dynamic: true, store, namespaced: true, name: 'auth', preserveState: !!getStorageByPath<IAuthState | null>('auth', null) })
class AuthModule extends VuexModule implements IAuthState {
  redirectPath = '/'
  access_token = ''
  reissue_token = ''
  isUpdatingToken = false
  reissueTokenTime: Date | null = null
  lastRequestTimestamp: Date | null = null

  get isAuthenticated(): boolean {
    return !!this.access_token
  }

  get shouldUpdateToken() {
    const environment = process.env.VUE_APP_VERSION || config?.ENVIRONMENT
    const reissueTime =
      environment === 'production'
        ? 60 * 60000 * 24 // 24 hours for production
        : 30 * 60000 // 30 min for development

    return (
      this.reissueTokenTime &&
      this.lastRequestTimestamp &&
      new Date(this.lastRequestTimestamp).valueOf() - new Date(this.reissueTokenTime).valueOf() > reissueTime
    )
  }

  @Mutation
  setToken({ access_token, reissue_token }: IGetTokenResponse) {
    this.access_token = access_token
    this.reissue_token = reissue_token
  }

  @Mutation
  setAccessToken(access_token: string) {
    this.access_token = access_token
  }

  @Mutation
  setReissueTokenTime() {
    this.reissueTokenTime = new Date()
  }

  @Mutation
  setLastRequestTimestamp() {
    this.lastRequestTimestamp = new Date()
  }

  @Mutation
  setUpdatingTokenState(updatingTokenState: boolean) {
    this.isUpdatingToken = updatingTokenState
  }

  @Mutation
  setRedirectPath(redirectPath: string) {
    this.redirectPath = redirectPath
  }

  @Mutation
  resetTokens() {
    this.access_token = ''
    this.reissue_token = ''
    this.redirectPath = '/'
  }

  @Action
  async loadToken(code: string) {
    const res: IGetTokenResponse = await Vue.prototype.$api('getToken', {
      redirect_uri: `${window.location.origin}${window.location.pathname}`,
      code
    })

    const { access_token, reissue_token } = res

    this.setToken({ access_token, reissue_token })

    try {
      await this.login()
    } finally {
      if (this.redirectPath) router.replace(this.redirectPath)
    }
  }

  @Action
  async reissueToken() {
    this.setUpdatingTokenState(true)
    try {
      const accessToken: string = await Vue.prototype.$api('reissue', {
        reissue_token: this.reissue_token
      })
      this.setAccessToken(accessToken)
      this.setReissueTokenTime()
      this.login()
    } catch (e) {
      console.error(e)
      this.resetTokens()
      storeApp.setSocketLoggedIn(false)
      this.loadAuthURL()
    }
    this.setUpdatingTokenState(false)
  }

  @Action
  async login() {
    try {
      await this.loginAPI()
      await this.loginWS()
    } catch (e) {
      storeApp.setSocketLoggedIn(false)
      storeApp.setApiLoggedIn(false)
      console.error(e)
      this.reissueToken()
    }
  }

  @Action
  async loginAPI() {
    try {
      Vue.prototype.$nprogress.start()
      const { user_id }: ILoginResponse = await Vue.prototype.$api('login', {
        access_token: this.access_token
      })
      storeApp.setUserId(user_id)
      storeApp.setApiLoggedIn(true)
      Vue.prototype.$nprogress.done()
    } catch (e) {
      Vue.prototype.$nprogress.done()
      storeApp.setUserId(null)
      storeApp.setApiLoggedIn(false)
      console.error(e)
      this.reissueToken()
    }
  }

  @Action
  async loginWS() {
    try {
      Vue.prototype.$nprogress.start()
      const wsResponse: boolean = await Vue.prototype.$socket.sendRequest('login', {
        access_token: this.access_token
      })

      storeApp.setSocketLoggedIn(wsResponse)
      Vue.prototype.$nprogress.done()
    } catch (e) {
      Vue.prototype.$nprogress.done()
      storeApp.setSocketLoggedIn(false)
      console.error(e)
    }
  }

  @Action
  async loadAuthURL() {
    this.setRedirectPath(window.location.pathname)

    const res = await Vue.prototype.$api('getAuthUrl', {
      redirect_uri: `${window.location.origin}/remote-login`
    })

    Vue.prototype.$nprogress.done()
    window.location.replace(res.url)
  }

  @Action
  async logout() {
    await Vue.prototype.$socket.sendRequest('logout')
    await Vue.prototype.$socket.close()

    this.resetTokens()
    storeApp.setSocketLoggedIn(false)
    storeApp.setApiLoggedIn(false)
    router.push('/')
  }
}

export const storeAuth: InstanceType<typeof AuthModule> = getModule(AuthModule)
