
import Vue from 'vue'
import { throttle } from 'lodash'
import { Ref, Component } from 'vue-property-decorator'
import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event'

import { storeApp } from '@/store/modules/app'
import { storeDashboard } from '@/store/modules/dashboard'
import { getFormattedDurationTime, isMobileDevice } from '@/helpers'

@Component
export default class ServerTime extends Vue {
  timer: NodeJS.Timeout | null = null
  serverTime: string = '00'
  updatedTime: string = '0 minute'
  shortUpdatedTime: string = '0 m'
  showShortForDesktop = false

  @Ref('fullUpdatedTime') fullUpdatedTimeRef?: HTMLElement
  @Ref('shortUpdatedTime') shortUpdatedTimeRef?: HTMLElement
  @Ref('shadowFullUpdatedTime') shadowFullUpdatedTimeRef?: HTMLElement

  get resourceUpdatedTime() {
    return storeApp.resourceUpdatedTime
  }
  get currentRouteName() {
    return storeApp.currentRouteName
  }
  get currentPageName() {
    return storeApp.currentPageName
  }
  get showServerTime() {
    return this.currentPageName || this.currentRouteName === 'home'
  }
  get showResourceUpdatedTime() {
    return (
      ['reportsPage', 'inventoryPage', 'dashboardPage'].includes(this.currentRouteName) &&
      !storeDashboard.isRealtimeMap &&
      !['UA Creative Report'].includes(this.currentPageName)
    )
  }

  mounted() {
    this.updateTime()

    if (!this.showServerTime) {
      const unwatchCurrentEntity = this.$watch('showServerTime', function () {
        addResizeListener(this.$el, this.onRenderUpdatedTime)
        unwatchCurrentEntity()
      })
    } else {
      addResizeListener(this.$el, this.onRenderUpdatedTime)
    }
  }

  beforeDestroy() {
    if (this.$el) removeResizeListener(this.$el, this.onRenderUpdatedTime)
  }

  updateTime() {
    if (this.showResourceUpdatedTime && this.resourceUpdatedTime) {
      this.updatedTime = getFormattedDurationTime(this.resourceUpdatedTime)
      this.shortUpdatedTime = getFormattedDurationTime(this.resourceUpdatedTime, false, true)
    } else {
      const currentDate = new Date()
      this.serverTime = `${currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours()}:${
        currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes()
      }`
    }

    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(this.updateTime, 1000)
  }

  onRenderUpdatedTime = throttle(this.renderUpdatedTime, 50)
  renderUpdatedTime() {
    if (!this.showResourceUpdatedTime || !this.shadowFullUpdatedTimeRef) return

    const showUpdatedShortTime = this.shadowFullUpdatedTimeRef.offsetHeight > 64

    this.showShortForDesktop = !isMobileDevice() && showUpdatedShortTime

    if (showUpdatedShortTime || isMobileDevice()) {
      this.showShortTime()
    } else {
      this.showFullTime()
    }
  }

  showShortTime() {
    this.shortUpdatedTimeRef!.style.visibility = 'visible'
    this.shortUpdatedTimeRef!.style.position = 'relative'

    this.fullUpdatedTimeRef!.style.visibility = 'hidden'
    this.fullUpdatedTimeRef!.style.position = 'absolute'
  }

  showFullTime() {
    this.fullUpdatedTimeRef!.style.visibility = 'visible'
    this.fullUpdatedTimeRef!.style.position = 'relative'

    this.shortUpdatedTimeRef!.style.position = 'absolute'
    this.shortUpdatedTimeRef!.style.visibility = 'hidden'
  }
}
