import Vue from 'vue'
import { isEqual } from 'lodash'
import { deleteOne, setOne } from 'vue-set-path'
import { VuexModule, Module, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators'

import store from '@/store'
import { storeApp } from '@/store/modules/app'
import { removeReactivity } from '@/helpers'
import { getFieldsData } from '@/store/utils/jsonSchemas'
import {
  ICardTemplateInfo,
  ICloneableValue,
  IDefinedFieldCard,
  IForm,
  IFormFieldValue,
  IFormsetsIDs,
  IFormWithSchemaId,
  IFullFieldInfo,
  IJsonSchemasState,
  ILastActiveSelect,
  IModalSchema,
  IModalSchemas,
  ISchemas,
  IDefinedFields,
  ISchemaGroup,
  LoadSchemaGroupParams,
  IContentServerOrderMessage,
  IDroppableItem
} from '@/store/typings/jsonSchemas'
import { MULTIPLE_UA_CREATIVE_SCHEMA_ID } from '@/helpers/constants'
import { storeMagicLinks } from '@/store/modules/magicLinks'

const name = 'jsonSchemas'
if (store.state[name]) {
  store.unregisterModule(name)
}

@Module({ dynamic: true, store, namespaced: true, name })
class JsonSchemasModule extends VuexModule implements IJsonSchemasState {
  form: IForm = {}
  detailsFieldsNames: string[] = ['application', 'name']
  schemas: ISchemas | null = null
  schemaGroup: ISchemaGroup = {}
  definedFields: IDefinedFields = {}
  predefinedFields: IDefinedFields = {}
  hasMultipleUaCreativeAPIAccess: boolean = false
  _modalSchemas: IModalSchemas = {}
  _droppableItem: IDroppableItem | null = null
  _currentSchemaID: string | null = null
  _lastActiveSelect: ILastActiveSelect | Record<string, never> = {}
  _initialFormsetsIDs: { [schema_id: string]: IFormsetsIDs } = {}
  _initialCloneableValues: ICloneableValue | Record<string, never> = {}
  _defaultCloneableValues: ICloneableValue | Record<string, never> = {}
  _contentServerOrderMessage: IContentServerOrderMessage | null = null
  _isCloneableState: boolean = false
  formIsSubmitting: boolean = false
  initialDataTimestamp: number = 0

  get currentSchema() {
    return this.schemas
  }

  get droppableItem() {
    return this._droppableItem
  }

  get currentSchemaTable() {
    return this.currentSchema?.table ?? null
  }

  get filters() {
    return this.currentSchema?.filters ?? []
  }

  get uiSchema() {
    return this.currentSchema?.ui_schema ?? null
  }

  get jsonSchema() {
    return this.currentSchema?.json_schema ?? null
  }

  get canRead() {
    return storeMagicLinks.configAccessEnabled ? storeMagicLinks.configAccess.can_read : this.currentSchema?.can_read ?? false
  }

  get canCreate() {
    return storeMagicLinks.configAccessEnabled ? storeMagicLinks.configAccess.can_create : this.currentSchema?.can_create ?? false
  }

  get canDelete() {
    return storeMagicLinks.configAccessEnabled ? storeMagicLinks.configAccess.can_delete : this.currentSchema?.can_delete ?? false
  }

  get canUpdate() {
    return storeMagicLinks.configAccessEnabled ? storeMagicLinks.configAccess.can_update : this.currentSchema?.can_update ?? false
  }

  get isCloneable() {
    return this.currentSchema?.is_cloneable ?? false
  }

  get formIsSubmittingState() {
    return this.formIsSubmitting
  }

  get lastActiveSelect() {
    return this._lastActiveSelect
  }

  get schemaGroupCards() {
    return this.schemaGroup.cards || []
  }

  get currentSchemaGroupCard() {
    return this.schemaGroupCards?.find(card => storeApp.currentRouter.params.schema_id === card.schema_id)
  }

  get modalSchemas() {
    return this._modalSchemas
  }

  get predefinedForm() {
    return this.predefinedFields
  }

  get definedForm() {
    return this.definedFields
  }

  get initialFormsetsIDs() {
    return this._initialFormsetsIDs
  }

  get defaultCloneableValues() {
    return this._defaultCloneableValues
  }

  get initialCloneableValues() {
    return this._initialCloneableValues
  }

  get isCloneableState() {
    return this._isCloneableState || storeApp.currentRouter.params.state === 'clone'
  }

  get contentServerOrderMessage() {
    return this._contentServerOrderMessage
  }

  get currentSchemaID() {
    return this._currentSchemaID ?? storeApp.resourceID
  }

  get formHasBeenChanged() {
    return !isEqual(this.predefinedForm, this.definedForm)
  }

  @Mutation
  removePredefinedCard(removedCardTemplate: ICardTemplateInfo) {
    // Find cards data with filled fields
    const cardsData = this.predefinedFields[removedCardTemplate.name!]

    // Skip removing if we did not add fields to the deleted card
    if (!cardsData || !Array.isArray(cardsData)) return

    // Removed card index with filled fields
    const removedCardIndex = cardsData.findIndex(cardInfo => cardInfo.cardID === removedCardTemplate.id)

    cardsData.splice(removedCardIndex, 1)
  }

  @Mutation
  removeDefinedCardByTemplate(removedCardTemplate: ICardTemplateInfo) {
    // Find cards data with filled fields
    const cardsData = this.definedFields[removedCardTemplate.name!]

    // Skip removing if we did not add fields to the deleted card
    if (!cardsData || !Array.isArray(cardsData)) return

    // Removed card index with filled fields
    const removedCardIndex = cardsData.findIndex(cardInfo => cardInfo.cardID === removedCardTemplate.id)

    cardsData.splice(removedCardIndex, 1)
  }

  @Mutation
  setForm({ form, schemaId }: IFormWithSchemaId) {
    setOne(this.form, schemaId, form)
    // HACK: for some reasons it should be manually assigned for update
    Object.assign(this, this.form)
  }

  @Mutation
  setFormFieldValue({ value, path, schemaId }: IFormFieldValue) {
    if (value === undefined) return storeJsonSchemas.deleteFormFieldValue({ path, schemaId })

    setOne(this.form, `${schemaId}.${path}`, value)
    // HACK: for some reasons it should be manually assigned for update
    Object.assign(this, this.form)
  }

  @Mutation
  deleteFormFieldValue({ path, schemaId }: IFormFieldValue) {
    deleteOne(this.form, `${schemaId}.${path}`)
    // HACK: for some reasons it should be manually assigned for update
    Object.assign(this, this.form)
  }

  @Mutation
  resetForm() {
    Object.assign(this, { form: {}, predefinedFields: {}, definedFields: {} })
  }

  @Mutation
  deleteModalSchema(id: string) {
    Vue.delete(this._modalSchemas, id)
    Object.assign(this, this._modalSchemas)
  }

  @Mutation
  setLastActiveSelect(lastActiveSelect: ILastActiveSelect) {
    this._lastActiveSelect = lastActiveSelect
  }

  @Mutation
  rearrangeFormsetDefinedCard(cardInfo: { newIndex: number; oldIndex: number; formsetName: string }) {
    const formsetData = this.definedFields[cardInfo.formsetName] as IDefinedFieldCard[]
    const oldFormsetCardData = formsetData.splice(cardInfo.oldIndex, 1)[0]

    formsetData.splice(cardInfo.newIndex, 0, oldFormsetCardData)

    this.definedFields = {
      ...this.definedFields,
      [cardInfo.formsetName]: formsetData
    }
  }

  @Mutation
  setDefinedField(definedField: IFullFieldInfo) {
    this.definedFields = getFieldsData(this.definedFields, definedField)
  }

  @Mutation
  setPredefinedField(predefinedField: IFullFieldInfo) {
    this.predefinedFields = getFieldsData(this.predefinedFields, predefinedField)
  }

  @Mutation
  updatePredefinedFields() {
    this.predefinedFields = removeReactivity(this.definedFields)
  }

  @Mutation
  setinitialFormsetsIDs({ schemaId, formsetIds }: { schemaId: string; formsetIds: IFormsetsIDs }) {
    Vue.set(this._initialFormsetsIDs, schemaId, formsetIds)
    // this._initialFormsetsIDs = formsetIds
  }

  @Mutation
  setDefaultCloneableValues({ path, value }: IFormFieldValue) {
    this._defaultCloneableValues = { ...this.defaultCloneableValues, [path]: value! }
  }

  @Mutation
  setInitialCloneableValues({ path, value }: IFormFieldValue) {
    this._initialCloneableValues = {
      ...this._initialCloneableValues,
      [path]: value!
    }
  }

  @Mutation
  setCloneableState(cloneableState: boolean) {
    this._isCloneableState = cloneableState
  }

  @Mutation
  setFormIsSubmitting(formIsSubmitting: boolean) {
    this.formIsSubmitting = formIsSubmitting
  }

  @Mutation
  setDroppableItem(droppableItem: IDroppableItem | null) {
    this._droppableItem = droppableItem
  }

  @Mutation
  setContentServerOrderMessage(contentServerOrderMessage: IContentServerOrderMessage | null) {
    this._contentServerOrderMessage = contentServerOrderMessage
  }

  @Mutation
  setCurrentSchemaID(currentSchemaID: string | null) {
    this._currentSchemaID = currentSchemaID
  }

  @Mutation
  removeDefinedField(fieldInfo: IFullFieldInfo) {
    const definedFields = removeReactivity(this.definedFields)

    if (fieldInfo.hasOwnProperty('cardName')) {
      const cardsData = definedFields[fieldInfo.cardName!] as IDefinedFieldCard[]
      const card = cardsData.find((cardsDataItem: IDefinedFieldCard) => cardsDataItem.cardID === fieldInfo.cardID)
      if (card) Vue.delete(card.fields, fieldInfo.fieldName)
    } else {
      Vue.delete(definedFields, fieldInfo.fieldName)
    }

    this.definedFields = definedFields
  }

  @Mutation
  updateInitialDataTimestamp() {
    this.initialDataTimestamp = Date.now()
  }

  @MutationAction
  async loadMultipleUaCreativeSchema() {
    try {
      await Vue.prototype.$api('getSchema', { id: MULTIPLE_UA_CREATIVE_SCHEMA_ID }, {}, { preventErrorShow: true })
      return { hasMultipleUaCreativeAPIAccess: true }
    } catch (e) {
      console.error('No access to UA API schema: ', e)
      return { hasMultipleUaCreativeAPIAccess: false }
    }
  }

  @MutationAction
  async loadSchemas(id: string) {
    const schemas = await Vue.prototype.$api('getSchema', { id })
    return { schemas }
  }

  @MutationAction
  async loadModalSchemas({ schemaId, formItemLabel, formSelect }: Pick<IModalSchema, 'formItemLabel' | 'formSelect'> & { schemaId: string }) {
    const schemas = (await Vue.prototype.$api('getSchema', { id: schemaId })) as ISchemas

    Vue.set(this._modalSchemas, schemaId, { schemas, formItemLabel, formSelect })

    return { _modalSchemas: this._modalSchemas }
  }

  @MutationAction
  async loadSchemaGroup(params: LoadSchemaGroupParams) {
    const schemaGroup: ISchemaGroup = await Vue.prototype.$api('getSchemaGroup', params)
    return { schemaGroup }
  }

  @Action
  removeDefinedCard(removedCardTemplate: ICardTemplateInfo) {
    this.removeDefinedCardByTemplate(removedCardTemplate)

    // Remove predefined form in not saved card
    if (removedCardTemplate.isNew) {
      this.removePredefinedCard(removedCardTemplate)
    }
  }
}

export const storeJsonSchemas: InstanceType<typeof JsonSchemasModule> = getModule(JsonSchemasModule)
